/* eslint prefer-const: "off" */
/* eslint  import/no-extraneous-dependencies: "off" */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Modal, ModalHeader, ModalBody, Form, Label, Input, FormFeedback, Row, Col, InputGroup
} from 'reactstrap';

import Select from 'react-select';
import 'flatpickr/dist/themes/material_blue.css';
import Flatpickr from 'react-flatpickr';

import FileService from '../services/FileService';
// import CityService from '../services/CityService';
import CompanyService from '../services/CompanyService';
import ValidationService from '../services/Validation';

import ErrorModal from './ErrorModal';

import {
  PROPERTY_DESCRIPTION_MAX_LENGTH
} from '../constants/validation.constant';
import BbaSpinner from '../components/Common/BbaSpinner';
import { extractErrorAndShowToast, titleCase } from '../utils';
import AlertService from '../services/AlertService';
import BankService from '../services/BankService';
import BranchService from '../services/BranchService';

const control = {
  value: '',
  valid: true
};

function AddNewFile({ file, isModalOpen, handleCloseModal }) {
  // let [number, setNumber] = useState({ ...control });
  // let [date, setDate] = useState({ ...control });
  let [company, setCompany] = useState({ ...control });
  let [bank, setBank] = useState({ ...control });
  let [companyRefNo, setCompanyRefNo] = useState({ ...control });
  let [description, setDescription] = useState({ ...control });
  // let [fees, setFees] = useState({ ...control });
  let [city, setCity] = useState({ ...control });
  let [branch, setBranch] = useState({ ...control });
  let [loginFileType, setLoginFileType] = useState({ ...control });
  let [customer, setCustomer] = useState({ ...control });
  let [actualFees, setActualFees] = useState({ ...control });
  let [loanAmount, setLoanAmount] = useState({ ...control });
  let [receivedFees, setReceivedFees] = useState({ ...control });
  let [appointmentDate, setAppointmentDate] = useState({ ...control });
  let [subRegistrarDetails, setSubRegistrarDetails] = useState({ ...control });

  let [active, setActive] = useState(true);

  const [cities, setCities] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [banks, setBanks] = useState([]);
  // const [masterBanks, setMasterBanks] = useState([]);
  const [mortgagteBanks, setMortgageBanks] = useState([]);

  const [loginFileTypes, setLoginFileTypes] = useState([]);
  const [fileId, setFileId] = useState(null);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorModalDetail, setErrorModalDetail] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // {
  //   "id": "1",
  //   "uuid": "4611d79f-76e0-4423-8e7f-b517a7cfc14c",
  //   "file_no": 1,
  //   "year": "2022-'23",
  //   "is_active": true,
  //   "status": "login",
  //   "created_at": "2023-03-19T08:32:08.194Z",
  //   "bank_reference_no": "Bank 1",
  //   "property_description": "desc",
  //   "customer_name": "Pal",
  //   "fees": 1500,
  //   "bank_id": "1",
  //   "bank_name": "AB Bank Ltd",
  //   "login_file_type_id": "9",
  //   "login_file_type": "TSR"
  // }
  useEffect(() => {
    if (file) {
      setCompany({
        ...control,
        value: file.bank_configuration_id,
        optionValue: {
          value: file.bank_configuration_id,
          label: file.bank_name
        }
      });
      setLoginFileType({ ...control, value: file.login_file_type });
      setCity({ ...control, value: file.city_id });
      setBranch({ ...control, value: file.branch_id });

      if (file.login_file_type === 'TSR') {
        // setDate({ ...control, value: file.date });
        setCompanyRefNo({ ...control, value: file.bank_reference_no });
        setDescription({ ...control, value: file.property_description });
        setCustomer({ ...control, value: file.customer_name });
        // setFees({ ...control, value: file.fees });
      } else {
        if (file.login_file_type === 'Mortgage') {
          setBank({
            ...control,
            value: file.bank_id,
            optionValue: {
              value: file.bank_configuration_id,
              label: file.bank_name
            }
          });
        }
        setCustomer({ ...control, value: file.general_file_customer_name });
        setActualFees({ ...control, value: file.actual_fees });
        setReceivedFees({ ...control, value: file.received_fees });
        setLoanAmount({ ...control, value: file.loan_amount });
        setSubRegistrarDetails({ ...control, value: file.sub_registrar_details });
        if (file.appointment_date) {
          setAppointmentDate({ ...control, value: file.appointment_date });
        }
      }
      setActive(file.is_active);
      setFileId(file.uuid);
    }
    getBranches();
    // getCities();
    getCompanies();
    getLoginFileTypes();
    getBanks();
    // getRoles();
  }, []);

  const handleValidation = () => {
    loginFileType = ValidationService.isEmpty(loginFileType);
    if (loginFileType.isEmpty || loginFileType.isInvalidLength) {
      loginFileType.valid = false;
    } else {
      loginFileType.valid = true;
    }

    setLoginFileType({ ...loginFileType });

    if (loginFileType.valid === true) {
      if (loginFileType.value === 'TSR') {
        return handleTSRFormValidation();
      }
      return handleOtherFormValidation();
    }
    return false;
  };

  const handleTSRFormValidation = () => {
    company = ValidationService.isEmpty(company);
    if (company.isEmpty || company.isInvalidLength) {
      company.valid = false;
    } else {
      company.valid = true;
    }

    description = ValidationService.isEmpty(description);
    description = ValidationService.lengthValidation(description, PROPERTY_DESCRIPTION_MAX_LENGTH);
    if (description.isEmpty || description.isInvalidLength) {
      description.valid = false;
    } else {
      description.valid = true;
    }

    city = ValidationService.isEmpty(city);
    if (city.isEmpty) {
      city.valid = false;
    } else {
      city.valid = true;
    }

    branch = ValidationService.isEmpty(branch);
    if (branch.isEmpty) {
      branch.valid = false;
    } else {
      branch.valid = true;
    }

    customer = ValidationService.isEmpty(customer);
    if (customer.isEmpty) {
      customer.valid = false;
    } else {
      customer.valid = true;
    }

    setCompany({ ...company });
    setDescription({ ...description });
    setCity({ ...city });
    setBranch({ ...branch });
    setCustomer({ ...customer });

    if (
      company.valid
      && description.valid
      && city.valid
      && branch.valid
      && customer.valid
    ) {
      return true;
    }
    return false;
  };

  const handleOtherFormValidation = () => {
    actualFees = ValidationService.isEmpty(actualFees);
    // actualFees = ValidationService.numberValidatior(actualFees, FIRST_NAME_MAX_LENGTH);
    if (actualFees.isEmpty) {
      actualFees.valid = false;
    } else {
      actualFees.valid = true;
    }

    customer = ValidationService.isEmpty(customer);
    if (customer.isEmpty) {
      customer.valid = false;
    } else {
      customer.valid = true;
    }

    if (loginFileType.value === 'Mortgage') {
      bank = ValidationService.isEmpty(bank);
      if (bank.isEmpty || bank.isInvalidLength) {
        bank.valid = false;
      } else {
        bank.valid = true;
      }
      setBank({ ...bank });
    }

    setActualFees({ ...actualFees });
    setCustomer({ ...customer });

    if (
      actualFees.valid
      && customer.valid
      && (loginFileType.value !== 'Mortgage' || bank.valid)
    ) {
      return true;
    }
    return false;
  };

  // const getCities = async (searchKeyword) => {
  //   try {
  //     const cityResponse = await CityService.getCities(1, 1000, searchKeyword);
  //     const cityResponseData = cityResponse.data;
  //     const { data } = cityResponseData;
  //     console.log('cityResponseData', cityResponseData);
  //     // setUsers(userResponseData.users);
  //     if (data.length > 0 && !fileId) {
  //       setCity({ ...city, value: data[0].id });
  //     }
  //     setCities(data);
  //     setIsLoading(false);
  //   } catch (e) {
  //     extractErrorAndShowToast(e);
  //     // console.log('e', e);
  //     // const errorObj = {
  //     //   title: 'Citys',
  //     //   message: 'Something went wrong!!',
  //     // };
  //     // setErrorModalDetail(errorObj);
  //     // setIsErrorModalOpen(true);
  //     setTimeout(() => {
  //       setIsLoading(false);
  //     }, 2000);
  //   }
  // };

  const getBranches = async (searchKeyword) => {
    try {
      const branchResponse = await BranchService.getBranchDropdown(1, 100, searchKeyword, true);
      const branchResponseData = branchResponse.data;
      const { data } = branchResponseData;
      console.log('branchResponseData', data);
      cityBranchMapping(data);
      // setPageCount(totalPages);
      setIsLoading(false);
    } catch (e) {
      console.log('e', e);
      extractErrorAndShowToast(e);
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    }
  };

  const cityBranchMapping = async (allBranches) => {
    const objCity = {};
    const citiesObj = {};
    const cityNameMapping = {};
    for (let branchObj of allBranches) {
      branchObj.label = branchObj.name;
      branchObj.value = branchObj.id;
      if (objCity[branchObj.city_id]) {
        objCity[branchObj.city_id].push(branchObj);
      } else {
        cityNameMapping[branchObj.city_id] = branchObj.city_name;
        objCity[branchObj.city_id] = [branchObj];
      }
    }
    for (let key in objCity) {
      if (Object.hasOwn(objCity, key)) {
        const cityObj = {
          city_id: key,
          city_name: cityNameMapping[key],
          branches: objCity[key]
        };
        citiesObj[key] = cityObj;
      }
    }
    setCities({ ...citiesObj });
  };

  const getCompanies = async (searchKeyword) => {
    try {
      const userResponse = await CompanyService.getCompaniesDropdown(1, 1000, searchKeyword);
      const userResponseData = userResponse.data;
      console.log('userResponseData', userResponseData);
      setCompanies(userResponseData.data);
      const bankDropdown = [];
      if (userResponseData.data && userResponseData.data.length > 0) {
        for (let responseBank of userResponseData.data) {
          const dropdownObj = {
            label: responseBank.bank_name,
            value: responseBank.id
          };
          bankDropdown.push(dropdownObj);
        }
      }
      setBanks(bankDropdown);
      setIsLoading(false);
    } catch (e) {
      extractErrorAndShowToast(e);
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    }
  };

  const getBanks = async () => {
    try {
      const bankResponse = await BankService.getBanks(1, 1000, null);
      const bankResponseData = bankResponse.data;
      const { data } = bankResponseData;
      // setMasterBanks(data);
      const bankDropdown = [];
      if (data && data.length > 0) {
        for (let responseBank of data) {
          const dropdownObj = {
            label: responseBank.name,
            value: responseBank.id
          };
          bankDropdown.push(dropdownObj);
        }
      }
      setMortgageBanks(bankDropdown);
      setIsLoading(false);
    } catch (e) {
      extractErrorAndShowToast(e);
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    }
  };

  const getLoginFileTypes = async () => {
    try {
      const loginFileTypeResponse = await FileService.getLoginFileTypes();
      const loginFileTypeResponseData = loginFileTypeResponse.data;
      const { data } = loginFileTypeResponseData;
      console.log('loginFileTypeResponseData', loginFileTypeResponseData);
      // setUsers(userResponseData.users);
      if (data.length > 0 && !(file && file.uuid)) {
        setLoginFileType({ ...loginFileType, value: data[0].name });
      }
      setLoginFileTypes(data);
      setIsLoading(false);
    } catch (e) {
      extractErrorAndShowToast(e);
      // console.log('e', e);
      // const errorObj = {
      //   title: 'Citys',
      //   message: 'Something went wrong!!',
      // };
      // setErrorModalDetail(errorObj);
      // setIsErrorModalOpen(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    }
  };

  const addFileApi = async () => {
    try {
      const isFormValid = handleValidation();
      if (!isFormValid) {
        return;
      }
      let loginFileTypeData = null;
      if (loginFileType.value) {
        loginFileTypeData = loginFileTypes.find((l) => l.name === loginFileType.value);
      }
      let bankConfigurationData = null;
      if (['TSR'].includes(loginFileType.value)) {
        if (company.value) {
          bankConfigurationData = companies.find((c) => +c.id === +company.value);
        }
      }
      // if (['Mortgage'].includes(loginFileType.value)) {
      //   if (bank.value) {
      //     bankConfigurationData = companies.find((c) => +c.id === +company.value);
      //   }
      // }

      const fileObj = {
        customerName: titleCase(customer.value),
        loginFileTypeId: loginFileTypeData.id,
        loginFileType: loginFileTypeData.name,
        cityId: city.value,
        branchId: branch.value
      };

      if (loginFileType.value === 'TSR') {
        fileObj.bankReferenceNo = (companyRefNo.value || '').toUpperCase();
        fileObj.propertyDescription = description.value;
        fileObj.bankId = bankConfigurationData?.bank_id || null;
        fileObj.bankConfigurationId = bankConfigurationData?.id || null;
        // fileObj.fees = fees.value;
      } else {
        if (loginFileType.value === 'Mortgage') {
          fileObj.bankId = bank.value || null;
        }
        fileObj.actualFees = actualFees.value;
        fileObj.receivedFees = receivedFees.value;
        fileObj.loanAmount = loanAmount.value;
        fileObj.subRegistrarDetails = subRegistrarDetails.value;
        fileObj.appointmentDate = appointmentDate.value;
      }

      console.log('fileObj', fileObj);
      if (file && file.uuid) {
        fileObj.id = file.uuid;
        await FileService.updateFile(fileObj);
      } else {
        await FileService.addFile(fileObj);
      }
      AlertService.showToast(`${file && file.uuid ? 'File updated successfully' : 'File added successfully'}`);
      handleCloseModal(true);
    } catch (e) {
      extractErrorAndShowToast(e);
      // console.log('update failed');
      // const errorObj = {
      //   title: 'File',
      //   message: 'Something went wrong!!',
      // };
      // setErrorModalDetail(errorObj);
      // setIsErrorModalOpen(true);
    }
  };

  const handleCloseErrorModal = () => {
    setIsErrorModalOpen(false);
    setErrorModalDetail(null);
  };

  const handleLoginFileTypeOnChange = (e) => {
    if (e.target.value !== 'TSR') {
      // reset field value as it is not require in other forms
      setCompany({ ...control, value: company.value });
    }
    setLoginFileType({ ...loginFileType, value: e.target.value });
  };

  const handleDateChange = (e) => {
    console.log('e', e);
    const date = e[0];
    if (date) {
      setAppointmentDate({ ...control, value: new Date(date).toISOString() });
    }
  };

  return (
    <>
      <Modal isOpen={isModalOpen}>
        <ModalHeader tag="h4">
          {fileId ? 'Edit File' : 'Add File'}
          {/* {!!isEdit ? "Edit Order" : "Add Order"} */}
        </ModalHeader>
        <ModalBody>
          {isLoading && <BbaSpinner />}

          <Form
            className="form-horizontal"
            onSubmit={(e) => {
              e.preventDefault();
              // validation.handleSubmit();

              return false;
            }}
          >
            {/* {error ? <Alert color="danger">{error}</Alert> : null} */}
            <div className="mb-3">
              <Label className="form-label">Type</Label>
              <Input
                type="select"
                value={loginFileType.value}
                disabled={!!(file && file.uuid)}
                // className="form-control bg-light border-light rounded"
                // placeholder="Search..."
                onChange={handleLoginFileTypeOnChange}
              >
                {loginFileTypes.map((l) => <option value={l.name}>{l.name}</option>)}
              </Input>
            </div>

            {fileId && (
              <div className="mb-3">
                <Label className="form-label">File No</Label>
                <Input
                  name="companyRefNo"
                  className="form-control"
                  placeholder="Enter Company Ref No."
                  type="text"
                  value={file.file_no || ''}
                  disabled
                // onChange={(e) => setLastName({ ...companyRefNo, value: e.target.value })}
                // invalid={
                //   !(companyRefNo.valid)
                // }
                />
              </div>
            )}

            {loginFileType && ['TSR'].includes(loginFileType.value) && (
              <div className="mb-3">
                <Label className="form-label">
                  Bank Name
                  <span className="text-danger">&nbsp;*</span>
                </Label>
                <Select
                  value={company.optionValue}
                  onChange={(e) => {
                    console.log('e', e);
                    setCompany({ ...company, optionValue: e, value: e.value });
                  }}
                  // isDisabled={!!company}
                  options={banks}
                  classNamePrefix="select2-selection"
                />
                {company.isEmpty ? (
                  <FormFeedback type="invalid">Please select bank</FormFeedback>
                ) : null}
              </div>
            )}

            {loginFileType && ['Mortgage'].includes(loginFileType.value) && (
              <div className="mb-3">
                <Label className="form-label">
                  Bank Name
                  <span className="text-danger">&nbsp;*</span>
                </Label>
                <Select
                  value={bank.optionValue}
                  onChange={(e) => {
                    console.log('e', e);
                    setBank({ ...bank, optionValue: e, value: e.value });
                  }}
                  // isDisabled={!!company}
                  options={mortgagteBanks}
                  classNamePrefix="select2-selection"
                />
                {bank.isEmpty ? (
                  <FormFeedback type="invalid">Please select bank</FormFeedback>
                ) : null}
              </div>
            )}

            {loginFileType && loginFileType.value === 'TSR' && (
              <>
                <div className="mb-3">
                  <Label className="form-label">Bank Ref No.</Label>
                  <Input
                    name="companyRefNo"
                    className="form-control"
                    placeholder="Enter Bank Ref No."
                    type="text"
                    value={companyRefNo.value || ''}
                    onChange={(e) => setCompanyRefNo({ ...companyRefNo, value: e.target.value })}
                    invalid={
                      !(companyRefNo.valid)
                    }
                  />
                  {companyRefNo.isEmpty ? (
                    <FormFeedback type="invalid">Bank Ref No should not be empty</FormFeedback>
                  ) : null}
                </div>

                <div className="mb-3">
                  <Label className="form-label">
                    Property Description
                    <span className="text-danger">&nbsp;*</span>
                  </Label>
                  <Input
                    name="description"
                    className="form-control"
                    placeholder="Enter Property Description"
                    type="textarea"
                    onChange={(e) => setDescription({ ...description, value: e.target.value })}
                    value={description.value || ''}
                    invalid={
                      !(description.valid)
                    }
                  />
                  {description.isEmpty ? (
                    <FormFeedback type="invalid">Property description should not be empty</FormFeedback>
                  ) : null}
                  {description.isInvalidLength ? (
                    <FormFeedback type="invalid">
                      Last name should be more then
                      {PROPERTY_DESCRIPTION_MAX_LENGTH}
                      {' '}
                      characters
                    </FormFeedback>
                  ) : null}
                </div>

                {/* <div className="mb-3">
                  <Label className="form-label">
                    City
                    <span className="text-danger">&nbsp;*</span>
                  </Label>

                  <Input
                    type="select"
                    value={city.value}
                    onChange={(e) => { setCity({ ...city, value: e.target.value }); }}
                  >
                    {cities.map((c) => <option value={c.id}>{c.name}</option>)}
                  </Input>
                </div> */}

                {/* <Row className="mt-10-px mb-3">
                  <Col sm={6} md={6}>
                    <Label className="form-label">
                      City
                      <span className="text-danger">&nbsp;*</span>
                    </Label>
                    <Input
                      type="select"
                      value={city?.value}
                      onChange={(e) => {
                        console.log('e', e);
                        setCity({ ...control, value: e.target.value });
                        setBranch({ ...branch, value: '' });
                      }}
                    >
                      <option value="">Select city...</option>
                      {Object.values(cities).map((c) => <option value={c.city_id}>{c.city_name}</option>)}
                    </Input>
                    {city.isEmpty ? (
                      <FormFeedback type="invalid">Please Select City</FormFeedback>
                    ) : null}
                  </Col>
                  <Col sm={6} md={6}>
                    {city.value && (
                      <>
                        <Label className="form-label">
                          Branch
                          <span className="text-danger">&nbsp;*</span>
                        </Label>
                        <Input
                          type="select"
                          value={branch?.value}
                          onChange={(e) => {
                            console.log('e', e);
                            setBranch({ ...branch, value: e.target.value });
                          }}
                        >
                          <option value="">Select Branch...</option>
                          {cities[city.value].branches.map((b) => <option value={b.id}>{b.name}</option>)}
                        </Input>
                        {branch.isEmpty ? (
                          <FormFeedback type="invalid">Please Select Branch</FormFeedback>
                        ) : null}
                      </>
                    )}

                  </Col>
                </Row> */}
              </>
            )}
            {loginFileType && loginFileType.value !== 'TSR' && (
              <>
                <div className="mb-3">
                  <Label className="form-label">
                    Actual Fees
                    <span className="text-danger">&nbsp;*</span>
                  </Label>
                  <Input
                    name="actualFees"
                    className="form-control"
                    placeholder="Enter Actual Fees"
                    type="number"
                    value={actualFees.value}
                    onChange={(e) => setActualFees({ ...actualFees, value: e.target.value })}
                    onWheel={(e) => e.target.blur()}
                    invalid={
                      !(actualFees.valid)
                    }
                  />
                  {actualFees.isEmpty ? (
                    <FormFeedback type="invalid">Actual fees should not be empty</FormFeedback>
                  ) : null}
                </div>

                <div className="mb-3">
                  <Label className="form-label">
                    Received Fees
                  </Label>
                  <Input
                    name="receivedFees"
                    className="form-control"
                    placeholder="Enter Received Fees"
                    type="number"
                    value={receivedFees.value}
                    onChange={(e) => setReceivedFees({ ...receivedFees, value: e.target.value })}
                    onWheel={(e) => e.target.blur()}
                    invalid={
                      !(receivedFees.valid)
                    }
                  />
                  {receivedFees.isEmpty ? (
                    <FormFeedback type="invalid">Received Fees should not be empty</FormFeedback>
                  ) : null}
                </div>

                <div className="mb-3">
                  <Label className="form-label">
                    Loan Amount Cum Agreement Value
                  </Label>
                  <Input
                    name="loanAmount"
                    className="form-control"
                    placeholder="Enter Loan Amount"
                    type="number"
                    value={loanAmount.value}
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) => setLoanAmount({ ...loanAmount, value: e.target.value })}
                    invalid={
                      !(loanAmount.valid)
                    }
                  />
                  {loanAmount.isEmpty ? (
                    <FormFeedback type="invalid">Loan amount should not be empty</FormFeedback>
                  ) : null}
                </div>

                <div className="mb-3">
                  <Label className="form-label">Sub Registrar Details</Label>
                  <Input
                    name="subRegistrarDetails"
                    className="form-control"
                    placeholder="Enter Sub Registrar Detail"
                    type="textarea"
                    onChange={(e) => setSubRegistrarDetails({ ...subRegistrarDetails, value: e.target.value })}
                    value={subRegistrarDetails.value || ''}
                    invalid={
                      !(subRegistrarDetails.valid)
                    }
                  />
                  {subRegistrarDetails.isEmpty ? (
                    <FormFeedback type="invalid">Sub Registrar should not be empty</FormFeedback>
                  ) : null}
                </div>
                <div className="form-group mb-4">
                  <Label>Appointment Date</Label>
                  <InputGroup>
                    {/* <div>
                      <input
                        className="form-control"
                        type="date"
                        value={appointmentDate.value}
                        defaultValue={appointmentDate.value}
                        id="example-date-input"
                        onChange={handleDateChange}
                      />
                    </div> */}
                    <Flatpickr
                      className="form-control d-block"
                      placeholder="dd M,yyyy"
                      options={{
                        altInput: true,
                        altFormat: 'j F, Y',
                        dateFormat: 'Y-m-d',
                      }}
                      onChange={handleDateChange}
                      value={appointmentDate.value}
                    />
                  </InputGroup>
                </div>
              </>
            )}

            <Row className="mt-10-px mb-3">
              <Col sm={6} md={6}>
                <Label className="form-label">
                  City
                  <span className="text-danger">&nbsp;*</span>
                </Label>
                <Input
                  type="select"
                  value={city?.value}
                  onChange={(e) => {
                    console.log('e', e);
                    setCity({ ...control, value: e.target.value });
                    setBranch({ ...branch, value: '' });
                  }}
                >
                  <option value="">Select city...</option>
                  {Object.values(cities).map((c) => <option value={c.city_id}>{c.city_name}</option>)}
                </Input>
                {city.isEmpty ? (
                  <FormFeedback type="invalid">Please Select City</FormFeedback>
                ) : null}
              </Col>
              <Col sm={6} md={6}>
                {city.value && (
                  <>
                    <Label className="form-label">
                      Branch
                      <span className="text-danger">&nbsp;*</span>
                    </Label>
                    <Input
                      type="select"
                      value={branch?.value}
                      onChange={(e) => {
                        console.log('e', e);
                        setBranch({ ...branch, value: e.target.value });
                      }}
                    >
                      <option value="">Select Branch...</option>
                      {cities && cities[city?.value] && cities[city.value].branches.map((b) => <option value={b.id}>{b.name}</option>)}
                    </Input>
                    {branch.isEmpty ? (
                      <FormFeedback type="invalid">Please Select Branch</FormFeedback>
                    ) : null}
                  </>
                )}

              </Col>
            </Row>

            <div className="mb-3">
              <Label className="form-label">
                Customer
                <span className="text-danger">&nbsp;*</span>
              </Label>
              <Input
                name="customer"
                className="form-control"
                placeholder="Enter Customer Name"
                type="text"
                onChange={(e) => setCustomer({ ...customer, value: e.target.value })}
                value={customer.value || ''}
                invalid={
                  !(customer.valid)
                }
              />
              {customer.isEmpty ? (
                <FormFeedback type="invalid">Customer should not be empty</FormFeedback>
              ) : null}
            </div>

            {file && (
              <Row>
                <Col md={3} sm={6}>
                  <Label className="form-label mt-2-px">Active</Label>
                  <span className="form-check form-switch form-switch-md switch-inline">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="customSwitchsizemd"
                      defaultChecked
                      checked={!!active}
                      onClick={() => {
                        setActive(!active);
                      }}
                    />
                  </span>
                </Col>
              </Row>
            )}

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => {
                  handleCloseModal();
                }}
              >
                Close
              </button>
              <button type="submit" className="btn btn-primary" onClick={addFileApi}>
                Save
              </button>
            </div>

          </Form>
        </ModalBody>
      </Modal>
      {isErrorModalOpen && (
        <ErrorModal
          isModalOpen={isErrorModalOpen}
          {...errorModalDetail}
          closeModal={handleCloseErrorModal}
        />
      )}
    </>
  );
}

AddNewFile.propTypes = {
  file: PropTypes.object,
  isModalOpen: PropTypes.bool,
  handleCloseModal: PropTypes.func
};

export default AddNewFile;
