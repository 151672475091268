import React, { useEffect, useState, useCallback } from 'react';
import {
  Row, Col, Card, CardBody, CardTitle, Input, Button,
  // Label
} from 'reactstrap';
import {
  Table, Thead, Tbody, Tr, Th, Td,
} from 'react-super-responsive-table';
// import Switch from 'react-switch';
import _debounce from 'lodash/debounce';

import BbaSpinner from '../../components/Common/BbaSpinner';
import Pagination from '../../components/Common/Pagination';

import BankService from '../../services/BankService';
// import AlertService from '../../services/AlertService';

import ConfirmationModal from '../../modal/ConfirmationModal';
import AddBankModal from '../../modal/AddBank';
import ErrorModal from '../../modal/ErrorModal';

import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

import { extractErrorAndShowToast } from '../../utils';

function Banks() {
  const [banks, setBanks] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [active, setActive] = useState('');
  const [search, setSearch] = useState('');
  const [pageCount, setPageCount] = useState(10);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isBankModalOpen, setIsBankModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorModalDetail, setErrorModalDetail] = useState(null);
  const [bankToUpdate, setBankToUpdate] = useState(null);

  useEffect(() => {
    getBanks();
    console.log(process.env.REACT_APP_API_URL);
    // @NOTE: added to remove validation error
    setLimit(10);
  }, []);

  useEffect(() => {
    // setIsLoading(true);
    getBanks();
  }, [page, limit, active]);

  useEffect(() => {
    // setIsLoading(true);
    console.log('search', search);
    getBankDebounceFunction(search);
  }, [search]);

  const getBanks = async (searchKeyword) => {
    try {
      const bankResponse = await BankService.getBanks(page, limit, searchKeyword || search, active);
      const bankResponseData = bankResponse.data;
      const { data, totalPages } = bankResponseData;
      console.log('bankResponseData', bankResponseData);
      // setUsers(userResponseData.users);
      setBanks(data);
      setPageCount(totalPages);
      setIsLoading(false);
    } catch (e) {
      extractErrorAndShowToast(e);
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    }
  };

  const getBankDebounceFunction = useCallback(_debounce(getBanks, 500), []);

  const updateBank = async (updateObj) => {
    try {
      setIsLoading(true);
      await BankService.updateBank(updateObj);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      extractErrorAndShowToast(e);
      // console.log('update failed');
      // const errorObj = {
      //   title: 'Banks',
      //   message: 'Something went wrong!!',
      // };
      // setErrorModalDetail(errorObj);
      // setIsErrorModalOpen(true);
    }
  };

  const activeOrInactiveBank = async (bankId, value) => {
    const updateObj = {
      id: bankId,
      isActive: value,
    };
    await updateBank(updateObj);

    // @NOTE: comment once api is active
    const bank = banks.find((b) => b.uuid === bankId);
    bank.is_active = value;

    setBanks([...banks]);
    // call update user api
  };

  const openDeleteBankModal = (bank) => {
    setBankToUpdate(bank);
    setIsModalOpen(true);
  };

  const editBank = (user) => {
    setIsBankModalOpen(true);
    setBankToUpdate(user);
  };

  const deleteBank = async (bank) => {
    const updateObj = {
      id: bank.uuid,
      isDeleted: true,
    };
    await updateBank(updateObj);
    setBankToUpdate(null);
    setIsModalOpen(false);
    getBanks();
  };

  const handlePageChange = (selectedPage) => {
    setPage(selectedPage);
  };

  const handleCloseConfirmationModal = () => {
    setIsModalOpen(false);
  };

  const handleCloseErrorModal = () => {
    setIsErrorModalOpen(false);
    setErrorModalDetail(null);
  };

  const handleCloseBankModal = (reload) => {
    setIsBankModalOpen(false);
    if (reload) {
      getBanks();
    }
  };
  const openAddBankModal = () => {
    setIsBankModalOpen(true);
  };

  return (
    <div className="page-content">
      <div className="container-fluid">
        {/* <Breadcrumbs title="Tables" breadcrumbItem="Responsive Table" /> */}

        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                {isLoading && <BbaSpinner />}
                <CardTitle className="h4">Banks</CardTitle>
                <Row>
                  <Col sm={10} md={5} lg={5}>
                    <div className="pb-3">
                      <div className="search-box chat-search-box">
                        <div className="position-relative">
                          <Input
                            type="text"
                            className="form-control bg-light border-light rounded"
                            placeholder="Search..."
                            onChange={(e) => setSearch(e.target.value)}
                          />
                          <i className="uil uil-search search-icon" />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col sm={6} md={2} lg={2}>
                    <div className="pb-3">
                      {/* <Label className="form-label">Role</Label> */}

                      <div className="position-relative">
                        <Input
                          type="select"
                          className="pagination-button-size"
                          // className="form-control bg-light border-light rounded"
                          // placeholder="Search..."
                          onChange={(e) => setActive(e.target.value)}
                        >
                          <option value="">All Banks</option>
                          <option value="true">Active</option>
                          <option value="false">InActive</option>
                        </Input>
                      </div>
                    </div>
                  </Col>

                  <Col sm={6} md={5} lg={5}>
                    <div className="pb-3 text-sm-end">
                      <Button
                        type="button"
                        color="success"
                        className="waves-effect waves-light mb-3 btn btn-success"
                        onClick={openAddBankModal}
                      >
                        <i className="mdi mdi-plus me-1" />
                        Add Bank
                      </Button>
                    </div>
                  </Col>
                </Row>

                <div className="table-rep-plugin">
                  <div
                    className="table-responsive mb-0"
                    data-pattern="priority-columns"
                  >
                    <Table
                      id="tech-companies-1"
                      className="table table-striped"
                    >
                      <Thead>
                        <Tr>
                          <Th style={{ minWidth: '400px' }}>Name</Th>
                          <Th>Active</Th>
                          <Th style={{ width: '120px' }}>Actions</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {banks.map((bank) => (
                          <Tr>
                            <Td>
                              {bank.name}
                            </Td>
                            <Td>
                              {/* {user.is_active.toString()} */}

                              <div className="form-check form-switch form-switch-md">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="customSwitchsizemd"
                                  checked={!!bank.is_active}
                                  // defaultChecked
                                  onClick={() => {
                                    activeOrInactiveBank(bank.uuid, !bank.is_active);
                                  }}
                                />
                              </div>
                            </Td>
                            <Td>
                              <span onClick={() => editBank(bank)} role="link" tabIndex="-1" className="text-primary">
                                <i className="uil uil-pen font-size-20" />
                              </span>
                              &nbsp;&nbsp;
                              <span onClick={() => openDeleteBankModal(bank)} role="button" tabIndex="-1" className="text-danger">
                                <i className="uil-trash-alt bba-icon font-size-20" />
                              </span>
                            </Td>
                          </Tr>
                        ))}
                        {
                          banks.length === 0 && (
                            <Tr>
                              <Td colSpan="3" className="text-center">No Bank Found</Td>
                            </Tr>
                          )
                        }
                      </Tbody>
                    </Table>
                  </div>
                </div>
                {isModalOpen && (
                  <ConfirmationModal
                    isModalOpen={isModalOpen}
                    title="Delete Bank"
                    message="Are you sure you want to delete Bank?"
                    closeModal={handleCloseConfirmationModal}
                    handleConfirmation={() => deleteBank(bankToUpdate)}
                  />
                )}

                {isBankModalOpen && (
                  <AddBankModal
                    isModalOpen={isBankModalOpen}
                    bank={bankToUpdate}
                    handleCloseModal={handleCloseBankModal}
                  />
                )}

                {isErrorModalOpen && (
                  <ErrorModal
                    isModalOpen={isErrorModalOpen}
                    {...errorModalDetail}
                    closeModal={handleCloseErrorModal}
                  />
                )}

                <Pagination
                  handlePageChange={handlePageChange}
                  page={page}
                  pageCount={pageCount}
                />

              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
  // return <div className="page-content">Users</div>
}

export default Banks;
