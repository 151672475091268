/* eslint prefer-const: "off" */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Modal, ModalHeader, ModalBody, Form, Label, Input, FormFeedback, Row, Col
} from 'reactstrap';

import BankService from '../services/BankService';
import ValidationService from '../services/Validation';
import AlertService from '../services/AlertService';

import ErrorModal from './ErrorModal';

import {
  BANK_NAME_MAX_LENGTH
} from '../constants/validation.constant';
import BbaSpinner from '../components/Common/BbaSpinner';

import { extractErrorAndShowToast } from '../utils';

const control = {
  value: '',
  valid: true
};

function AddBankModal({ bank, isModalOpen, handleCloseModal }) {
  let [name, setName] = useState({ ...control });
  let [active, setActive] = useState(true);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorModalDetail, setErrorModalDetail] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (bank) {
      setName({ ...control, value: bank.name });
      setActive(bank.is_active);
    }
  }, []);

  const handleValidation = () => {
    name = ValidationService.isEmpty(name);
    name = ValidationService.lengthValidation(name, BANK_NAME_MAX_LENGTH);
    if (name.isEmpty || name.isInvalidLength) {
      name.valid = false;
    } else {
      name.valid = true;
    }

    setName({ ...name });

    if (
      name.valid
    ) {
      return true;
    }
    return false;
  };

  const addBank = async () => {
    try {
      const isFormValid = handleValidation();
      if (!isFormValid) {
        return;
      }
      const bankObj = {
        name: name.value,
      };
      console.log('bankObj', bankObj);
      setIsLoading(true);

      if (bank && bank.uuid) {
        bankObj.id = bank.uuid;
        bankObj.isActive = active;
        await BankService.updateBank(bankObj);
      } else {
        await BankService.addBank(bankObj);
      }
      // const infoObj = {
      //   title: 'Bank',
      //   message: `${bank.uuid ? 'Bank info updated successfully' : 'Bank info added successfully'}`,
      // };
      // openSweetAlert('Bank', `${bank.uuid ? 'Bank info updated successfully' : 'Bank info added successfully'}`, 'sucess');
      AlertService.showToast(`${bank && bank.uuid ? 'Bank info updated successfully' : 'Bank info added successfully'}`, 'success');
      // setIsInfoModalOpen(true);
      // setInfoModalDetail(infoObj);
      setIsLoading(false);
      handleCloseModal(true);
    } catch (e) {
      console.log('update failed');
      // const errorObj = {
      //   title: 'Bank',
      //   message: 'Something went wrong!!',
      // };
      setIsLoading(false);
      extractErrorAndShowToast(e);
      // openSweetAlert('Bank', 'Something went wrong', 'error');
      // setErrorModalDetail(errorObj);
      // setIsErrorModalOpen(true);
    }
  };

  const handleCloseErrorModal = () => {
    setIsErrorModalOpen(false);
    setErrorModalDetail(null);
  };
  // const openSweetAlert = (title, message, state = 'success') => {
  //   let icon = 'info';
  //   if (state === 'error') {
  //     icon = 'error';
  //   }
  //   Swal.fire({
  //     title,
  //     icon,
  //     text: message,
  //     showCloseButton: true,
  //     confirmButtonText:
  //       'Ok',
  //     confirmButtonAriaLabel: 'Ok',
  //   });
  // };

  return (
    <>
      <Modal isOpen={isModalOpen}>
        <ModalHeader tag="h4">
          {bank && bank.uuid ? 'Edit Bank Detail' : 'Add Bank'}
          {/* {!!isEdit ? "Edit Order" : "Add Order"} */}
        </ModalHeader>
        <ModalBody>
          {isLoading && <BbaSpinner />}
          <Form
            className="form-horizontal"
            onSubmit={(e) => {
              e.preventDefault();
              // validation.handleSubmit();

              return false;
            }}
          >
            {/* {error ? <Alert color="danger">{error}</Alert> : null} */}

            <div className="mb-3">
              <Label className="form-label">
                Bank Name
                <span className="text-danger">&nbsp;*</span>
              </Label>
              <Input
                name="name"
                className="form-control"
                placeholder="Enter Bank Name"
                type="text"
                onChange={(e) => setName({ ...name, value: e.target.value })}
                value={name.value || ''}
                invalid={
                  !(name.valid)
                }
              />
              {name.isEmpty ? (
                <FormFeedback type="invalid">Bank name should not be empty</FormFeedback>
              ) : null}
              {name.isInvalidLength ? (
                <FormFeedback type="invalid">
                  Bank name should be more then
                  {BANK_NAME_MAX_LENGTH}
                  {' '}
                  characters
                </FormFeedback>
              ) : null}
            </div>

            {bank && (
              <Row>
                <Col md={3} sm={6}>
                  <Label className="form-label mt-2-px">Active</Label>
                  <span className="form-check form-switch form-switch-md switch-inline">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="customSwitchsizemd"
                      defaultChecked
                      checked={!!active}
                      onClick={() => {
                        setActive(!active);
                      }}
                    />
                  </span>
                </Col>
              </Row>
            )}

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => {
                  handleCloseModal();
                }}
              >
                Close
              </button>
              <button type="submit" className="btn btn-primary" onClick={addBank}>
                Save
              </button>
            </div>

          </Form>
        </ModalBody>
      </Modal>
      {isErrorModalOpen && (
        <ErrorModal
          isModalOpen={isErrorModalOpen}
          {...errorModalDetail}
          closeModal={handleCloseErrorModal}
        />
      )}
    </>
  );
}

AddBankModal.propTypes = {
  bank: PropTypes.object,
  isModalOpen: PropTypes.bool,
  handleCloseModal: PropTypes.func
};

export default AddBankModal;
