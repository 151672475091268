/* eslint prefer-const: "off" */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Modal, ModalHeader, ModalBody, Form, Label, Input, FormFeedback, Row, Col
} from 'reactstrap';

import FileService from '../services/FileService';
import ValidationService from '../services/Validation';
import AlertService from '../services/AlertService';

import ErrorModal from './ErrorModal';

import BbaSpinner from '../components/Common/BbaSpinner';

import { extractErrorAndShowToast } from '../utils';

const control = {
  value: '',
  valid: true
};

function TSRVRHandover({ file, isModalOpen, closeModal }) {
  let [name, setName] = useState({ ...control });
  let [type, setType] = useState('tsr');
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorModalDetail, setErrorModalDetail] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (file) {
      setName({ ...control, value: file.handover_name });
    } else {
      // return back to file page
    }
  }, []);

  const handleValidation = () => {
    console.log('type', type);
    name = ValidationService.isEmpty(name);
    if (name.isEmpty || name.isInvalidLength) {
      name.valid = false;
    } else {
      name.valid = true;
    }

    setName({ ...name });

    if (
      name.valid
    ) {
      return true;
    }
    return false;
  };

  const updateFile = async () => {
    try {
      const isFormValid = handleValidation();
      if (!isFormValid) {
        return;
      }
      const fileObj = {
        handoverTo: name.value,
        type
      };
      console.log('fileObj', fileObj);
      console.log('file', file);
      setIsLoading(true);
      fileObj.id = file.uuid;
      await FileService.tsrVrHandover(fileObj);
      AlertService.showToast(`${file.uuid ? 'Tsr Vr handover updated successfully' : 'Bank info added successfully'}`, 'success');
      setIsLoading(false);
      closeModal(true);
    } catch (e) {
      console.log('update failed');
      // const errorObj = {
      //   title: 'Bank',
      //   message: 'Something went wrong!!',
      // };
      setIsLoading(false);
      extractErrorAndShowToast(e);
    }
  };

  const handleCloseErrorModal = () => {
    setIsErrorModalOpen(false);
    setErrorModalDetail(null);
  };

  return (
    <>
      <Modal isOpen={isModalOpen}>
        <ModalHeader tag="h4">
          {file && file.uuid ? 'TSR/VR Handover' : 'TSR/VR Handover'}
          {/* {!!isEdit ? "Edit Order" : "Add Order"} */}
        </ModalHeader>
        <ModalBody>
          {isLoading && <BbaSpinner />}
          <Form
            className="form-horizontal"
            onSubmit={(e) => {
              e.preventDefault();
              // validation.handleSubmit();

              return false;
            }}
          >
            {/* {error ? <Alert color="danger">{error}</Alert> : null} */}
            <Row>
              <Col md={2}>
                <div>
                  <div className="form-check form-check-left">
                    <input
                      type="radio"
                      id="tsr"
                      value="tsr"
                      checked={type === 'tsr'}
                      name="typeRadio"
                      className="form-check-input"
                      defaultChecked
                      onChange={(e) => setType(e.target.value)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="tsr"
                    >
                      TSR
                    </label>
                  </div>
                </div>
              </Col>
              <Col>
                <div>
                  <div className="form-check form-check-left">
                    <input
                      type="radio"
                      value="vr"
                      checked={type === 'vr'}
                      id="vr"
                      name="typeRadio"
                      className="form-check-input"
                      onChange={(e) => setType(e.target.value)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="vr"
                    >
                      VR
                    </label>
                  </div>
                </div>
              </Col>
            </Row>
            <br />
            <div className="mb-3">
              <Label className="form-label">
                Name
                <span className="text-danger">&nbsp;*</span>
              </Label>
              <Input
                name="name"
                className="form-control"
                placeholder="Enter Name"
                type="text"
                onChange={(e) => setName({ ...name, value: e.target.value })}
                value={name.value || ''}
                invalid={
                  !(name.valid)
                }
              />
              {name.isEmpty ? (
                <FormFeedback type="invalid">Please enter name</FormFeedback>
              ) : null}
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => {
                  closeModal();
                }}
              >
                Close
              </button>
              <button type="submit" className="btn btn-primary" onClick={updateFile}>
                Save
              </button>
            </div>

          </Form>
        </ModalBody>
      </Modal>
      {
        isErrorModalOpen && (
          <ErrorModal
            isModalOpen={isErrorModalOpen}
            {...errorModalDetail}
            closeModal={handleCloseErrorModal}
          />
        )
      }
    </>
  );
}

TSRVRHandover.propTypes = {
  file: PropTypes.object,
  isModalOpen: PropTypes.bool,
  closeModal: PropTypes.func
};

export default TSRVRHandover;
