// import Loadable from 'react-loadable';

import Login from '../pages/Login/Login';
import Dashboard from '../pages/Dashboard/Dashboard';
import ForgetPassword from '../pages/ForgotPassword/ForgetPassword';
import UserProfile from '../pages/Profile/UserProfile';
import ChangePassword from '../pages/Profile/ChangePassword';
import Users from '../pages/Users/Users';
import Banks from '../pages/Banks/Banks';
import City from '../pages/City/City';
import Company from '../pages/Company/Company';
import File from '../pages/File/File';
import ResetPassword from '../pages/ForgotPassword/ResetPassword';
import SetPassword from '../pages/ForgotPassword/SetPassword';
import Success from '../pages/Success/Success';
import Pages404 from '../pages/Utility/Pages404';
import Branch from '../pages/Branch/Branch';
import permissionConstant from '../constants/permission.constant';
import Unauthorized from '../pages/Unauthorized/Unauthorized';

const {
  LIST_BANK,
  LIST_BRANCH,
  LIST_CITY,
  LIST_FILE,
  LIST_BANK_CONFIGURATION,
  LIST_USER
} = permissionConstant;
// Lazy loading sample code
// const Home = Loadable({
//   loader: () => import(/* webpackChunkName: "home" */ '../containers/Home'),
//   loading: () => <div>Loading...</div>,
// });

export const publicRouteObj = [
  {
    exact: true,
    path: '/',
    component: Login,
    key: 'Login',
  },
  {
    exact: true,
    path: '/forgot-password',
    component: ForgetPassword,
    key: 'ForgetPassword',
  },
  {
    exact: true,
    path: '/reset-password',
    component: ResetPassword,
    key: 'ResetPassword',
  },
  {
    exact: true,
    path: '/set-password',
    component: SetPassword,
    key: 'SetPassword',
  },
  {
    exact: true,
    path: '/success',
    component: Success,
    key: 'success',
  },
  {
    exact: false,
    path: '*',
    component: Pages404,
    key: 'Pages404',
  },
];

export const privateRouteObj = [
  {
    exact: true,
    path: '/dashboard',
    component: Dashboard,
    key: 'Dashboard',
  },
  {
    exact: true,
    path: '/unauthorized',
    component: Unauthorized,
    key: 'Unauthorized',
  },
  {
    exact: true,
    path: '/profile',
    component: UserProfile,
    key: 'UserProfile',
  },
  {
    exact: true,
    path: '/change-password',
    component: ChangePassword,
    key: 'ChangePassword',
  },
  {
    exact: true,
    path: '/users',
    component: Users,
    permissions: [LIST_USER],
    key: 'Users',
  },
  {
    exact: true,
    path: '/banks',
    component: Banks,
    permissions: [LIST_BANK],
    key: 'Banks',
  },
  {
    exact: true,
    path: '/city',
    component: City,
    permissions: [LIST_CITY],
    key: 'City',
  },
  {
    exact: true,
    path: '/company',
    component: Company,
    permissions: [LIST_BANK_CONFIGURATION],
    key: 'Company',
  },
  {
    exact: true,
    path: '/file',
    component: File,
    permissions: [LIST_FILE],
    key: 'Company',
  },
  {
    exact: true,
    path: '/branch',
    component: Branch,
    permissions: [LIST_BRANCH],
    key: 'Branch',
  }

];
