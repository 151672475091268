import { request } from './request';
import { routes } from '../constants/constant.routes';

const { API_URL } = routes;

export default class BankService {
  static addBank(bankObj) {
    return request('POST', `${API_URL}${routes.ADD_ADMIN_BANK}`, null, bankObj, null);
  }

  static updateBank(bankObj) {
    return request('PUT', `${API_URL}${routes.UPDATE_ADMIN_BANK}`, null, bankObj, null);
  }

  static getAdminBanks(page = 1, limit = 10, search = null, active = '') {
    let qString = '';
    console.log('limit', limit);
    if (page) {
      qString += `page=${(page).toString()}&`;
    }
    if (limit) {
      qString += `limit=${limit.toString()}&`;
    }
    if (search) {
      qString += `search=${search}&`;
    }
    if (active) {
      qString += `active=${active}&`;
    }
    return request('GET', `${API_URL}${routes.GET_ADMIN_BANKS}?${qString}`, null, null, null);
  }

  static getBanks(page = 1, limit = 10, search = null, active = '') {
    let qString = '';
    if (page) {
      qString += `page=${(page).toString()}&`;
    }
    if (limit) {
      qString += `limit=${limit.toString()}&`;
    }
    if (search) {
      qString += `search=${search}&`;
    }
    if (active) {
      qString += `active=${active}&`;
    }
    return request('GET', `${API_URL}${routes.GET_ADMIN_BANKS}?${qString}`, null, null, null);
  }
}
