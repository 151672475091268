import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

function NonAuthLayout({ children }) {
  const location = useLocation();
  useEffect(() => {
    const title = location.pathname;
    const currentage = title.charAt(1).toUpperCase() + title.slice(2);

    document.title = `${currentage} | B&B Associates`;
  }, [location.pathname]);

  return children;
}

NonAuthLayout.propTypes = {
  children: PropTypes.any,
};

export default NonAuthLayout;
