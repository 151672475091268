/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

// import auth from '../constants/auth';

const INIT_STATE = {
  userPermissions: null,
};

export const permissionSlice = createSlice({
  name: 'permission',
  initialState: {
    ...INIT_STATE,
  },
  reducers: {
    setUserPermissions: (state, action) => {
      console.log('action ...', action);
      state.userPermissions = action.payload;
    }
  },
});

// Action creators are generated for each case reducer function
export const {
  setUserPermissions
} = permissionSlice.actions;

export default permissionSlice.reducer;
