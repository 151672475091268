/* eslint prefer-const: "off" */

import React, { useState } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Input,
  FormFeedback,
  Form,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import ValidationService from '../services/Validation';
import UserService from '../services/UserService';
import AlertService from '../services/AlertService';
import { extractErrorAndShowToast } from '../utils';

// Formik Validation
// import * as Yup from 'yup';
// import { useFormik } from 'formik';

const control = {
  value: '',
  valid: true
};

function ChangePassword({ forceReset = false }) {
  // const dispatch = useDispatch();
  // const [idx, setidx] = useState('');
  const navigate = useNavigate();
  let [oldPassword, setOldPassword] = useState({ ...control });
  let [newPassword, setNewPassword] = useState({ ...control });
  let [confirmPassword, setConfirmPassword] = useState({ ...control });

  // const { error, success } = useSelector(state => ({
  //   error: state.Profile.error,
  //   success: state.Profile.success,
  // }));

  const handleValidation = () => {
    oldPassword = ValidationService.isEmpty(oldPassword);
    if (oldPassword.isEmpty) {
      oldPassword.valid = false;
    } else {
      oldPassword.valid = true;
    }

    newPassword = ValidationService.isEmpty(newPassword);
    if (newPassword.isEmpty) {
      newPassword.valid = false;
    } else {
      newPassword.valid = true;
    }

    confirmPassword = ValidationService.isEmpty(confirmPassword);
    if (confirmPassword.value !== newPassword.value) {
      confirmPassword.passwordMismatch = true;
    } else {
      confirmPassword.passwordMismatch = false;
    }
    if (confirmPassword.isEmpty || confirmPassword.passwordMismatch) {
      confirmPassword.valid = false;
    } else {
      confirmPassword.valid = true;
    }

    setOldPassword({ ...oldPassword });
    setNewPassword({ ...newPassword });
    setConfirmPassword({ ...confirmPassword });

    if (
      oldPassword.valid
      && newPassword.valid
      && confirmPassword.valid
    ) {
      return true;
    }
    return false;
  };

  const resetPassword = async (e) => {
    try {
      e.preventDefault();
      const isFormValid = handleValidation();
      if (!isFormValid) {
        return;
      }
      const changePasswordObj = {
        oldPassword: oldPassword.value,
        newPassword: newPassword.value
      };
      console.log('isFormValid', isFormValid);
      await UserService.changePassword(changePasswordObj);
      AlertService.showToast('Password updated successfully');
      navigate('/file');
    } catch (err) {
      extractErrorAndShowToast(err);
    }
  };

  return (
    <Card>
      <CardBody>
        {forceReset
          && (
            <div className="text-center mt-2">
              <h5 className="text-primary">Reset Password</h5>
            </div>
          )}
        <Form
          onSubmit={resetPassword}
        >
          <Row>
            <Col lg="12">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  Old Password
                </Label>
                <Input
                  type="password"
                  name="oldPassword"
                  className="form-control"
                  id="basicpill-firstname-input1"
                  value={oldPassword.value || ''}
                  placeholder="Enter old password"
                  onChange={(e) => setOldPassword({ ...oldPassword, value: e.target.value })}
                  // onBlur={validation.handleBlur}
                  invalid={
                    !(oldPassword.valid)
                  }
                />
                {oldPassword.isEmpty ? (
                  <FormFeedback type="invalid">Please enter old password</FormFeedback>
                ) : null}

              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  New Password
                </Label>
                <Input
                  type="password"
                  name="newPassword"
                  className="form-control"
                  id="basicpill-firstname-input1"
                  value={newPassword.value || ''}
                  placeholder="Enter new password"
                  onChange={(e) => setNewPassword({ ...newPassword, value: e.target.value })}
                  // onBlur={validation.handleBlur}
                  invalid={
                    !(newPassword.valid)
                  }
                />
                {newPassword.isEmpty ? (
                  <FormFeedback type="invalid">Please enter new password</FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  Confirm Password
                </Label>
                <Input
                  type="password"
                  name="confirmPassword"
                  className="form-control"
                  id="basicpill-firstname-input1"
                  value={confirmPassword.value || ''}
                  placeholder="Enter new password again"
                  onChange={(e) => setConfirmPassword({ ...confirmPassword, value: e.target.value })}
                  // onBlur={validation.handleBlur}
                  invalid={
                    !(confirmPassword.valid)
                  }
                />
                {confirmPassword.isEmpty ? (
                  <FormFeedback type="invalid">Please enter confirm password</FormFeedback>
                ) : null}
                {confirmPassword.passwordMismatch ? (
                  <FormFeedback type="invalid">Password is not matching</FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>

          <div className="mt-3">
            <button
              className={`btn btn-primary w-md waves-effect waves-light ${forceReset && 'w-100'}`}
              type="submit"
              formNoValidate
            >
              Save
            </button>
          </div>
        </Form>
      </CardBody>
    </Card>
  );
}

ChangePassword.propTypes = {
  forceReset: PropTypes.bool
};

export default ChangePassword;
