/* eslint prefer-const: "off" */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Table, Tbody, Th, Thead, Tr, Td
} from 'react-super-responsive-table';
import FileService from '../services/FileService';
import ResendEmail from '../modal/ResendEmail';

import { extractErrorAndShowToast, formatDate } from '../utils';
import BbaSpinner from './Common/BbaSpinner';

function QueryComponent({ file }) {
  const [fileQueries, setFileQueries] = useState([]);
  const [emailDetails, setEmailDetails] = useState(null);
  const [isLoading, setIsLoading] = useState([]);
  const [isResendMailModalOpen, setIsResendMailModalOpen] = useState(false);
  // const [pageCount, setPageCount] = useState(1);

  useEffect(() => {
    getQueries();
  }, []);

  const getQueries = async () => {
    try {
      const fileQueryResponse = await FileService.getFileQuery(1, 1000, file.uuid);
      const fileQueryResponseData = fileQueryResponse.data;
      const { data } = fileQueryResponseData;
      console.log('fileQueryResponseData', fileQueryResponseData);
      // setUsers(userResponseData.users);
      setFileQueries(data);
      // setPageCount(totalPages);
      setIsLoading(false);
    } catch (e) {
      console.log('e', e);
      extractErrorAndShowToast(e);
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    }
  };

  const openResendMailModalOpen = (query) => {
    setIsResendMailModalOpen(true);
    setEmailDetails({
      query,
      action: 'query'
    });
  };

  const closeResendMailModalOpen = () => {
    setIsResendMailModalOpen(false);
    setEmailDetails(null);
  };

  // const resendQueryEmail = async (query) => {
  //   try {
  //     const obj = {
  //       loginFileId: file.uuid,
  //       action: 'query',
  //       query
  //     };
  //     await FileService.resendEmail(obj);
  //     showSuccessToast('Mail sent');
  //   } catch (e) {
  //     console.log('resend mail failed');
  //     extractErrorAndShowToast(e);
  //   }
  // };

  return (
    <div>
      {isLoading && <BbaSpinner />}
      <Table
        className="table table-striped"
      >
        <Thead>
          <Tr>
            <Th>Created Date</Th>
            <Th>Type</Th>
            <Th style={{ width: '120px' }}>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {fileQueries.length > 0 && fileQueries.map((fileQuery) => (
            <Tr>
              <Td>
                {formatDate(fileQuery.created_at)}
              </Td>
              <Td>
                {fileQuery.query}
              </Td>
              <Td>
                {/* <span onClick={() => resendQueryEmail(fileQuery.query)} role="button" title="Resend Query" tabIndex="-1" className="text-primary">
                  <i className="uil uil-envelope font-size-20" />
                </span> */}
                {file.login_file_type === 'TSR' && (
                  <span onClick={() => openResendMailModalOpen(fileQuery.query)} role="button" title="Resend query via mail" tabIndex="-1" className="text-primary">
                    <i className="uil uil-envelope font-size-20" />
                  </span>
                )}
              </Td>
            </Tr>
          ))}
          {
            fileQueries.length === 0 && (
              <Tr>
                <Td colSpan="7" className="text-center">No Query found</Td>
              </Tr>
            )
          }
        </Tbody>
      </Table>
      {isResendMailModalOpen && (
        <ResendEmail
          file={file}
          emailDetails={emailDetails}
          isModalOpen={isResendMailModalOpen}
          handleCloseModal={closeResendMailModalOpen}
        />
      )}
      {/* {fileQueries.map((f) => <div>{f.query}</div>)} */}
    </div>
  );
}

QueryComponent.propTypes = {
  file: PropTypes.object
};

export default QueryComponent;
