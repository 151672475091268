/* eslint prefer-const: "off" */

import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal, ModalHeader, ModalBody, Label, Row, Col
} from 'reactstrap';

function UserDetailModal({ user, isModalOpen, closeModal }) {
  return (
    <Modal isOpen={isModalOpen}>
      <ModalHeader tag="h4">
        User Detail
        {/* {!!isEdit ? "Edit Order" : "Add Order"} */}
      </ModalHeader>
      <ModalBody>
        <>
          {/* {error ? <Alert color="danger">{error}</Alert> : null} */}
          <Row>
            <Col>
              <div className="mb-3">
                <Label className="form-label">Name</Label>
                <div>
                  {user.first_name}
                  {' '}
                  {user.last_name}
                </div>
              </div>
            </Col>
            <Col>
              <div className="mb-3">
                <Label className="form-label">Role</Label>
                <div>{user.role}</div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="mb-3">
                <Label className="form-label">Email</Label>
                <div>{user.email}</div>
              </div>
            </Col>
            <Col>
              <div className="mb-3">
                <Label className="form-label">Phone Number</Label>
                <div>{user.phone_number}</div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="mb-3">
                <Label className="form-label">City</Label>
                <div>{user.city_name}</div>
              </div>
            </Col>
            <Col>
              <div className="mb-3">
                <Label className="form-label">Branch</Label>
                <div>{user.branch_name}</div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="mb-3">
                <Label className="form-label">Address</Label>
                <div>{user.address}</div>
              </div>
            </Col>
          </Row>

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                closeModal();
              }}
            >
              Close
            </button>
            {/* <button type="submit" className="btn btn-primary" onClick={addUser}>
                Save
              </button> */}
          </div>

        </>
      </ModalBody>
    </Modal>
  );
}

UserDetailModal.propTypes = {
  user: PropTypes.object,
  isModalOpen: PropTypes.bool,
  closeModal: PropTypes.func
};

export default UserDetailModal;
