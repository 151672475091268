import { request } from './request';
import { routes } from '../constants/constant.routes';

const { API_URL } = routes;

export default class FileService {
  static addFile(fileObj) {
    return request('POST', `${API_URL}${routes.ADD_FILE}`, null, fileObj, null);
  }

  static updateFile(fileObj) {
    return request('PUT', `${API_URL}${routes.UPDATE_FILE}`, null, fileObj, null);
  }

  static tsrVrHandover(fileObj) {
    return request('PUT', `${API_URL}${routes.TSR_VR_HANDOVER}`, null, fileObj, null);
  }

  static getFiles(page = 1, limit = 10, search = null, active = '', filters = {}, download = null) {
    let qString = '';
    if (page) {
      qString += `page=${(page).toString()}&`;
    }
    if (limit) {
      qString += `limit=${limit.toString()}&`;
    }
    if (search) {
      qString += `search=${search}&`;
    }
    if (active) {
      qString += `active=${active}&`;
    }
    if (download) {
      qString += `download=${download}&`;
    }
    return request('POST', `${API_URL}${routes.GET_FILES}?${qString}`, filters, null, null);
  }

  static getFileDetail(fileObj) {
    return request('GET', `${API_URL}${routes.GET_FILE_DETAIL}`, null, fileObj, null);
  }

  static getLoginFileTypes() {
    return request('GET', `${API_URL}${routes.GET_LOGIN_FILE_TYPES}`, null, null, null);
  }

  static addFileQuery(fileObj) {
    return request('POST', `${API_URL}${routes.ADD_FILE_QUERY}`, null, fileObj, null);
  }

  static getFileQuery(page, limit, loginFileId) {
    return request('GET', `${API_URL}${routes.GET_FILE_QUERY}?page=${page}&limit=${limit}&loginFileId=${loginFileId}`, null, null, null);
  }

  static uploadTSRVRDocument(fileObj) {
    return request('POST', `${API_URL}${routes.UPLOAD_TSR_VR_DOCUMENT}`, null, fileObj, { 'content-type': 'multipart/form-data' });
  }

  static getSignedUrl(fileObj) {
    return request('POST', `${API_URL}${routes.GET_SIGNED_URL}`, null, fileObj, null);
  }

  static getTSRVRDocument(page = 1, limit = 10, loginFileId = null) {
    let qString = '';
    if (page) {
      qString += `page=${(page).toString()}&`;
    }
    if (limit) {
      qString += `limit=${limit.toString()}&`;
    }
    if (loginFileId) {
      qString += `loginFileId=${loginFileId}&`;
    }
    return request('GET', `${API_URL}${routes.GET_TSR_VR_DOCUMENT}?${qString}`, null, null, null);
  }

  static getFileHistory(page = 1, limit = 10, loginFileId = null) {
    let qString = '';
    if (page) {
      qString += `page=${(page).toString()}&`;
    }
    if (limit) {
      qString += `limit=${limit.toString()}&`;
    }
    if (loginFileId) {
      qString += `loginFileId=${loginFileId}&`;
    }
    return request('GET', `${API_URL}${routes.GET_FILE_HISTORY}?${qString}`, null, null, null);
  }

  static outsourceFile(fileObj) {
    return request('POST', `${API_URL}${routes.OUTSOURCE}`, null, fileObj, null);
  }

  static resendEmail(fileObj) {
    return request('POST', `${API_URL}${routes.RESEND_EMAIL}`, null, fileObj, null);
  }
}
