import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Input,
  FormFeedback,
  Form,
} from 'reactstrap';

// Formik Validation
import * as Yup from 'yup';
import { useFormik } from 'formik';

// redux
import { useDispatch } from 'react-redux';

// Import Breadcrumb
import Breadcrumb from '../../components/Common/Breadcrumb';

import avatar from '../../assets/images/users/avatar-9.png';
import StorageService from '../../services/StorageService';
// import UserService from '../../services/UserService';
// actions
// import { editProfile, resetProfileFlag } from "../../store/actions";

function UserProfile() {
  const dispatch = useDispatch();

  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [address, setAddress] = useState('');
  // const [profileImage, setProfileImage] = useState(null);
  // const [profileImageUrl, setProfileImageUrl] = useState(null);
  // const [idx, setidx] = useState('');

  // const { error, success } = useSelector(state => ({
  //   error: state.Profile.error,
  //   success: state.Profile.success,
  // }));

  useEffect(() => {
    const userDetail = StorageService.getUserDetail();
    if (userDetail) {
      const obj = JSON.parse(localStorage.getItem('authUser'));
      if (process.env.REACT_APP_DEFAULTAUTH === 'firebase') {
        setFirstName(obj.displayName);
        setEmail(obj.email);
        // setidx(obj.uid);

        // @NOTE: added to remove validation error
        setPhone(obj.phone);
        setLastName(obj.lastName);
        setAddress(obj.address);
        // setProfileImage(obj.address);
      }
      setTimeout(() => {
        // reset profile flag
        // dispatch(resetProfileFlag());
      }, 3000);
    }
  }, [dispatch,
    // success
  ]);

  // const getUsers = async (searchKeyword) => {
  //   try {
  //     const userDetail = StorageService.getUserDetail();
  //     const userResponse = await UserService.getUserDetail(userDetail.id);
  //     const userResponseData = userResponse.data;
  //     console.log('userResponseData', userResponseData);
  //     setUsers(userResponseData.data);
  //     // setUsers(usersList);
  //     setPageCount(userResponseData.totalPages);
  //     setIsLoading(false);
  //   } catch (e) {
  //     // console.log('e', e);
  //     // const errorObj = {
  //     //   title: 'Users',
  //     //   message: 'Something went wrong!!',
  //     // };
  //     // setErrorModalDetail(errorObj);
  //     // setIsErrorModalOpen(true);
  //     extractErrorAndShowToast(e);
  //     // setUsers(usersList);
  //     setTimeout(() => {
  //       setIsLoading(false);
  //     }, 2000);
  //   }
  // };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      firstName: firstName || '',
      lastName: lastName || '',
      email: email || '',
      phone: phone || '',
      address: address || '',

      // @NOTE : added to remove validation error
      // profileImage: profileImage || ''
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required('Please Enter Your UserName'),
    }),
    onSubmit: (values) => {
      console.log(values);
      // edit profile
      // dispatch(editProfile(values));
    },
  });

  // const handleImageChange = (e) => {
  //   console.log('e', e);
  //   const file = e.target.files[0];
  //   setProfileImage(file);
  //   const profileImageUrlLocal = URL.createObjectURL(file);
  //   setProfileImageUrl(profileImageUrlLocal);
  // };

  return (
    <div className="page-content">
      <Container fluid>
        {/* Render Breadcrumb */}
        <Breadcrumb title="Minible" breadcrumbItem="Profile" />
        <Card>
          <CardBody>
            <div style={{ textAlign: 'center' }}>
              <div className="ms-3 mb-1">
                <img
                  src={avatar}
                  alt=""
                  className="avatar-md rounded-circle img-thumbnail"
                />
                {/* <label style={{ position: 'relative', top: '50px', right: '18px' }}>
                  <span>Up</span>
                  <Input type="file" style={{ visibility: 'hidden', width: '0px' }} onChange={handleImageChange} />
                </label> */}
                <div><b>Arpan Shah</b></div>
              </div>

            </div>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <Row>
              <Col lg="12">
                {/* {error && error ? <Alert color="danger">{error}</Alert> : null}
              {success ? <Alert color="success">{success}</Alert> : null} */}

              </Col>
            </Row>

            {/* <h4 className="card-title mb-4">Change User Name</h4> */}

            <Form onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();

              return false;
            }}
            >
              <Row>
                <Col lg="6">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">
                      First name
                    </Label>
                    <Input
                      type="text"
                      name="firstName"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      value={validation.values.firstName || ''}
                      placeholder="Enter your First name"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      invalid={
                        !!(validation.touched.firstName && validation.errors.firstName)
                      }
                    />
                    {validation.touched.firstName && validation.errors.firstName ? (
                      <FormFeedback type="invalid">{validation.errors.firstName}</FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col lg="6">
                  <div className="mb-3">
                    <Label for="basicpill-lastname-input2">
                      Last name
                    </Label>
                    <Input
                      type="text"
                      name="lastName"
                      className="form-control"
                      id="basicpill-lastname-input2"
                      value={validation.values.lastName || ''}
                      placeholder="Enter your Last Name"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      invalid={
                        !!(validation.touched.lastName && validation.errors.lastName)
                      }
                    />
                    {validation.touched.lastName && validation.errors.lastName ? (
                      <FormFeedback type="invalid">{validation.errors.lastName}</FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </Row>

              <Row>
                <Col lg="6">
                  <div className="mb-3">
                    <Label for="basicpill-phoneno-input3">
                      Phone
                    </Label>
                    <Input
                      type="text"
                      name="phone"
                      className="form-control"
                      id="basicpill-phoneno-input3"
                      placeholder="Enter your Phone Number"
                      value={validation.values.phone || ''}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      invalid={
                        !!(validation.touched.phone && validation.errors.phone)
                      }
                    />
                    {validation.touched.phone && validation.errors.phone ? (
                      <FormFeedback type="invalid">{validation.errors.phone}</FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col lg="6">
                  <div className="mb-3">
                    <Label for="basicpill-email-input4">
                      Email
                    </Label>
                    <Input
                      type="email"
                      name="email"
                      className="form-control"
                      id="basicpill-email-input4"
                      placeholder="Enter your email address"
                      value={validation.values.email || ''}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      invalid={
                        !!(validation.touched.email && validation.errors.email)
                      }
                    />
                    {validation.touched.email && validation.errors.email ? (
                      <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg="12">
                  <div className="mb-3">
                    <Label for="basicpill-address-input1">
                      Address
                    </Label>
                    <textarea
                      id="basicpill-address-input1"
                      name="address"
                      className="form-control"
                      placeholder="Enter your Address"
                      rows="2"
                      value={validation.values.address || ''}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                    // invalid={
                    //   !!(validation.touched.email && validation.errors.email)
                    // }
                    />
                    {validation.touched.email && validation.errors.email ? (
                      <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </Row>
              <div className="mt-3">
                <button
                  className="btn btn-primary w-md waves-effect waves-light"
                  type="submit"
                >
                  Save
                </button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
}

export default UserProfile;
