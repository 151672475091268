import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

// i18n
// Redux
// import { connect, useDispatch } from "react-redux"
import { Link, useNavigate } from 'react-router-dom';
import StorageService from '../../../services/StorageService';
import i18next from '../../../i18n';

// users
import user4 from '../../../assets/images/users/avatar-9.png';

function ProfileMenu({ success }) {
  // Declare a new state variable, which we'll call "menu"
  const navigate = useNavigate();
  const [menu, setMenu] = useState(false);

  const [username, setusername] = useState('Admin');

  useEffect(() => {
    const userDetail = StorageService.getUserDetail();
    if (userDetail) {
      setusername(`${userDetail.firstName} ${userDetail.lastName}`);
    }
    // if (localStorage.getItem('authUser')) {
    //   if (process.env.REACT_APP_DEFAULTAUTH === 'firebase') {
    //     const obj = JSON.parse(localStorage.getItem('authUser'));
    //     setusername(obj.displayName);
    //   } else if (
    //     process.env.REACT_APP_DEFAULTAUTH === 'fake'
    //     || process.env.REACT_APP_DEFAULTAUTH === 'jwt'
    //   ) {
    //     const obj = JSON.parse(localStorage.getItem('authUser'));
    //     if (obj.username) {
    //       setusername(obj.username);
    //     } else {
    //       setusername(obj.name);
    //     }
    //   }
    // }
  }, [success]);

  const navigateToLink = (link) => {
    navigate(link);
  };

  const logout = () => {
    StorageService.removeToken();
    StorageService.removeUserDetail();
  };

  return (
    <Dropdown
      isOpen={menu}
      toggle={() => setMenu(!menu)}
      className="d-inline-block"
    >
      <DropdownToggle
        className="btn header-item waves-effect"
        id="page-header-user-dropdown"
        tag="button"
      >
        <img
          className="rounded-circle header-profile-user"
          src={user4}
          alt="Header Avatar"
        />
        <span className="d-none d-xl-inline-block ms-1 fw-medium font-size-15">{username}</span>
        {' '}
        <i className="uil-angle-down d-none d-xl-inline-block font-size-15" />
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-end">
        {/* <DropdownItem tag="a" onClick={() => navigateToLink('/profile')}>
          {' '}
          <i className="uil uil-user-circle font-size-18 align-middle text-muted me-1" />
          {i18next.t('View Profile')}
          {' '}
        </DropdownItem> */}
        <DropdownItem tag="a" onClick={() => navigateToLink('/change-password')}>
          {' '}
          <i className="uil uil-user-circle font-size-18 align-middle text-muted me-1" />
          {i18next.t('Change Password')}
          {' '}
        </DropdownItem>
        {/* <DropdownItem tag="a" href="/">
            <i className="uil uil-wallet font-size-18 align-middle me-1 text-muted"></i>
            {i18next.t("My Wallet")}
          </DropdownItem> */}
        {/* <DropdownItem tag="a" onClick={() => navigateToLink('/settings')}>
            <i className="uil uil-cog font-size-18 align-middle me-1 text-muted"></i>
            {i18next.t("Settings")}
            <span className="badge bg-soft-success rounded-pill mt-1 ms-2">03</span>
          </DropdownItem> */}
        {/* <DropdownItem tag="a" href="auth-lock-screen">
            <i className="uil uil-lock-alt font-size-18 align-middle me-1 text-muted"></i>
            {i18next.t("Lock screen")}
          </DropdownItem> */}
        <div className="dropdown-divider" />
        <Link to="/" className="dropdown-item" onClick={() => logout()}>
          <i className="uil uil-sign-out-alt font-size-18 align-middle me-1 text-muted" />
          <span>{i18next.t('Logout')}</span>
        </Link>
      </DropdownMenu>
    </Dropdown>
  );
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  // t: PropTypes.any,
};

// const mapStatetoProps = (state) => {
//   const { error, success } = state.Profile;
//   return { error, success };
// };

export default ProfileMenu;
