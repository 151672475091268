import { request } from './request';
import { routes } from '../constants/constant.routes';

const { API_URL } = routes;

export default class UserPermissionsService {
  static getModulePermissions() {
    return request('GET', `${API_URL}${routes.GET_MODULE_PERMISSIONS}`, null, null, null);
  }

  static addUserPermissions(permissionObj) {
    return request('POST', `${API_URL}${routes.ADD_USER_PERMISSIONS}`, null, permissionObj, null);
  }

  static getUserPermissions(userId) {
    return request('GET', `${API_URL}${routes.GET_USER_PERMISSIONS}?userId=${userId}`, null, null, null);
  }
}
