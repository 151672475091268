/* eslint prefer-const: "off" */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Modal, ModalHeader, ModalBody, Form, Row, Col, Card
} from 'reactstrap';
import Dropzone from 'react-dropzone';

import FileService from '../services/FileService';
// import ValidationService from '../services/Validation';
import { extractErrorAndShowToast } from '../utils';

import ErrorModal from './ErrorModal';

import AlertService from '../services/AlertService';

const control = {
  value: '',
  valid: true
};

function UploadTSRVRDocument({ file, isModalOpen, closeModal }) {
  let [type, setType] = useState('tsr');
  let [tsrVrdocument, setTsrVrdocument] = useState({ ...control });
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorModalDetail, setErrorModalDetail] = useState(null);

  // const handleValidation = () => {
  //   name = ValidationService.isEmpty(name);
  //   name = ValidationService.lengthValidation(name, BANK_NAME_MAX_LENGTH);
  //   if (name.isEmpty || name.isInvalidLength) {
  //     name.valid = false;
  //   } else {
  //     name.valid = true;
  //   }

  //   setName({ ...name });

  //   if (
  //     name.valid
  //   ) {
  //     return true;
  //   }
  //   return false;
  // };

  const uploadDocument = async () => {
    try {
      // const isFormValid = handleValidation();
      // if (!isFormValid) {
      //   return;
      // }
      // const fileObj = {
      //   type,
      //   loginFileId: file.uuid
      // };
      let formData = new FormData();
      formData.append('type', type);
      formData.append('fileNo', file.file_no);
      formData.append('loginFileId', file.uuid);
      formData.append('path', `${file.year}/${file.file_no}`);
      formData.append('document', tsrVrdocument.value);
      // formData.append('loginFileId', loginFileId);
      console.log('formData', formData);
      await FileService.uploadTSRVRDocument(formData);

      AlertService.showToast('File uploaded successfully');
      closeModal(true);
    } catch (e) {
      console.log('update failed');
      extractErrorAndShowToast(e);
    }
  };

  const handleCloseErrorModal = () => {
    setIsErrorModalOpen(false);
    setErrorModalDetail(null);
  };
  // const openSweetAlert = (title, message, state = 'success') => {
  //   let icon = 'info';
  //   if (state === 'error') {
  //     icon = 'error';
  //   }
  //   Swal.fire({
  //     title,
  //     icon,
  //     text: message,
  //     showCloseButton: true,
  //     confirmButtonText:
  //       'Ok',
  //     confirmButtonAriaLabel: 'Ok',
  //   });
  // };

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
  };

  const handleAcceptedFiles = (files) => {
    let fileData = files[0];
    const fileMetaObj = {
      preview: URL.createObjectURL(fileData),
      formattedSize: formatBytes(fileData.size)
    };
    setTsrVrdocument({ ...control, value: fileData, ...fileMetaObj });
    // files.map((f) => Object.assign(f, {
    //   preview: URL.createObjectURL(f),
    //   formattedSize: formatBytes(f.size),
    // }));

    // setSelectedFiles(files);
  };

  return (
    <>
      <Modal isOpen={isModalOpen}>
        <ModalHeader tag="h4">
          Upload Document
          {/* {!!isEdit ? "Edit Order" : "Add Order"} */}
        </ModalHeader>
        <ModalBody>
          <Form
            className="form-horizontal"
            onSubmit={(e) => {
              e.preventDefault();
              // validation.handleSubmit();

              return false;
            }}
          >
            {/* {error ? <Alert color="danger">{error}</Alert> : null} */}

            <Row>
              <Col md={2}>
                <div>
                  <div className="form-check form-check-left">
                    <input
                      type="radio"
                      id="tsr"
                      value="tsr"
                      checked={type === 'tsr'}
                      name="typeRadio"
                      className="form-check-input"
                      defaultChecked
                      onChange={(e) => setType(e.target.value)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="tsr"
                    >
                      TSR
                    </label>
                  </div>
                </div>
              </Col>
              <Col>
                <div>
                  <div className="form-check form-check-left">
                    <input
                      type="radio"
                      value="vr"
                      checked={type === 'vr'}
                      id="vr"
                      name="typeRadio"
                      className="form-check-input"
                      onChange={(e) => setType(e.target.value)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="vr"
                    >
                      VR
                    </label>
                  </div>
                </div>
              </Col>
            </Row>

            <div className="p-4 border-top">
              <Form>
                <Dropzone
                  onDrop={(acceptedFiles) => {
                    handleAcceptedFiles(acceptedFiles);
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div className="dropzone">
                      <div
                        className="dz-message needsclick"
                        {...getRootProps()}
                      >
                        <input {...getInputProps()} />
                        <div className="dz-message needsclick">
                          <div className="mb-3">
                            <i className="display-4 text-muted uil uil-cloud-upload" />
                          </div>
                          <h4>Drop files here or click to upload.</h4>
                        </div>
                      </div>
                    </div>
                  )}
                </Dropzone>
                {tsrVrdocument && tsrVrdocument.value && (
                  <div
                    className="dropzone-previews mt-3"
                    id="tsrVrdocument-previews"
                  >
                    <Card
                      className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                      key={`${tsrVrdocument.value.name}-tsrVrdocument`}
                    >
                      <div className="p-2">
                        <Row className="align-items-center">
                          <Col className="col-auto">
                            <img
                              data-dz-thumbnail=""
                              height="80"
                              className="avatar-sm rounded bg-light"
                              alt={tsrVrdocument.value.name}
                              src={tsrVrdocument.preview}
                            />
                          </Col>
                          <Col>
                            <div>
                              {tsrVrdocument.value.name}
                            </div>
                            <p className="mb-0">
                              <strong>{tsrVrdocument.formattedSize}</strong>
                            </p>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  </div>
                )}
              </Form>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => {
                  closeModal();
                }}
              >
                Close
              </button>
              <button type="submit" className="btn btn-primary" onClick={uploadDocument}>
                Save
              </button>
            </div>

          </Form>
        </ModalBody>
      </Modal>
      {isErrorModalOpen && (
        <ErrorModal
          isModalOpen={isErrorModalOpen}
          {...errorModalDetail}
          closeModal={handleCloseErrorModal}
        />
      )}
    </>
  );
}

UploadTSRVRDocument.propTypes = {
  file: PropTypes.object,
  isModalOpen: PropTypes.bool,
  closeModal: PropTypes.func
};

export default UploadTSRVRDocument;
