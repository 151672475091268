/* eslint prefer-const: "off" */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Modal, ModalHeader, ModalBody, Form, Label, Input, FormFeedback
} from 'reactstrap';

import FileService from '../services/FileService';
import ValidationService from '../services/Validation';
import AlertService from '../services/AlertService';

import ErrorModal from './ErrorModal';

import BbaSpinner from '../components/Common/BbaSpinner';

import { extractErrorAndShowToast } from '../utils';

const control = {
  value: '',
  valid: true
};

function Outsource({ file, isModalOpen, closeModal }) {
  let [name, setName] = useState({ ...control });
  // let [type, setType] = useState('tsr');
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorModalDetail, setErrorModalDetail] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (file) {
      setName({ ...control, value: file.handover_name });
    } else {
      // return back to file page
    }
  }, []);

  const handleValidation = () => {
    name = ValidationService.isEmpty(name);
    if (name.isEmpty || name.isInvalidLength) {
      name.valid = false;
    } else {
      name.valid = true;
    }

    setName({ ...name });

    if (
      name.valid
    ) {
      return true;
    }
    return false;
  };

  const updateFile = async () => {
    try {
      const isFormValid = handleValidation();
      if (!isFormValid) {
        return;
      }
      const fileObj = {
        outsourceTo: name.value,
        id: file.uuid
        // type
      };
      setIsLoading(true);
      await FileService.outsourceFile(fileObj);
      AlertService.showToast('Outsource successfully', 'success');
      setIsLoading(false);
      closeModal(true);
    } catch (e) {
      console.log('update failed');
      setIsLoading(false);
      extractErrorAndShowToast(e);
    }
  };

  const handleCloseErrorModal = () => {
    setIsErrorModalOpen(false);
    setErrorModalDetail(null);
  };

  return (
    <>
      <Modal isOpen={isModalOpen}>
        <ModalHeader tag="h4">
          Outsource File
          {/* {!!isEdit ? "Edit Order" : "Add Order"} */}
        </ModalHeader>
        <ModalBody>
          {isLoading && <BbaSpinner />}
          <Form
            className="form-horizontal"
            onSubmit={(e) => {
              e.preventDefault();
              // validation.handleSubmit();

              return false;
            }}
          >
            {/* {error ? <Alert color="danger">{error}</Alert> : null} */}
            <div className="mb-3">
              <Label className="form-label">
                Outsource To
                <span className="text-danger">&nbsp;*</span>
              </Label>
              <Input
                name="name"
                className="form-control"
                placeholder="Enter Name"
                type="text"
                onChange={(e) => setName({ ...name, value: e.target.value })}
                value={name.value || ''}
                invalid={
                  !(name.valid)
                }
              />
              {name.isEmpty ? (
                <FormFeedback type="invalid">Please enter name</FormFeedback>
              ) : null}
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => {
                  closeModal();
                }}
              >
                Close
              </button>
              <button type="submit" className="btn btn-primary" onClick={updateFile}>
                Save
              </button>
            </div>

          </Form>
        </ModalBody>
      </Modal>
      {
        isErrorModalOpen && (
          <ErrorModal
            isModalOpen={isErrorModalOpen}
            {...errorModalDetail}
            closeModal={handleCloseErrorModal}
          />
        )
      }
    </>
  );
}

Outsource.propTypes = {
  file: PropTypes.object,
  isModalOpen: PropTypes.bool,
  closeModal: PropTypes.func
};

export default Outsource;
