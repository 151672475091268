import React from 'react';
import {
  Route, Routes
} from 'react-router-dom';
import { useSelector } from 'react-redux';

import { publicRouteObj, privateRouteObj } from './router-config';
import Protected from '../components/Protected';
import VerticalLayout from '../components/VerticalLayout';
import NonAuthLayout from '../components/NonAuthLayout';
// import Dashboard from '../pages/Dashboard/Dashboard';

const privateRoutes = privateRouteObj;

function CustomRoutes() {
  const {
    isAuthenticated
  } = useSelector((state) => {
    console.log('state...', state);
    return {
      isAuthenticated: state.auth.isAuthenticated,
    };
  });

  function getLayout() {
    let layoutCls = VerticalLayout;
    if (!isAuthenticated) {
      layoutCls = NonAuthLayout;
    }
    // switch (props.layout.layoutType) {
    //   case "horizontal":
    //     layoutCls = HorizontalLayout;
    //     break;
    //   default:
    //     layoutCls = VerticalLayout;
    //     break;
    // }
    return layoutCls;
  }

  const Layout = getLayout();

  const publicRoutesObj = publicRouteObj.map((route) => {
    const Component = route.component;
    return (
      <Route
        key={route.key}
        exact={route.exact}
        path={route.path}
        element={(
          <Protected page={route.key} path={route.path} isProtected={false}>
            <NonAuthLayout>
              <Component />
            </NonAuthLayout>
          </Protected>
        )}
      />
    );
    // const Component = route.component;
    // const token = StorageService.getToken();
    // if (token && route.path === '/') {
    // 	return <Navigate to="/dashboard" replace />;
    // }
    // return <Route key={route.key} exact={route.exact} path={route.path} element={<Component />} />
  });

  const privateRoutesObj = privateRoutes.map((route) => {
    // const Component = RequiredAuth(route.component);
    const Component = route.component;
    return (
      <Route
        key={route.key}
        exact={route.exact}
        path={route.path}
        element={(
          <Protected route={route} page={route.key} path={route.path} isProtected>
            <Layout>
              <Component />
            </Layout>
          </Protected>
        )}
      />
    );
  });

  return (
    <Routes>
      {publicRoutesObj}
      {privateRoutesObj}
      {/* if user enter wrong path redirect to home page */}
      {/* <Route path="*" element={<Dashboard />} /> */}
    </Routes>
  );
}

// export default connect(mapStateToProps, null)(Routes);
// export default (withRouter(Routes));
export default CustomRoutes;
