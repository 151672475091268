import { request } from './request';
import { routes } from '../constants/constant.routes';

const { API_URL } = routes;

export default class CityService {
  static addCity(bankObj) {
    return request('POST', `${API_URL}${routes.ADD_CITY}`, null, bankObj, null);
  }

  static updateCity(bankObj) {
    return request('PUT', `${API_URL}${routes.UPDATE_CITY}`, null, bankObj, null);
  }

  static getCities(page = 1, limit = 10, search = null, active = '', all = 'false') {
    let qString = '';
    if (page) {
      qString += `page=${(page).toString()}&`;
    }
    if (limit) {
      qString += `limit=${limit.toString()}&`;
    }
    if (search) {
      qString += `search=${search}&`;
    }
    if (active) {
      qString += `active=${active}&`;
    }
    if (all) {
      qString += `all=${all}&`;
    }
    return request('GET', `${API_URL}${routes.GET_CITIES}?${qString}`, null, null, null);
  }
}
