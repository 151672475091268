import React from 'react';
import PropTypes from 'prop-types';

import { Modal } from 'reactstrap';

function ConfirmationModal({
  title, isModalOpen, message, closeModal, handleConfirmation,
}) {
  return (
    <Modal
      isOpen={isModalOpen}
      toggle={() => {
        // tog_backdrop()
      }}
      backdrop="static"
      scrollable
      id="staticBackdrop"
    >
      <div className="modal-header">
        <h5
          className="modal-title"
          id="staticBackdropLabel"
        >
          {title}
        </h5>
        <button
          type="button"
          className="btn-close"
          onClick={() => {
            closeModal(false);
          }}
          aria-label="Close"
        />
      </div>
      <div className="modal-body">
        <p>
          {message}
        </p>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-light"
          onClick={() => {
            closeModal(false);
          }}
        >
          No
        </button>
        <button type="button" className="btn btn-primary" onClick={() => handleConfirmation()}>
          Yes
        </button>
      </div>
    </Modal>
  );
}

ConfirmationModal.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  isModalOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  handleConfirmation: PropTypes.func
};

export default ConfirmationModal;
