import { request } from './request';
import { routes } from '../constants/constant.routes';

const { API_URL } = routes;

export default class BranchService {
  static addBranch(branchObj) {
    return request('POST', `${API_URL}${routes.ADD_BRANCH}`, null, branchObj, null);
  }

  static updateBranch(branchObj) {
    return request('PUT', `${API_URL}${routes.UPDATE_BRANCH}`, null, branchObj, null);
  }

  static getBranches(page = 1, limit = 10, search = null, active = '') {
    let qString = '';
    if (page) {
      qString += `page=${(page).toString()}&`;
    }
    if (limit) {
      qString += `limit=${limit.toString()}&`;
    }
    if (search) {
      qString += `search=${search}&`;
    }
    if (active) {
      qString += `active=${active}&`;
    }
    return request('GET', `${API_URL}${routes.GET_BRANCHES}?${qString}`, null, null, null);
  }

  static getBranchDropdown(page = 1, limit = 10, search = null, active = '') {
    let qString = '';
    if (page) {
      qString += `page=${(page).toString()}&`;
    }
    if (limit) {
      qString += `limit=${limit.toString()}&`;
    }
    if (search) {
      qString += `search=${search}&`;
    }
    if (active) {
      qString += `active=${active}&`;
    }
    return request('GET', `${API_URL}${routes.GET_BRANCHES_DROPDOWN}?${qString}`, null, null, null);
  }
}
