import React, { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
// import PropTypes from 'prop-types';

// //Import Scrollbar
import SimpleBar from 'simplebar-react';

// MetisMenu
import MetisMenu from 'metismenujs';
import { useLocation, Link } from 'react-router-dom';

// i18n
// import { withTranslation } from "react-i18next"
import i18next from '../../i18n';
// import StorageService from '../../services/StorageService';
import moduleConstant from '../../constants/module.constant';

let ref = null;

function scrollElement(item) {
  if (item) {
    const currentPosition = item.offsetTop;
    if (currentPosition > window.innerHeight) {
      ref.current.getScrollElement().scrollTop = currentPosition - 300;
    }
  }
}

function SidebarContent() {
  // const ref = useRef();
  ref = useRef();
  const location = useLocation();
  const userPermission = useSelector((state) => state.permission.userPermissions);

  const activateParentDropdown = useCallback((item) => {
    item.classList.add('active');
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== 'side-menu') {
      parent2El.classList.add('mm-show');
    }

    if (parent) {
      parent.classList.add('mm-active');
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add('mm-show'); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add('mm-active'); // li
          parent3.childNodes[0].classList.add('mm-active'); // a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add('mm-show'); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add('mm-show'); // li
              parent5.childNodes[0].classList.add('mm-active'); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = location.pathname;
    new MetisMenu('#side-menu');
    let matchingMenuItem = null;
    const ul = document.getElementById('side-menu');
    const items = ul.getElementsByTagName('a');
    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [location, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
  });

  const isModulePermissionExist = (module) => {
    // const userDetail = StorageService.getUserDetail();
    if (userPermission) {
      const { modulePermissions = [] } = userPermission;
      const moduleDetail = modulePermissions.find((item) => item.name === module && item.checked === true);
      if (moduleDetail) {
        return true;
      }
    }
    return false;
  };

  return (
    <SimpleBar style={{ maxHeight: '100%' }} ref={ref}>
      <div id="sidebar-menu">
        <ul className="metismenu list-unstyled" id="side-menu">
          {/* <li className="menu-title">
            {i18next.t('Menu')}
            {' '}
          </li> */}
          {/* <li>
            <Link to="/#" className="waves-effect">
              <i className="uil-home-alt" />
              <span>{i18next.t('Dashboard')}</span>
            </Link>
          </li> */}
          {isModulePermissionExist(moduleConstant.FILE) && (
            <li className={`waves-effect ${location.pathname === '/file' ? 'mm-active' : ''}`}>
              <Link to="/file" className="waves-effect">
                <i className="uil-files-landscapes-alt" />
                {/* <span className="badge rounded-pill bg-primary float-end">
                  01
                </span> */}
                <span>{i18next.t('Files')}</span>
              </Link>
            </li>
          )}
          {isModulePermissionExist(moduleConstant.USER) && (
            <li className={`waves-effect ${location.pathname === '/users' ? 'mm-active' : ''}`}>
              <Link to="/users" className="waves-effect">
                <i className="uil-users-alt" />
                {/* <span className="badge rounded-pill bg-primary float-end">
                  01
                </span> */}
                <span>{i18next.t('Users')}</span>
              </Link>
            </li>
          )}

          {isModulePermissionExist(moduleConstant.BANK) && (
            <li className={`waves-effect ${location.pathname === '/banks' ? 'mm-active' : ''}`}>
              <Link to="/banks" className="waves-effect">
                <i className="uil-building" />
                {/* <span className="badge rounded-pill bg-primary float-end">
                  01
                </span> */}
                <span>{i18next.t('Banks')}</span>
              </Link>
            </li>
          )}

          {isModulePermissionExist(moduleConstant['BANK CONFIGURATION']) && (
            <li className={`waves-effect ${location.pathname === '/company' ? 'mm-active' : ''}`}>
              <Link to="/company" className="waves-effect">
                <i className="uil-books" />
                {/* <span className="badge rounded-pill bg-primary float-end">
                  01
                </span> */}
                <span>{i18next.t('Bank Configuration')}</span>
              </Link>
            </li>
          )}

          {isModulePermissionExist(moduleConstant.CITY) && (
            <li className={`waves-effect ${location.pathname === '/city' ? 'mm-active' : ''}`}>
              <Link to="/city" className="waves-effect">
                <i className="uil-globe" />
                {/* <span className="badge rounded-pill bg-primary float-end">
                  01
                </span> */}
                <span>{i18next.t('City')}</span>
              </Link>
            </li>
          )}

          {isModulePermissionExist(moduleConstant.BRANCH) && (
            <li className={`waves-effect ${location.pathname === '/branch' ? 'mm-active' : ''}`}>
              <Link to="/branch" className="waves-effect">
                <i className="uil-globe" />
                {/* <span className="badge rounded-pill bg-primary float-end">
                  01
                </span> */}
                <span>{i18next.t('Branch')}</span>
              </Link>
            </li>
          )}

          {/* <li className="menu-title">{i18next.t('Apps')}</li>

          <li>
            <Link to="/calendar" className=" waves-effect">
              <i className="uil-calender" />
              <span>{i18next.t('Calendar')}</span>
            </Link>
          </li>

          <li>
            <Link to="/chat" className="waves-effect">
              <i className="uil-comments-alt" />
              <span className="badge rounded-pill bg-warning float-end">
                {i18next.t('New')}
              </span>
              <span>{i18next.t('Chat')}</span>
            </Link>
          </li> */}

          {/* <li className="menu-title">Pages</li>
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="uil-user-circle"></i>
                <span>{i18next.t("Authentication")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/pages-login">{i18next.t("Login")}</Link>
                </li>
                <li>
                  <Link to="/pages-register">{i18next.t("Register")}</Link>
                </li>
                <li>
                  <Link to="/page-recoverpw">
                    {i18next.t("Recover Password")}
                  </Link>
                </li>
                <li>
                  <Link to="/auth-lock-screen">{i18next.t("Lock Screen")}</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="uil-file-alt"></i>
                <span>{i18next.t("Utility")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/pages-starter">{i18next.t("Starter Page")}</Link>
                </li>
                <li>
                  <Link to="/pages-maintenance">{i18next.t("Maintenance")}</Link>
                </li>
                <li>
                  <Link to="/pages-comingsoon">{i18next.t("Coming Soon")}</Link>
                </li>
                <li>
                  <Link to="/pages-timeline">{i18next.t("Timeline")}</Link>
                </li>
                <li>
                  <Link to="/pages-faqs">{i18next.t("FAQs")}</Link>
                </li>
                <li>
                  <Link to="/pages-pricing">{i18next.t("Pricing")}</Link>
                </li>
                <li>
                  <Link to="/pages-404">{i18next.t("Error 404")}</Link>
                </li>
                <li>
                  <Link to="/pages-500">{i18next.t("Error 500")}</Link>
                </li>
              </ul>
            </li> */}
        </ul>
      </div>
    </SimpleBar>
  );
}

SidebarContent.propTypes = {
  // location: PropTypes.object,
  // t: PropTypes.any,
};

export default SidebarContent;
