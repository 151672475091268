import { request } from './request';
import { routes } from '../constants/constant.routes';

const { API_URL } = routes;

export default class UserBranchAccessService {
  static addUserBranch(branchObj) {
    return request('POST', `${API_URL}${routes.ADD_USER_BRANCH}`, null, branchObj, null);
  }

  // static updateBranch(branchObj) {
  //   return request('PUT', `${API_URL}${routes.UPDATE_BRANCH}`, null, branchObj, null);
  // }

  static getUserBranch(page = 1, limit = 10, userId = null, active = '') {
    let qString = '';
    if (page) {
      qString += `page=${(page).toString()}&`;
    }
    if (limit) {
      qString += `limit=${limit.toString()}&`;
    }
    if (userId) {
      qString += `userId=${userId}&`;
    }
    if (active) {
      qString += `active=${active}&`;
    }
    return request('GET', `${API_URL}${routes.GET_USER_BRANCH}?${qString}`, null, null, null);
  }
}
