import React, { useEffect, useState, useCallback } from 'react';
import {
  Row, Col, Card, CardBody, CardTitle, Input, Button,
  // Label
} from 'reactstrap';
import {
  Table, Thead, Tbody, Tr, Th, Td,
} from 'react-super-responsive-table';
// import Switch from 'react-switch';
import _debounce from 'lodash/debounce';

import BbaSpinner from '../../components/Common/BbaSpinner';
import Pagination from '../../components/Common/Pagination';

import CityService from '../../services/CityService';

import ConfirmationModal from '../../modal/ConfirmationModal';
import AddCityModal from '../../modal/AddCity';
import ErrorModal from '../../modal/ErrorModal';

import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { extractErrorAndShowToast } from '../../utils';

function City() {
  const [cities, setCities] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [active, setActive] = useState('');
  const [search, setSearch] = useState('');
  const [pageCount, setPageCount] = useState(10);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCityModalOpen, setIsCityModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorModalDetail, setErrorModalDetail] = useState(null);
  const [cityToUpdate, setCityToUpdate] = useState(null);

  useEffect(() => {
    getCities();
    console.log(process.env.REACT_APP_API_URL);
    // @NOTE: added to remove validation error
    setLimit(10);
  }, []);

  useEffect(() => {
    getCities();
  }, [page, limit, active]);

  useEffect(() => {
    console.log('search', search);
    getCityDebounceFunction(search);
  }, [search]);

  const getCities = async (searchKeyword) => {
    try {
      const cityResponse = await CityService.getCities(page, limit, searchKeyword || search, active);
      const cityResponseData = cityResponse.data;
      const { data, totalPages } = cityResponseData;
      console.log('cityResponseData', cityResponseData);
      // setUsers(userResponseData.users);
      setCities(data);
      setPageCount(totalPages);
      setIsLoading(false);
    } catch (e) {
      console.log('e', e);
      // const errorObj = {
      //   title: 'Citys',
      //   message: 'Something went wrong!!',
      // };
      // setErrorModalDetail(errorObj);
      // setIsErrorModalOpen(true);
      extractErrorAndShowToast(e);
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    }
  };

  const getCityDebounceFunction = useCallback(_debounce(getCities, 500), []);

  const updateCity = async (updateObj) => {
    try {
      await CityService.updateCity(updateObj);
    } catch (e) {
      // console.log('update failed');
      // const errorObj = {
      //   title: 'Citys',
      //   message: 'Something went wrong!!',
      // };
      // setErrorModalDetail(errorObj);
      // setIsErrorModalOpen(true);
      extractErrorAndShowToast(e);
    }
  };

  const activeOrInactiveCity = async (cityId, value) => {
    const updateObj = {
      id: cityId,
      isActive: value,
    };
    await updateCity(updateObj);

    // @NOTE: comment once api is active
    const city = cities.find((b) => b.uuid === cityId);
    city.is_active = value;

    setCities([...cities]);
    // call update user api
  };

  const openDeleteCityModal = (city) => {
    setCityToUpdate(city);
    setIsModalOpen(true);
  };

  const editCity = (city) => {
    setIsCityModalOpen(true);
    setCityToUpdate(city);
  };

  const deleteCity = async (city) => {
    const updateObj = {
      id: city.uuid,
      isDeleted: true,
    };
    await updateCity(updateObj);
    setCityToUpdate(null);
    getCities();
    setIsModalOpen(false);
  };

  const handlePageChange = (selectedPage) => {
    setPage(selectedPage);
  };

  const handleCloseConfirmationModal = () => {
    setIsModalOpen(false);
  };

  const handleCloseErrorModal = () => {
    setIsErrorModalOpen(false);
    setErrorModalDetail(null);
  };

  const handleCloseCityModal = (reload) => {
    setIsCityModalOpen(false);
    setCityToUpdate(null);
    if (reload) {
      getCities();
    }
  };
  const openAddCityModal = () => {
    setIsCityModalOpen(true);
  };

  return (
    <div className="page-content">
      <div className="container-fluid">
        {/* <Breadcrumbs title="Tables" breadcrumbItem="Responsive Table" /> */}

        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                {isLoading && <BbaSpinner />}
                <CardTitle className="h4">City </CardTitle>
                <Row>
                  <Col sm={10} md={5} lg={5}>
                    <div className="pb-3">
                      <div className="search-box chat-search-box">
                        <div className="position-relative">
                          <Input
                            type="text"
                            className="form-control bg-light border-light rounded"
                            placeholder="Search..."
                            onChange={(e) => setSearch(e.target.value)}
                          />
                          <i className="uil uil-search search-icon" />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col sm={6} md={2} lg={2}>
                    <div className="pb-3">
                      {/* <Label className="form-label">Role</Label> */}

                      <div className="position-relative">
                        <Input
                          type="select"
                          className="pagination-button-size"
                          // className="form-control bg-light border-light rounded"
                          // placeholder="Search..."
                          onChange={(e) => setActive(e.target.value)}
                        >
                          <option value="">All Cities</option>
                          <option value="true">Active</option>
                          <option value="false">InActive</option>
                        </Input>
                      </div>
                    </div>
                  </Col>

                  <Col sm={6} md={5} lg={5}>
                    <div className="pb-3 text-sm-end">
                      <Button
                        type="button"
                        color="success"
                        className="waves-effect waves-light mb-3 btn btn-success"
                        onClick={openAddCityModal}
                      >
                        <i className="mdi mdi-plus me-1" />
                        Add City
                      </Button>
                    </div>
                  </Col>
                </Row>

                <div className="table-rep-plugin">
                  <div
                    className="table-responsive mb-0"
                    data-pattern="priority-columns"
                  >
                    <Table
                      id="tech-companies-1"
                      className="table table-striped"
                    >
                      <Thead>
                        <Tr>
                          <Th style={{ minWidth: '400px' }}>Name</Th>
                          <Th>Active</Th>
                          <Th style={{ width: '120px' }}>Actions</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {cities.map((city) => (
                          <Tr>
                            <Td>
                              {city.name}
                            </Td>
                            <Td>
                              {/* {user.is_active.toString()} */}

                              <div className="form-check form-switch form-switch-md">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="customSwitchsizemd"
                                  checked={!!city.is_active}
                                  // defaultChecked
                                  onClick={() => {
                                    activeOrInactiveCity(city.uuid, !city.is_active);
                                  }}
                                />
                              </div>
                            </Td>
                            <Td>
                              <span onClick={() => editCity(city)} role="link" tabIndex="-1" className="text-primary">
                                <i className="uil uil-pen font-size-20" />
                              </span>
                              &nbsp;&nbsp;
                              <span onClick={() => openDeleteCityModal(city)} role="button" tabIndex="-1" className="text-danger">
                                <i className="uil-trash-alt bba-icon font-size-20" />
                              </span>
                            </Td>
                          </Tr>
                        ))}
                        {
                          cities.length === 0 && (
                            <Tr>
                              <Td colSpan="3" className="text-center">No city found</Td>
                            </Tr>
                          )
                        }
                      </Tbody>
                    </Table>
                  </div>
                </div>
                {isModalOpen && (
                  <ConfirmationModal
                    isModalOpen={isModalOpen}
                    title="Delete City"
                    message="Are you sure you want to delete City?"
                    closeModal={handleCloseConfirmationModal}
                    handleConfirmation={() => deleteCity(cityToUpdate)}
                  />
                )}

                {isCityModalOpen && (
                  <AddCityModal
                    isModalOpen={isCityModalOpen}
                    city={cityToUpdate}
                    handleCloseModal={handleCloseCityModal}
                  />
                )}

                {isErrorModalOpen && (
                  <ErrorModal
                    isModalOpen={isErrorModalOpen}
                    {...errorModalDetail}
                    closeModal={handleCloseErrorModal}
                  />
                )}

                <Pagination
                  handlePageChange={handlePageChange}
                  page={page}
                  pageCount={pageCount}
                />

              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
  // return <div className="page-content">Users</div>
}

export default City;
