/* eslint prefer-const: "off" */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Modal, ModalHeader, ModalBody
} from 'reactstrap';
import Stepper from '../components/Stepper';

import ErrorModal from './ErrorModal';

import AddUser from '../components/AddUser';
import UserBranchAccess from '../components/UserBranchAccess';
import UserPermissions from '../components/UserPermissions';

// const steps = [
//   'add_user',
//   'user_branch_access',
//   'user_permissons'
// ]
function AddUserModal({
  user = {}, isModalOpen, handleCloseModal, reloadList
}) {
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorModalDetail, setErrorModalDetail] = useState(null);
  const [userId, setUserId] = useState(null);
  const [userDetail, setUserDetail] = useState({ ...user });
  const [stepIndex, setStepIndex] = useState(0);

  const handleCloseErrorModal = () => {
    setIsErrorModalOpen(false);
    setErrorModalDetail(null);
  };

  const nextStep = (userDetailParam) => {
    console.log('userDetailParam', userDetailParam);
    setUserDetail(userDetailParam);
    setStepIndex(stepIndex + 1);
  };

  const prevStep = () => {
    setStepIndex(stepIndex - 1);
  };

  useEffect(() => {
    if (user) {
      setUserId(user.uuid);
    }
  }, []);

  return (
    <>
      <Modal isOpen={isModalOpen} style={{ maxWidth: '800px' }}>
        <ModalHeader tag="h4">
          {userId ? 'Edit User' : 'Add User'}
          {/* {!!isEdit ? "Edit Order" : "Add Order"} */}
        </ModalHeader>
        <ModalBody>
          <div>
            <Stepper
              completeColor="#4d62c5"
              activeColor="#4d62c5"
              steps={[{ title: 'User Info' }, { title: 'Setup Branch' }, { title: 'Permissions' }]}
              activeStep={stepIndex}
              titleTop={5}
              titleFontSize={14}
            />
            <hr />
          </div>
          {stepIndex === 0 && <AddUser user={userDetail} nextStep={nextStep} prevStep={prevStep} reloadList={reloadList} handleCloseModal={handleCloseModal} />}
          {stepIndex === 1 && <UserBranchAccess user={userDetail} nextStep={nextStep} prevStep={prevStep} handleCloseModal={handleCloseModal} />}
          {stepIndex === 2 && <UserPermissions user={userDetail} nextStep={nextStep} prevStep={prevStep} handleCloseModal={handleCloseModal} />}
        </ModalBody>
      </Modal>
      {isErrorModalOpen && (
        <ErrorModal
          isModalOpen={isErrorModalOpen}
          {...errorModalDetail}
          closeModal={handleCloseErrorModal}
        />
      )}
    </>
  );
}

AddUserModal.propTypes = {
  user: PropTypes.object,
  isModalOpen: PropTypes.bool,
  reloadList: PropTypes.func,
  handleCloseModal: PropTypes.func
};

export default AddUserModal;
