import headerReducer from './header-slice';
import layoutReducer from './layout';
import authReducer from './auth';
import permissionReducer from './permission';

export default {
  headerReducer,
  Layout: layoutReducer,
  auth: authReducer,
  permission: permissionReducer
};
