import { useState } from 'react';
import UserPermissionsService from '../services/UserPermissionsService';
import { extractErrorAndShowToast } from '../utils';

function useUserAssignedPermissions() {
  const [isLoading, setIsLoading] = useState([]);
  const [modulePermissions, setModulePermissions] = useState([]);
  const [userPermissionMap, setModulePeruserPermissionMap] = useState({});

  const getModulePermissions = async () => {
    const modulePermissionsResponse = await UserPermissionsService.getModulePermissions();
    const modulePermissionsResponseData = modulePermissionsResponse.data;
    setModulePermissions(modulePermissionsResponseData);
    return modulePermissionsResponseData;
  };

  const getUserPermissions = async (userId) => {
    const userPermissions = await UserPermissionsService.getUserPermissions(userId);
    const userPermissionsData = userPermissions.data;
    return userPermissionsData;
  };

  const getUserAssignedPermissionsInModulePermissions = async (user) => {
    try {
      setIsLoading(true);
      const [userPermissions, modulePermissionsResponse] = await Promise.all([
        getUserPermissions(user.uuid),
        getModulePermissions()
      ]);
      // write code to convert getUserPermissions array into map
      const userPermissionsMap = {};
      for (let i = 0; i < userPermissions.length; i++) {
        userPermissionsMap[userPermissions[i]] = true;
      }
      console.log('userPermissionsMap', userPermissionsMap);
      for (const module of modulePermissionsResponse) {
        for (const permission of module.modulePermission) {
          if (userPermissionsMap[permission.permissionValue]) {
            module.checked = true;
            permission.checked = true;
          }
        }
      }

      setModulePeruserPermissionMap(userPermissionsMap);
      setModulePermissions([...modulePermissionsResponse]);
      setIsLoading(false);
      return { userPermissionsMap, modulePermissionsResponse };
    } catch (e) {
      console.log('e', e);
      extractErrorAndShowToast(e);
      setIsLoading(false);
      return {};
    }
  };

  return {
    getUserAssignedPermissionsInModulePermissions,
    setModulePermissions,
    modulePermissions,
    userPermissionMap,
    isLoading
  };
}

export default useUserAssignedPermissions;
