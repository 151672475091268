import PropTypes from 'prop-types';
import React, { useState } from 'react';

// import { useDispatch } from 'react-redux';

import { Link } from 'react-router-dom';

// Reactstrap
import {
  Form, Input, Button, Dropdown, DropdownToggle, DropdownMenu
} from 'reactstrap';

// Import menuDropdown
// import { withTranslation, useTranslation } from 'react-i18next';
// import LanguageDropdown from '../CommonForBoth/TopbarDropdown/LanguageDropdown';
// import NotificationDropdown from '../CommonForBoth/TopbarDropdown/NotificationDropdown';
import ProfileMenu from '../CommonForBoth/TopbarDropdown/ProfileMenu';

import logoSm from '../../assets/images/bba-logo.png';
import logoBg from '../../assets/images/bba-long-header.png';
import logoLight from '../../assets/images/logo-light.png';

// import images
// import github from "../../assets/images/brands/github.png";
// import bitbucket from "../../assets/images/brands/bitbucket.png";
// import dribbble from "../../assets/images/brands/dribbble.png";
// import dropbox from "../../assets/images/brands/dropbox.png";
// import mail_chimp from "../../assets/images/brands/mail_chimp.png";
// import slack from "../../assets/images/brands/slack.png";

// i18n
// import i18next from '../../i18n';

// Redux Store
// import {
//   showRightSidebarAction,
//   toggleLeftmenu,
//   // changeSidebarType,
// } from '../../redux-slices/layout';

function Header({ toggleMenuCallback }) {
  // const dispatch = useDispatch();
  // const navigate = useNavigate();
  // const t = useTranslation();
  const [search, setsearch] = useState(false);
  // const [socialDrp, setsocialDrp] = useState(false);

  function toggleFullscreen() {
    if (
      !document.fullscreenElement
      /* alternative standard method */ && !document.mozFullScreenElement
      && !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT,
        );
      }
    } else if (document.cancelFullScreen) {
      document.cancelFullScreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitCancelFullScreen) {
      document.webkitCancelFullScreen();
    }
  }

  function tToggle() {
    const { body } = document;
    body.classList.toggle('vertical-collpsed');
    body.classList.toggle('sidebar-enable');
    toggleMenuCallback();
  }
  return (
    <header id="page-topbar">
      <div className="navbar-header">
        <div className="d-flex">
          <div className="navbar-brand-box">
            <Link to="/" className="logo logo-dark">
              <span className="logo-sm">
                <img src={logoSm} alt="" className="log-small" />
              </span>
              <span className="logo-lg">
                <img src={logoBg} alt="" className="log-big" />
              </span>
            </Link>

            <Link to="/" className="logo logo-light">
              <span className="logo-sm">
                <img src={logoSm} alt="" height="38" />
              </span>
              <span className="logo-lg">
                <img src={logoLight} alt="" height="36" />
              </span>
            </Link>
          </div>

          <button
            type="button"
            onClick={() => {
              tToggle();
            }}
            className="btn btn-sm px-3 font-size-16 header-item waves-effect vertical-menu-btn"
            id="vertical-menu-btn"
          >
            <i className="fa fa-fw fa-bars" />
          </button>

          {/* <Form className="app-search d-none d-lg-block">
            <div className="position-relative">
              <input
                type="text"
                className="form-control"
                placeholder={`${i18next.t('Search')}...`}
              />
              <span className="uil-search" />
            </div>
          </Form> */}
        </div>

        <div className="d-flex">

          <Dropdown
            className="d-inline-block d-lg-none ms-2"
            onClick={() => {
              setsearch(!search);
            }}
            type="button"
          >
            <DropdownToggle
              className="btn header-item noti-icon waves-effect"
              id="page-header-search-dropdown"
              tag="button"
            >
              {' '}
              <i className="uil-search" />
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
              <Form className="p-3">
                <div className="form-group m-0">
                  <div className="input-group">
                    <Input type="text" className="form-control" placeholder="Search ..." aria-label="Recipient's username" />
                    <div className="input-group-append">
                      <Button className="btn btn-primary" type="submit"><i className="mdi mdi-magnify" /></Button>
                    </div>
                  </div>
                </div>
              </Form>
            </DropdownMenu>
          </Dropdown>

          {/* <LanguageDropdown /> */}

          <Dropdown className="d-none d-lg-inline-block ms-1">
            <button
              type="button"
              onClick={() => {
                toggleFullscreen();
              }}
              className="btn header-item noti-icon waves-effect"
              data-toggle="fullscreen"
            >
              <i className="uil-minus-path" />
            </button>
          </Dropdown>

          {/* <NotificationDropdown /> */}

          <ProfileMenu />

          {/* <div onClick={() => {
              dispatch(showRightSidebarAction(!props.showRightSidebar));
            }}
              className="dropdown d-inline-block">
              <button type="button" className="btn header-item noti-icon right-bar-toggle waves-effect">
                <i className="uil-cog"></i>
              </button>
            </div> */}

        </div>
      </div>
    </header>
  );
}

Header.propTypes = {
  toggleMenuCallback: PropTypes.func
  // changeSidebarType: PropTypes.func,
  // leftMenu: PropTypes.any,
  // leftSideBarType: PropTypes.any,
  // showRightSidebar: PropTypes.any,
  // showRightSidebarAction: PropTypes.func,
  // t: PropTypes.any,
  // toggleLeftmenu: PropTypes.func,
};

// const mapStatetoProps = (state) => {
//   const {
//     layoutType,
//     showRightSidebar,
//     leftMenu,
//     leftSideBarType,
//   } = state.Layout;
//   return {
//     layoutType, showRightSidebar, leftMenu, leftSideBarType,
//   };
// };

export default Header;
