import React, { useEffect, useState, useCallback } from 'react';
import {
  Row, Col, Card, CardBody, CardTitle, Input, Button,
  // Label
} from 'reactstrap';
import {
  Table, Thead, Tbody, Tr, Th, Td,
} from 'react-super-responsive-table';
// import Switch from 'react-switch';
import _debounce from 'lodash/debounce';

import { useSelector } from 'react-redux';
import BbaSpinner from '../../components/Common/BbaSpinner';
import Pagination from '../../components/Common/Pagination';

import UserService from '../../services/UserService';
import RoleService from '../../services/RoleService';

import ConfirmationModal from '../../modal/ConfirmationModal';
import AddUserModal from '../../modal/AddUser';
import ErrorModal from '../../modal/ErrorModal';
import UserDetailModal from '../../modal/UserDetail';

import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
// import StorageService from '../../services/StorageService';
import { extractErrorAndShowToast } from '../../utils';
import permissionConstant from '../../constants/permission.constant';
import AlertService from '../../services/AlertService';
// import StorageService from '../../services/StorageService';

// const userDetail = StorageService.getUserDetail();
// const { permissions = {} } = userDetail;[]
// console.log('permissions', permissions);
const {
  ADD_USER,
  UPDATE_USER,
  DELETE_USER
} = permissionConstant;

// console.log('permissions', permissions[ADD_USER]);
// const usersList = [{
//   uuid: '837657863845',
//   first_name: 'Arpan',
//   last_name: 'Shah',
//   address: 'Pal, Surat',
//   phone_number: '9033340163',
//   email: 'shaharpan05@gmail.com',
//   is_active: true,
//   role: 'admin',

// },
// {
//   uuid: '837657863846',
//   first_name: 'Arpan',
//   last_name: 'Shah',
//   address: 'Pal, Surat',
//   phone_number: '9033340163',
//   email: 'shaharpan05@gmail.com',
//   is_active: true,
//   role: 'admin',
// }];

// const userDetail = StorageService.getUserDetail();
function Users() {
  const userPermissions = useSelector((state) => state.permission.userPermissions);
  const [roles, setRoles] = useState([]);
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [active, setActive] = useState('');
  const [role, setRole] = useState('');
  const [search, setSearch] = useState('');
  const [pageCount, setPageCount] = useState(10);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorModalDetail, setErrorModalDetail] = useState(null);
  const [userToUpdate, setUserToUpdate] = useState(null);
  const [isUserDetailModalOpen, setIsUserDetailModalOpen] = useState(false);
  const [isInActiveUserModalOpen, setIsInActiveUserModalOpen] = useState(false);
  const [userToInactive, setUserToInactive] = useState(false);
  const permissions = userPermissions ? userPermissions?.permissions : {};

  useEffect(() => {
    setLimit(10);
    getRoles();
  }, []);

  useEffect(() => {
    getUsers();
  }, [page, limit, role, active]);

  useEffect(() => {
    console.log('search', search);
    getUserDebounceFunction(search);
  }, [search]);

  const getUsers = async (searchKeyword) => {
    try {
      const userResponse = await UserService.getUsers(page, limit, searchKeyword || search, role, active);
      const userResponseData = userResponse.data;
      console.log('userResponseData', userResponseData);
      setUsers(userResponseData.data);
      // setUsers(usersList);
      setPageCount(userResponseData.totalPages);
      setIsLoading(false);
    } catch (e) {
      // console.log('e', e);
      // const errorObj = {
      //   title: 'Users',
      //   message: 'Something went wrong!!',
      // };
      // setErrorModalDetail(errorObj);
      // setIsErrorModalOpen(true);
      extractErrorAndShowToast(e);
      // setUsers(usersList);
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    }
  };

  const getUserDebounceFunction = useCallback(_debounce(getUsers, 1000), []);

  const updateUser = async (updateObj) => {
    try {
      await UserService.updateUser(updateObj);
    } catch (e) {
      console.log('update failed');
      // const errorObj = {
      //   title: 'Users',
      //   message: 'Something went wrong!!',
      // };
      // setErrorModalDetail(errorObj);
      // setIsErrorModalOpen(true);
      extractErrorAndShowToast(e);
    }
  };

  const activeOrInactiveUser = async (userId = userToInactive, value = false) => {
    const updateObj = {
      id: userId,
      isActive: value,
    };
    try {
      await updateUser(updateObj);
      getUsers();
      if (userToInactive) {
        setUserToInactive(null);
        setIsInActiveUserModalOpen(false);
      }
    } catch (e) {
      extractErrorAndShowToast(e);
    }
    // @NOTE: comment once api is active
    // const user = users.find((u) => u.uuid === userId);
    // user.is_active = value;

    // setUsers([...users]);
    // call update user api
  };

  const openDeleteUserModal = (user) => {
    console.log('user', user);
    setUserToUpdate(user);
    setIsModalOpen(true);
  };

  const openInactiveUserModal = (user) => {
    console.log('user', user);
    setUserToInactive(user);
    setIsInActiveUserModalOpen(true);
  };

  const closeInactiveUserModal = () => {
    setUserToInactive(null);
    setIsInActiveUserModalOpen(false);
  };

  const editUser = (user) => {
    setUserToUpdate(user);
    setIsUserModalOpen(true);
  };

  const deleteUser = async () => {
    console.log('userToUpdate', userToUpdate);
    const updateObj = {
      id: userToUpdate.uuid,
      isDeleted: true,
    };
    await updateUser(updateObj);
    await setUserToUpdate(null);
    setIsModalOpen(false);
    getUsers();
  };

  const handlePageChange = (selectedPage) => {
    setPage(selectedPage);
  };

  const handleCloseConfirmationModal = () => {
    setIsModalOpen(false);
  };

  const handleCloseErrorModal = () => {
    setIsErrorModalOpen(false);
    setErrorModalDetail(null);
  };

  const resendVerificationEmail = async (userObj) => {
    const { uuid: userId } = userObj;
    try {
      await UserService.resendVerificationEmail(userId);
      AlertService.showToast('Verification Mail Sent Successfully');
    } catch (e) {
      extractErrorAndShowToast(e);
    }
  };

  const handleCloseUserModal = (reload) => {
    setIsUserModalOpen(false);
    setUserToUpdate(null);
    if (reload) {
      if (page === 1) {
        getUsers();
      }
      setPage(1);
    }
  };
  const openAddUserModal = () => {
    setIsUserModalOpen(true);
  };

  const getRoles = async () => {
    const rolesResponse = await RoleService.getRoles();
    setRoles(rolesResponse.data.data);
    console.log('roles', roles);
  };

  const handleOpenUserDetailModal = (userObj) => {
    setIsUserDetailModalOpen(true);
    setUserToUpdate(userObj);
  };

  const handleCloseUserDetailModal = () => {
    setIsUserDetailModalOpen(false);
    setUserToUpdate(null);
  };

  return (
    <div className="page-content">
      <div className="container-fluid">
        {/* <Breadcrumbs title="Tables" breadcrumbItem="Responsive Table" /> */}

        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                {isLoading && <BbaSpinner />}
                <CardTitle className="h4">Users </CardTitle>
                <Row>
                  <Col sm={10} md={5} lg={5}>
                    <div className="pb-3">
                      <div className="search-box chat-search-box">
                        <div className="position-relative">
                          <Input
                            type="text"
                            className="form-control bg-light border-light rounded "
                            placeholder="Search by Name, Email, Phone Number"
                            onChange={(e) => setSearch(e.target.value)}
                          />
                          <i className="uil uil-search search-icon" />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col sm={6} md={2} lg={2}>
                    <div className="pb-3">
                      {/* <Label className="form-label">Role</Label> */}

                      <div className="position-relative">
                        <Input
                          type="select"
                          className="pagination-button-size"
                          // className="form-control bg-light border-light rounded"
                          // placeholder="Search..."
                          onChange={(e) => setRole(e.target.value)}
                        >
                          <option value="">Role</option>
                          {roles.map((r) => <option value={r.id}>{r.name}</option>)}
                        </Input>
                      </div>
                    </div>
                  </Col>
                  <Col sm={6} md={2} lg={2}>
                    <div className="pb-3">
                      {/* <Label className="form-label">Role</Label> */}

                      <div className="position-relative">
                        <Input
                          type="select"
                          className="pagination-button-size"
                          // className="form-control bg-light border-light rounded"
                          // placeholder="Search..."
                          onChange={(e) => setActive(e.target.value)}
                        >
                          <option value="">All Users</option>
                          <option value="true">Active</option>
                          <option value="false">InActive</option>
                        </Input>
                      </div>
                    </div>
                  </Col>

                  <Col sm={2} md={3} lg={3}>
                    {permissions[ADD_USER] && (
                      <div className="pb-3 text-sm-end">
                        <Button
                          type="button"
                          color="success"
                          className="waves-effect waves-light mb-3 btn btn-success"
                          onClick={openAddUserModal}
                        >
                          <i className="mdi mdi-plus me-1" />
                          Add User
                        </Button>
                      </div>
                    )}
                  </Col>
                </Row>

                <div className="table-rep-plugin">
                  <div
                    className="table-responsive mb-0"
                    data-pattern="priority-columns"
                  >
                    <Table
                      className="table table-striped"
                    >
                      <Thead>
                        <Tr>
                          <Th>Name</Th>
                          <Th>Email</Th>
                          {/* <Th>Address</Th> */}
                          <Th>Phone Number</Th>
                          <Th>Role</Th>
                          <Th>Branch</Th>
                          <Th>Active</Th>
                          <Th style={{ width: '130px' }}>Actions</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {users.length > 0 && users.map((user) => (
                          <>
                            {user && (
                              <Tr>
                                <Td>
                                  {`${user.first_name || ''} ${user.last_name}`}
                                </Td>
                                <Td>
                                  {user.email.toLowerCase()}
                                  {' '}
                                  <span tabIndex="-1" className={user.is_email_confirmed ? 'text-success' : 'text-secondary'}>
                                    <i title={user.is_email_confirmed ? 'Verified' : 'Unverified'} className="uil uil-check-circle font-size-15" />
                                  </span>

                                </Td>
                                {/* <Td>{user.address}</Td> */}
                                <Td>{user.phone_number}</Td>
                                <Td>{user.role}</Td>
                                <Td>{user.branch_name}</Td>
                                <Td>
                                  <div className="form-check form-switch form-switch-md">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      id="customSwitchsizemd"
                                      checked={!!user.active}
                                      // defaultChecked
                                      onClick={() => {
                                        if (!user.active) {
                                          activeOrInactiveUser(user.uuid, !user.active);
                                        } else {
                                          openInactiveUserModal(user.uuid);
                                        }
                                      }}
                                    />
                                  </div>
                                </Td>
                                <Td>
                                  <span onClick={() => handleOpenUserDetailModal(user)} role="button" tabIndex="-1" className="text-success">
                                    <i className="uil uil-eye font-size-20" />
                                  </span>
                                  &nbsp;&nbsp;
                                  {!user.is_email_confirmed && (
                                    <>
                                      <span onClick={() => resendVerificationEmail(user)} title="Resend Verification Email" role="button" tabIndex="-1" className="text-warning">
                                        <i className="uil uil-envelope font-size-20" />
                                      </span>
                                      &nbsp;&nbsp;
                                    </>
                                  )}
                                  {permissions[UPDATE_USER] && (
                                    <>
                                      <span onClick={() => editUser(user)} role="button" tabIndex="-1" className="text-primary">
                                        <i className="uil uil-pen font-size-20" />
                                      </span>
                                      &nbsp;&nbsp;
                                    </>
                                  )}
                                  {permissions[DELETE_USER] && (
                                    <span onClick={() => openDeleteUserModal(user)} role="button" tabIndex="-1" className="text-danger">
                                      <i className="uil-trash-alt bba-icon font-size-20" />
                                    </span>
                                  )}
                                </Td>
                              </Tr>
                            )}
                            {/* {file.isActionMenuOpen && (
                              <Tr>
                                <Td>
                                  &nbsp;
                                </Td>
                              </Tr>
                            )} */}
                          </>
                        ))}
                        {
                          users.length === 0 && (
                            <Tr>
                              <Td colSpan="7" className="text-center">No User Found</Td>
                            </Tr>
                          )
                        }
                      </Tbody>
                    </Table>
                  </div>
                </div>
                {isModalOpen && (
                  <ConfirmationModal
                    isModalOpen={isModalOpen}
                    title="Delete User"
                    message="Are you sure you want to delete User?"
                    closeModal={handleCloseConfirmationModal}
                    handleConfirmation={() => deleteUser()}
                  />
                )}

                {isInActiveUserModalOpen && (
                  <ConfirmationModal
                    isModalOpen={isInActiveUserModalOpen}
                    title="Inactive User"
                    message="Are you sure you want to inactive User?"
                    closeModal={closeInactiveUserModal}
                    handleConfirmation={() => activeOrInactiveUser()}
                  />
                )}

                {isUserModalOpen && (
                  <AddUserModal
                    isModalOpen={isUserModalOpen}
                    user={userToUpdate}
                    reloadList={getUsers}
                    handleCloseModal={handleCloseUserModal}
                  />
                )}

                {isErrorModalOpen && (
                  <ErrorModal
                    isModalOpen={isErrorModalOpen}
                    {...errorModalDetail}
                    closeModal={handleCloseErrorModal}
                  />
                )}

                {isUserDetailModalOpen && (
                  <UserDetailModal
                    isModalOpen={isUserDetailModalOpen}
                    user={userToUpdate}
                    closeModal={handleCloseUserDetailModal}
                  />
                )}

                {pageCount > 1 && (
                  <Pagination
                    handlePageChange={handlePageChange}
                    page={page}
                    pageCount={pageCount}
                  />
                )}

              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
  // return <div className="page-content">Users</div>
}

export default Users;
