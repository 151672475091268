import React, { useEffect, useState } from 'react';
import {
  Row, Col, Button, Input,
} from 'reactstrap';
import PropTypes from 'prop-types';

function Pagination({ page, pageCount, handlePageChange }) {
  const [canNextPage, setCanNextPage] = useState(true);
  const [canPreviousPage, setCanPreviousPage] = useState(false);

  useEffect(() => {
    enableDisablePreviousPage(page);
  }, [page]);

  const enableDisablePreviousPage = (selectedPage) => {
    if (selectedPage <= 1) {
      setCanPreviousPage(false);
    } else {
      setCanPreviousPage(true);
    }

    if (selectedPage >= pageCount) {
      setCanNextPage(false);
    } else {
      setCanNextPage(true);
    }
  };

  const gotoPage = (selectedPage) => {
    handlePageChange(selectedPage);
    // enableDisablePreviousPage(selectedPage);
  };

  const onChangeInInput = (e) => {
    const selectedPage = +e.target.value;
    if (selectedPage >= 1 && selectedPage <= pageCount) {
      handlePageChange(selectedPage);
      // enableDisablePreviousPage(selectedPage);
    }
  };

  const nextPage = () => {
    handlePageChange(page + 1);
    // enableDisablePreviousPage(page + 1);
  };

  const previousPage = () => {
    handlePageChange(page - 1);
    // enableDisablePreviousPage(page - 1);
  };

  return (
    <Row className="justify-content-md-end justify-content-center align-items-center">
      <Col className="col-md-auto">
        <div className="d-flex gap-1">
          <Button
            className="pagination-button-size"
            color="primary"
            onClick={() => gotoPage(1)}
            disabled={!canPreviousPage}
          >
            {'<<'}
          </Button>
          <Button
            className="pagination-button-size"
            color="primary"
            onClick={previousPage}
            disabled={!canPreviousPage}
          >
            {'<'}
          </Button>
        </div>
      </Col>
      <Col className="col-md-auto d-none d-md-block">
        Page
        {' '}
        <strong>
          {page}
          {' '}
          of
          {' '}
          {pageCount}
        </strong>
      </Col>
      <Col className="col-md-auto">
        <Input
          className="pagination-button-size"
          type="number"
          min={1}
          style={{ width: 70 }}
          max={pageCount}
          defaultValue={page}
          value={page}
          onChange={onChangeInInput}
        />
      </Col>

      <Col className="col-md-auto">
        <div className="d-flex gap-1">
          <Button color="primary" className="pagination-button-size" onClick={nextPage} disabled={!canNextPage}>
            {'>'}
          </Button>
          <Button
            className="pagination-button-size"
            color="primary"
            onClick={() => gotoPage(pageCount)}
            disabled={!canNextPage}
          >
            {'>>'}
          </Button>
        </div>
      </Col>
    </Row>
  );
}

Pagination.propTypes = {
  page: PropTypes.number,
  pageCount: PropTypes.number,
  handlePageChange: PropTypes.func,
};

export default Pagination;
