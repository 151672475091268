import { request } from './request';
import { routes } from '../constants/constant.routes';

const { API_URL } = routes;

export default class CompanyService {
  static addCompany(companyObj) {
    return request('POST', `${API_URL}${routes.ADD_BANK_CONFIGURATION}`, null, companyObj, null);
  }

  static updateCompany(companyObj) {
    return request('PUT', `${API_URL}${routes.UPDATE_BANK_CONFIGURATION}`, null, companyObj, null);
  }

  static getCompanies(page = 1, limit = 10, search = null, active = '', bankConfigurationId = null) {
    let qString = '';
    if (page) {
      qString += `page=${(page).toString()}&`;
    }
    if (limit) {
      qString += `limit=${limit.toString()}&`;
    }
    if (search) {
      qString += `search=${search}&`;
    }
    if (active) {
      qString += `active=${active}&`;
    }
    if (bankConfigurationId) {
      qString += `id=${bankConfigurationId}&`;
    }
    return request('GET', `${API_URL}${routes.GET_BANK_CONFIGURATIONS}?${qString}`, null, null, null);
  }

  static getCompaniesDropdown(page = 1, limit = 10, search = null, active = '', bankConfigurationId = null) {
    let qString = '';
    if (page) {
      qString += `page=${(page).toString()}&`;
    }
    if (limit) {
      qString += `limit=${limit.toString()}&`;
    }
    if (search) {
      qString += `search=${search}&`;
    }
    if (active) {
      qString += `active=${active}&`;
    }
    if (bankConfigurationId) {
      qString += `id=${bankConfigurationId}&`;
    }
    return request('GET', `${API_URL}${routes.GET_BANK_CONFIGURATIONS_DROPDOWN}?${qString}`, null, null, null);
  }
}
