/* eslint  import/no-extraneous-dependencies: "off" */

import React, { useEffect, useState, useCallback } from 'react';
import {
  Row, Col, Card, CardBody, CardTitle, Input, Button, DropdownToggle, DropdownMenu, Badge,
  DropdownItem, Dropdown, Label, InputGroup
} from 'reactstrap';
import {
  Table, Thead, Tbody, Tr, Th, Td,
} from 'react-super-responsive-table';
// import Switch from 'react-switch';
import _debounce from 'lodash/debounce';
import Select from 'react-select';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_blue.css';

import { useSelector } from 'react-redux';
import BbaSpinner from '../../components/Common/BbaSpinner';
import Pagination from '../../components/Common/Pagination';

import FileService from '../../services/FileService';

import ConfirmationModal from '../../modal/ConfirmationModal';
import AddNewFileModal from '../../modal/AddNewFile';
import ErrorModal from '../../modal/ErrorModal';
import TSRVRHandover from '../../modal/TSRVRHandover';
import FileDetail from '../../modal/FileDetail';
import FileHistory from '../../modal/FileHistory';
import AddQuery from '../../modal/AddQuery';
import UploadTSRVRDocument from '../../modal/UploadTSRVRDocument';
import Outsource from '../../modal/Outsource';
import ResendEmail from '../../modal/ResendEmail';

import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
// import StorageService from '../../services/StorageService';
import {
  downlodFile, extractErrorAndShowToast, formatDate, getCurrentFinancialYear, getFinancialYears
} from '../../utils';
import BankService from '../../services/BankService';
import permissionConstant from '../../constants/permission.constant';
// import StorageService from '../../services/StorageService';

const {
  ADD_FILE,
  UPDATE_FILE,
  DELETE_FILE,
  SEND_OUTSOURCE,
  TSR_VR_HANDOVER,
  TSR_VR_UPLOAD,
  ADD_QUERY,
} = permissionConstant;

const control = {
  value: '',
  valid: true
};

const statusMappingObj = {
  login: 'Login',
  tsrpdf: 'TSR Pdf',
  vrpdf: 'VR Pdf',
  modify: 'Modify',
  queries: 'Queries',
  outsourced: 'Outsourced',
  tsrhandover: 'TSR Handover',
  vrhandover: 'VR Handover',
  tsrupload: 'TSR Upload',
  vrupload: 'VR Upload',
  closed: 'Closed'
};
// const userDetail = StorageService.getUserDetail();
function File() {
  const userPermissions = useSelector((state) => state.permission.userPermissions);
  const [files, setFiles] = useState([]);
  const [banks, setBanks] = useState([]);
  const [loginFileTypes, setLoginFileTypes] = useState([]);
  const [loginFileType, setLoginFileType] = useState(null);
  const [bank, setBank] = useState(null);
  const [status, setStatus] = useState(null);
  const [fromDate, setFromDate] = useState({ ...control });
  const [toDate, setToDate] = useState({ ...control });
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [year, setYear] = useState(getCurrentFinancialYear());
  const [search, setSearch] = useState('');
  const [pageCount, setPageCount] = useState(10);
  const [advanceFilterOpen, setAdvanceFilterOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFileModalOpen, setIsFileModalOpen] = useState(false);
  const [isTSRHandoverModalOpen, setIsTSRHandoverModalOpen] = useState(false);
  const [isAddQueryModalOpen, setIsAddQueryModalOpen] = useState(false);
  const [isTsrVrDocumentUploadModalOpen, setIsTsrVrDocumentUploadModalOpen] = useState(false);
  const [isFileDetailModalOpen, setIsFileDetailModalOpen] = useState(false);
  const [isFileHistoryModalOpen, setIsFileHistoryModalOpen] = useState(false);
  const [isOutsourceModalOpen, setIsOutsourceModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorModalDetail, setErrorModalDetail] = useState(null);
  const [fileToUpdate, setFileToUpdate] = useState(null);

  const [emailDetails, setEmailDetails] = useState(null);
  const [isResendMailModalOpen, setIsResendMailModalOpen] = useState(false);

  const { REACT_APP_API_URL } = process.env;

  // const userDetail = StorageService.getUserDetail();
  // const { permissions = {} } = userDetail;
  const permissions = userPermissions ? userPermissions?.permissions : {};

  useEffect(() => {
    getFiles();
    console.log(process.env.REACT_APP_API_URL);
    // @NOTE: added to remove validation error
    setLimit(20);
    getBanks();
    getLoginFileTypes();
    // getRoles();
  }, []);

  useEffect(() => {
    getFiles();
  }, [page, limit]);

  useEffect(() => {
    setPage(1);
    if (page === 1) {
      getFiles();
    }
  }, [year]);

  useEffect(() => {
    console.log('search', search);
    const filters = prepareFilters();
    getFileDebounceFunction(search, null, null, filters);
  }, [search]);

  const prepareFilters = () => {
    let endDate = null;
    if (toDate.value) {
      endDate = new Date(toDate.value);
      endDate.setHours(23);
      endDate.setMinutes(59);
    }
    const filters = {
      bankId: (bank && bank.value) || null,
      loginFileTypeId: loginFileType || null,
      fromDate: fromDate.value || null,
      toDate: endDate || null,
      year,
      status,
    };
    return filters;
  };

  const financialYears = () => {
    const fyYears = getFinancialYears();
    return fyYears.map((y) => <option value={y.value}>{y.label}</option>);
  };

  const getFiles = async (searchKeyword, download, resetFilter, filtersArg) => {
    try {
      let filters = prepareFilters();
      if (filtersArg) {
        filters = filtersArg;
      }
      let pageToBeFetch = page;
      if (resetFilter) {
        filters = {};
        setPage(1);
        pageToBeFetch = 1;
      }
      const fileResponse = await FileService.getFiles(pageToBeFetch, limit, searchKeyword || search, null, filters, download);
      if (download === 'excel') {
        console.log('downlod excel file');
        downlodFile(`${REACT_APP_API_URL}/${fileResponse.data}`);
      } else {
        const fileResponseData = fileResponse.data;
        console.log('fileResponseData', fileResponseData);
        setFiles(fileResponseData.data);
        // setFiles(filesDummyData);
        setPageCount(fileResponseData.totalPages);
      }
      setIsLoading(false);
    } catch (e) {
      console.log('e', e);
      extractErrorAndShowToast(e);
      // setFiles(filesDummyData);
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    }
  };

  const getBanks = async () => {
    try {
      const rolesResponse = await BankService.getAdminBanks(1, 10000);
      const responseBanks = rolesResponse.data.data;
      const bankDropdown = [{
        label: 'Select Bank',
        value: null
      }];
      if (responseBanks && responseBanks.length > 0) {
        for (const responseBank of responseBanks) {
          const dropdownObj = {
            label: responseBank.name,
            value: responseBank.id
          };
          bankDropdown.push(dropdownObj);
        }
      }
      setBanks(bankDropdown);
    } catch (e) {
      extractErrorAndShowToast(e);
    }
  };

  const getLoginFileTypes = async () => {
    try {
      const loginFileTypeResponse = await FileService.getLoginFileTypes();
      const loginFileTypeResponseData = loginFileTypeResponse.data;
      const { data } = loginFileTypeResponseData;
      console.log('loginFileTypeResponseData', loginFileTypeResponseData);
      // setUsers(userResponseData.users);
      setLoginFileTypes(data);
      setIsLoading(false);
    } catch (e) {
      extractErrorAndShowToast(e);
      // console.log('e', e);
      // const errorObj = {
      //   title: 'Citys',
      //   message: 'Something went wrong!!',
      // };
      // setErrorModalDetail(errorObj);
      // setIsErrorModalOpen(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    }
  };
  const getFileDebounceFunction = useCallback(_debounce(getFiles, 1000), []);

  const updateFile = async (updateObj) => {
    try {
      await FileService.updateFile(updateObj);
    } catch (e) {
      console.log('update failed');
      // const errorObj = {
      //   title: 'File',
      //   message: 'Something went wrong!!',
      // };
      // setErrorModalDetail(errorObj);
      // setIsErrorModalOpen(true);
      extractErrorAndShowToast(e);
    }
  };

  // const resendFileEmail = async (file) => {
  //   try {
  //     const obj = {
  //       loginFileId: file.uuid,
  //       action: 'login'
  //     };
  //     await FileService.resendEmail(obj);
  //     showSuccessToast('Mail sent');
  //   } catch (e) {
  //     console.log('resend mail failed');
  //     extractErrorAndShowToast(e);
  //   }
  // };

  const openResendMailModalOpen = (file) => {
    setIsResendMailModalOpen(true);
    setFileToUpdate(file);
    setEmailDetails({
      action: 'login'
    });
  };

  const closeResendMailModalOpen = () => {
    setIsResendMailModalOpen(false);
    setEmailDetails(null);
    setFileToUpdate(null);
  };

  // const activeOrInactiveFile = async (fileId, value) => {
  //   const updateObj = {
  //     fileId,
  //     is_active: value,
  //   };
  //   await updateFile(updateObj);

  //   // @NOTE: comment once api is active
  //   const file = files.find((f) => f.uuid === fileId);
  //   file.is_active = value;

  //   setFiles([...files]);
  //   // call update user api
  // };

  const openDeleteFileModal = (file) => {
    setFileToUpdate(file);
    setIsModalOpen(true);
  };

  const editFile = (file) => {
    setIsFileModalOpen(true);
    setFileToUpdate(file);
  };

  const deleteFile = async () => {
    const updateObj = {
      id: fileToUpdate.uuid,
      isDeleted: true,
    };
    await updateFile(updateObj);
    getFiles();
    handleCloseConfirmationModal();
  };

  const handlePageChange = (selectedPage) => {
    setPage(selectedPage);
  };

  const handleCloseConfirmationModal = () => {
    setIsModalOpen(false);
    setFileToUpdate(null);
  };

  const handleCloseErrorModal = () => {
    setIsErrorModalOpen(false);
    setErrorModalDetail(null);
  };

  const handleCloseFileModal = (reload) => {
    setIsFileModalOpen(false);
    setFileToUpdate(null);
    if (reload) {
      getFiles();
    }
  };
  const openAddFileModal = () => {
    setIsFileModalOpen(true);
  };

  const openTSRHandoverModal = (file) => {
    setFileToUpdate(file);
    setIsTSRHandoverModalOpen(true);
  };

  const handleCloseTSRHandoverModal = (reload) => {
    setIsTSRHandoverModalOpen(false);
    setFileToUpdate(null);
    if (reload) {
      getFiles();
    }
  };

  const openOutsourceModal = (file) => {
    setFileToUpdate(file);
    setIsOutsourceModalOpen(true);
  };

  const handleCloseOutsourcerModal = (reload) => {
    setIsOutsourceModalOpen(false);
    setFileToUpdate(null);
    if (reload) {
      getFiles();
    }
  };

  const handleCloseAddQueryModal = (reload) => {
    setIsAddQueryModalOpen(false);
    setFileToUpdate(null);
    if (reload) {
      getFiles();
    }
  };

  const openAddQueryModal = (file) => {
    setFileToUpdate(file);
    setIsAddQueryModalOpen(true);
  };

  const handleCloseTsrVrDocumentUploadModal = (reload) => {
    setIsTsrVrDocumentUploadModalOpen(false);
    setFileToUpdate(null);
    if (reload) {
      getFiles();
    }
  };

  const openTsrVrDocumentUploadModal = (file) => {
    setFileToUpdate(file);
    setIsTsrVrDocumentUploadModalOpen(true);
  };

  const closeFileHistoryModal = () => {
    setFileToUpdate(null);
    setIsFileHistoryModalOpen(false);
  };
  // const openFileHistoryModal = () => {
  //   setIsFileHistoryModalOpen(true);
  // };

  const handleCloseFileDetailModal = () => {
    setFileToUpdate(null);
    setIsFileDetailModalOpen(false);
  };
  const openFileDetailModal = (file) => {
    setFileToUpdate(file);
    setIsFileDetailModalOpen(true);
  };

  const openActionMenu = (index) => {
    files[index].isActionMenuOpen = !files[index].isActionMenuOpen;
    setFiles([...files]);
  };

  const abbreviateName = (bankName) => {
    if (bankName) {
      return bankName.split(' ').map((b) => b[0].toUpperCase()).join('');
    }
    return '-';
  };

  const handleFromDate = (e) => {
    console.log('e', e);
    const date = e[0];
    if (date) {
      setFromDate({ ...control, value: new Date(date).toISOString() });
    }
  };

  const handleToDate = (e) => {
    console.log('e', e);
    const date = e[0];
    if (date) {
      setToDate({ ...control, value: new Date(date).toISOString() });
    }
  };

  const generateStatusBadge = (fileStatus) => {
    const badgeClassMapping = {
      login: 'bg-info',
      tsrpdf: 'bg-dark',
      vrpdf: 'bg-dark',
      modify: 'bg-info',
      queries: 'bg-danger',
      outsourced: 'bg-success',
      tsrhandover: 'bg-success',
      vrhandover: 'bg-success',
      tsrupload: 'bg-warning',
      vrupload: 'bg-warning',
      closed: 'bg-success'
    };

    const badgeClass = badgeClassMapping[fileStatus] || 'bg-info';

    return <Badge className={badgeClass}>{statusMappingObj[fileStatus] || fileStatus}</Badge>;
  };

  const resetFilter = () => {
    setBank(null);
    setFromDate({ ...control });
    setToDate({ ...control });
    setLoginFileType('');
    setStatus('');
    getFiles(null, null, true);
  };

  return (
    <div className="page-content">
      <div className="container-fluid">
        {/* <Breadcrumbs title="Tables" breadcrumbItem="Responsive Table" /> */}

        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                {isLoading && <BbaSpinner />}
                <CardTitle className="h4">Files </CardTitle>
                <Row>
                  <Col sm={10} md={5} lg={5}>
                    <div className="pb-3">
                      <div className="search-box chat-search-box">
                        <div className="position-relative">
                          <Input
                            type="text"
                            className="form-control bg-light border-light rounded "
                            placeholder="Search by File No, Bank Ref., Customer, Description"
                            onChange={(e) => setSearch(e.target.value)}
                          />
                          <i className="uil uil-search search-icon" />
                        </div>
                      </div>
                    </div>
                  </Col>
                  {/* <Col sm={6} md={2} lg={2}>
                    <div className="pb-3">
                      <div className="position-relative">
                        <Input
                          type="select"
                          className="pagination-button-size"
                          onChange={(e) => setRole(e.target.value)}
                        >
                          <option value="">Role</option>
                          {roles.map((r) => <option value={r.uuid}>{r.name}</option>)}
                        </Input>
                      </div>
                    </div>
                  </Col> */}
                  <Col sm={6} md={2} lg={2}>
                    <div className="pb-3">

                      <span className="position-relative">
                        <Input
                          type="select"
                          className="pagination-button-size"
                          value={year}
                          onChange={(e) => setYear(e.target.value)}
                        >
                          {financialYears()}
                        </Input>
                      </span>

                    </div>
                  </Col>
                  <Col md={5} lg={3}>
                    <span onClick={() => getFiles(null, 'excel')} role="button" tabIndex="-1" className="text-success" title="Download">
                      <i className="uil uil-file-download font-size-20" />
                    </span>
                    &nbsp;&nbsp;
                    <span onClick={() => setAdvanceFilterOpen(!advanceFilterOpen)} role="button" tabIndex="-1" className="text-warning" title="Advance Filter">
                      {!advanceFilterOpen && <i className="uil uil-filter font-size-20" />}
                      {advanceFilterOpen && <i className="uil uil-filter-slash font-size-20" />}
                    </span>
                    {/* <span onClick={() => openFileDetailModal()} role="button" tabIndex="-1" className="text-success" title="File Details">
                      <i className="uil uil-eye font-size-20" />
                    </span> */}
                  </Col>
                  <Col sm={2} md={2} lg={2}>
                    {permissions[ADD_FILE] && (
                      <div className="pb-3 text-sm-end">
                        <Button
                          type="button"
                          color="success"
                          className="waves-effect waves-light mb-3 btn btn-success"
                          onClick={openAddFileModal}
                        >
                          <i className="mdi mdi-plus me-1" />
                          Add File
                        </Button>
                      </div>
                    )}
                  </Col>
                </Row>
                {advanceFilterOpen && (
                  <Row>
                    <Col>
                      <div className="mb-3">
                        <Label className="form-label">
                          Bank Name
                          <span className="text-danger">&nbsp;*</span>
                        </Label>
                        <Select
                          value={bank}
                          onChange={(e) => {
                            console.log('e', e);
                            setBank(e);
                          }}
                          options={banks}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col>
                      <div className="form-group mb-4">
                        <Label>From Date</Label>
                        <InputGroup>
                          <Flatpickr
                            className="form-control d-block"
                            placeholder="dd M,yyyy"
                            options={{
                              altInput: true,
                              altFormat: 'j F, Y',
                              dateFormat: 'Y-m-d',
                            }}
                            onChange={handleFromDate}
                            value={fromDate.value}
                          />
                        </InputGroup>
                      </div>
                    </Col>
                    <Col>
                      <div className="form-group mb-4">
                        <Label>To Date</Label>
                        <InputGroup>
                          <Flatpickr
                            className="form-control d-block"
                            placeholder="dd M,yyyy"
                            min={fromDate.value}
                            options={{
                              altInput: true,
                              altFormat: 'j F, Y',
                              dateFormat: 'Y-m-d',
                            }}
                            onChange={handleToDate}
                            value={toDate.value}
                          />
                        </InputGroup>
                      </div>
                    </Col>
                    <Col>
                      <div className="mb-3">
                        <Label className="form-label">Type</Label>
                        <Input
                          type="select"
                          value={loginFileType}
                          // className="form-control bg-light border-light rounded"
                          // placeholder="Search..."
                          onChange={(e) => setLoginFileType(e.target.value)}
                        >
                          <option value="">Select Type</option>
                          {loginFileTypes.map((l) => <option value={l.id}>{l.name}</option>)}
                        </Input>
                      </div>
                    </Col>
                    <Col>
                      <div className="mb-3">
                        <Label className="form-label">Status</Label>
                        <Input
                          type="select"
                          value={status}
                          // className="form-control bg-light border-light rounded"
                          // placeholder="Search..."
                          onChange={(e) => setStatus(e.target.value)}
                        >
                          <option value="">Select Status</option>
                          {Object.keys(statusMappingObj).map((s) => <option value={s}>{statusMappingObj[s]}</option>)}
                        </Input>
                      </div>
                    </Col>
                    <Col>
                      <Label className="form-label" />

                      <div className="pb-3 mt-1 text-sm-end">
                        {/* <Button
                        type="button"
                        color="success"
                        className="waves-effect waves-light btn btn-warning"
                        onClick={() => getFiles(null, 'excel')}
                      >
                        Excel
                      </Button> */}
                        <Button
                          type="button"
                          color="primary"
                          className="waves-effect waves-light btn btn-primary"
                          onClick={() => {
                            if (page !== 1) {
                              setPage(1);
                            } else {
                              getFiles();
                            }
                          }}
                        >
                          Apply
                        </Button>
                        &nbsp;
                        <Button
                          type="button"
                          color="success"
                          className="waves-effect waves-light btn btn-warning"
                          onClick={() => resetFilter()}
                        >
                          Reset
                        </Button>
                      </div>
                    </Col>
                  </Row>
                )}

                <div className="table-rep-plugin">
                  <div
                    className="table-responsive mb-0"
                    data-pattern="priority-columns"
                  >
                    <Table
                      // id="tech-companies-1"
                      className="table table-striped"
                    >
                      <Thead>
                        <Tr>
                          <Th style={{ width: '83px' }}>File No</Th>
                          <Th style={{ width: '115px' }}>Date</Th>
                          <Th style={{ width: '80px' }}>Bank</Th>
                          <Th style={{ width: '154px' }}>Bank Ref No</Th>
                          <Th style={{ width: '150px' }}>Customer</Th>
                          <Th>Description</Th>
                          {/* <Th>File Type</Th> */}
                          {/* <Th>City</Th> */}
                          <Th style={{ width: '120px' }}>Status</Th>
                          <Th style={{ width: '150px' }}>Actions</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {files.map((file, index) => (
                          <>
                            {file && (
                              <Tr>
                                <>
                                  <Td>
                                    <div>{`${file.file_no}`}</div>
                                    <Badge className="bg-info">
                                      {file.login_file_type}
                                    </Badge>
                                  </Td>
                                  <Td>{formatDate(file.created_at)}</Td>
                                  <Td title={file.bank_name}>
                                    <div>{abbreviateName(file.bank_name)}</div>

                                  </Td>
                                  <Td>{(file.bank_reference_no || '-').toUpperCase()}</Td>
                                  <Td>
                                    <div>{file.customer_name || file.general_file_customer_name || '-'}</div>

                                  </Td>
                                  <Td>{file.property_description || '-'}</Td>
                                  {/* <Td>{file.login_file_type}</Td> */}
                                  {/* <Td>{file.city}</Td> */}
                                  <Td>
                                    {generateStatusBadge(file.status)}
                                    <div>
                                      {file.tsr_document_upload_date && (
                                        <Badge className="bg-success" title={`Tsr uploaded on ${formatDate(file.tsr_document_upload_date)}`}>
                                          TSR
                                          <i className="uil-cloud-upload bba-icon font-size-14" />
                                        </Badge>
                                      )}

                                      {file.vr_document_upload_date
                                        && (
                                          <>
                                            &nbsp;
                                            <Badge className="bg-success" title={`Vr uploaded on ${formatDate(file.vr_document_upload_date)}`}>
                                              VR
                                              <i className="uil-cloud-upload bba-icon font-size-14" />
                                            </Badge>
                                          </>
                                        )}
                                    </div>
                                  </Td>
                                </>
                                {/* {file.isActionMenuOpen && (
                                  <Td colSpan="7" className="menu-show-transition`">
                                    <span>File Detail</span>
                                  </Td>
                                )} */}
                                <Td>
                                  <span onClick={() => openFileDetailModal(file)} role="button" tabIndex="-1" className="text-success" title="File Details">
                                    <i className="uil uil-eye font-size-20" />
                                  </span>
                                  &nbsp;&nbsp;
                                  {permissions[UPDATE_FILE] && (
                                    <>
                                      <span onClick={() => editFile(file)} role="link" tabIndex="-1" className="text-primary">
                                        <i className="uil uil-pen font-size-20" />
                                      </span>
                                      &nbsp;&nbsp;
                                    </>
                                  )}

                                  {permissions[DELETE_FILE] && (
                                    <>
                                      <span onClick={() => openDeleteFileModal(file)} role="button" tabIndex="-1" className="text-danger">
                                        <i className="uil-trash-alt bba-icon font-size-20" />
                                      </span>
                                      &nbsp;
                                    </>
                                  )}
                                  {/* <span role="button" tabIndex="-1" onClick={() => openActionMenu(index)}>
                                  <i className="uil uil-ellipsis-v" />
                                </span> */}
                                  <Dropdown
                                    isOpen={file.isActionMenuOpen}
                                    toggle={() => {
                                      openActionMenu(index);
                                    }}
                                    className="btn-group me-2 mb-1"
                                  >
                                    <DropdownToggle
                                      className="waves-light waves-effect dropdown-toggle"
                                      tag="div"
                                    >
                                      <i className="mdi mdi-dots-vertical font-size-20" />
                                    </DropdownToggle>
                                    <DropdownMenu>
                                      {permissions[ADD_QUERY] && <DropdownItem to="#" role="grid" onClick={() => openAddQueryModal(file)}>Add Query</DropdownItem>}
                                      {file.login_file_type === 'TSR' && (
                                        <>
                                          {permissions[TSR_VR_UPLOAD] && <DropdownItem to="#" role="cell" onClick={() => openTsrVrDocumentUploadModal(file)}>TSR/VR Upload</DropdownItem>}
                                          {/* <DropdownItem to="#" role="grid">TSR Email</DropdownItem> */}
                                          {/* <DropdownItem to="#" role="grid">Email TSR PDF</DropdownItem> */}
                                          {/* <DropdownItem to="#" role="grid">Email VR PDF</DropdownItem> */}
                                          {permissions[TSR_VR_HANDOVER] && <DropdownItem to="#" role="grid" onClick={() => openTSRHandoverModal(file)}>TSR/VR Handover</DropdownItem>}
                                          <DropdownItem to="#" role="grid" onClick={() => openResendMailModalOpen(file)}>Resend Login Email</DropdownItem>
                                        </>
                                      )}
                                      {permissions[SEND_OUTSOURCE] && <DropdownItem to="#" role="grid" onClick={() => openOutsourceModal(file)}>Send Outsource</DropdownItem>}
                                    </DropdownMenu>
                                  </Dropdown>
                                </Td>
                              </Tr>
                            )}
                            {/* {file.isActionMenuOpen && (
                              <Tr>
                                <Td>
                                  &nbsp;
                                </Td>
                              </Tr>
                            )} */}
                          </>
                        ))}
                        {
                          files.length === 0 && (
                            <Tr>
                              <Td colSpan="15" className="text-center">No File Found</Td>
                            </Tr>
                          )
                        }
                      </Tbody>
                    </Table>
                  </div>
                </div>
                {isModalOpen && (
                  <ConfirmationModal
                    isModalOpen={isModalOpen}
                    title="Delete File"
                    message="Are you sure you want to delete File?"
                    closeModal={handleCloseConfirmationModal}
                    handleConfirmation={() => deleteFile()}
                  />
                )}

                {isFileModalOpen && (
                  <AddNewFileModal
                    isModalOpen={isFileModalOpen}
                    file={fileToUpdate}
                    handleCloseModal={handleCloseFileModal}
                  />
                )}

                {isAddQueryModalOpen && (
                  <AddQuery
                    isModalOpen={isAddQueryModalOpen}
                    file={fileToUpdate}
                    closeModal={handleCloseAddQueryModal}
                  />
                )}

                {isTsrVrDocumentUploadModalOpen && (
                  <UploadTSRVRDocument
                    isModalOpen={isTsrVrDocumentUploadModalOpen}
                    file={fileToUpdate}
                    closeModal={handleCloseTsrVrDocumentUploadModal}
                  />
                )}

                {isTSRHandoverModalOpen && (
                  <TSRVRHandover
                    isModalOpen={isTSRHandoverModalOpen}
                    file={fileToUpdate}
                    closeModal={handleCloseTSRHandoverModal}
                  />
                )}

                {isOutsourceModalOpen && (
                  <Outsource
                    isModalOpen={isOutsourceModalOpen}
                    file={fileToUpdate}
                    closeModal={handleCloseOutsourcerModal}
                  />
                )}

                {isFileDetailModalOpen && (
                  <FileDetail
                    isModalOpen={isFileDetailModalOpen}
                    file={fileToUpdate}
                    closeModal={handleCloseFileDetailModal}
                  />
                )}

                {isFileHistoryModalOpen && (
                  <FileHistory
                    isModalOpen={isFileHistoryModalOpen}
                    // user={fileToUpdate}
                    closeModal={closeFileHistoryModal}
                  />
                )}

                {isResendMailModalOpen && (
                  <ResendEmail
                    file={fileToUpdate}
                    emailDetails={emailDetails}
                    isModalOpen={isResendMailModalOpen}
                    handleCloseModal={closeResendMailModalOpen}
                  />
                )}

                {isErrorModalOpen && (
                  <ErrorModal
                    isModalOpen={isErrorModalOpen}
                    {...errorModalDetail}
                    closeModal={handleCloseErrorModal}
                  />
                )}

                {pageCount > 1 && (
                  <Pagination
                    handlePageChange={handlePageChange}
                    page={page}
                    pageCount={pageCount}
                  />
                )}

              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
  // return <div className="page-content">Users</div>
}

export default File;
