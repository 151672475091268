/* eslint prefer-const: "off" */
/* eslint guard-for-in: "off" */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Col, FormGroup, Input, Label, Row
} from 'reactstrap';
import Select from 'react-select';
import {
  extractErrorAndShowToast
} from '../utils';
import BbaSpinner from './Common/BbaSpinner';
import BranchService from '../services/BranchService';
import UserBranchAccessService from '../services/UserBranchAccessService';
import AlertService from '../services/AlertService';

const control = {
  value: '',
  valid: true
};

const controlGroupObj = {
  city: { ...control },
  branches: { ...control }
};

function UserBranchAccess({
  user, handleCloseModal, prevStep, nextStep
}) {
  const [cities, setCities] = useState([]);
  const [isLoading, setIsLoading] = useState([]);
  const [controlGroups, setControlGroups] = useState([controlGroupObj]);
  const [isAllBranchAccess, setIsAllBranchAccess] = useState(false);
  const [deletedBranchAccess, setDeletedBranchAccess] = useState([]);
  // const [city, setCity] = useState({ ...control, value: '' });
  // const [branches, setBranches] = useState({ ...control, value: [] });

  useEffect(() => {
    getBranches();
    // getUserBranchAccess();
    setIsAllBranchAccess(user?.is_all_branch_access);
  }, []);

  useEffect(() => {
    getBranches();
    // getUserBranchAccess();
    setIsAllBranchAccess(user.is_all_branch_access);
  }, [user]);

  const getBranches = async (searchKeyword) => {
    try {
      const branchResponse = await BranchService.getBranchDropdown(1, 100, searchKeyword, true);
      const branchResponseData = branchResponse.data;
      const { data } = branchResponseData;
      console.log('branchResponseData', branchResponseData);
      cityBranchMapping(data);
      // getUserBranchAccess();
      // setPageCount(totalPages);
      setIsLoading(false);
    } catch (e) {
      console.log('e', e);
      extractErrorAndShowToast(e);
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    }
  };

  const cityBranchMapping = async (allBranches) => {
    const objCity = {};
    const citiesObj = {};
    const cityNameMapping = {};
    for (let branch of allBranches) {
      branch.label = branch.name;
      branch.value = branch.id;
      delete branch.uuid;
      if (objCity[branch.city_id]) {
        objCity[branch.city_id].push(branch);
      } else {
        cityNameMapping[branch.city_id] = branch.city_name;
        objCity[branch.city_id] = [branch];
      }
    }
    for (let key in objCity) {
      const cityObj = {
        city_id: key,
        city_name: cityNameMapping[key],
        branches: objCity[key]
      };
      citiesObj[key] = cityObj;
    }
    getUserBranchAccess();
    setCities({ ...citiesObj });
  };

  const addUserBranchAccess = async () => {
    const arrUserBranchAccess = [];
    for (let cg of controlGroups) {
      const city = cg?.city?.value;
      const branches = cg?.branches?.value;
      // extract branch id from braches
      // get branch value as array from branches
      let arrBranchIds = [];
      if (branches.length > 0) {
        for (let b of branches) {
          // arrBranchIds.push(b.value);
          if (city) {
            arrBranchIds.push({
              uuid: b?.uuid || null,
              cityId: city,
              branchId: b.value
            });
          }
        }
      } else {
        arrBranchIds = null;
      }
      if (city) {
        const userBranchAccessObj = {
          uuid: cg?.uuid || null,
          cityId: city,
          branches: arrBranchIds
        };
        arrUserBranchAccess.push(userBranchAccessObj);
      }
    }
    const userBranchAccessObj = {
      isAllBranchAccess,
      userId: user.uuid,
      userBranchAccess: arrUserBranchAccess,
      deletedBranchAccess
    };
    try {
      await UserBranchAccessService.addUserBranch(userBranchAccessObj);
      console.log('user branch access added successfully');
      AlertService.showToast('User branch access added successfully');
      nextStep(user);

      // handleCloseModal();
    } catch (e) {
      extractErrorAndShowToast(e);
    }
  };

  const getUserBranchAccess = async () => {
    const userId = user.uuid;
    const { data } = await UserBranchAccessService.getUserBranch(1, 1000, userId);
    const userBranchAccessRes = data?.data;
    // console.log('userBranchAccessRes', data?.data);
    if (userBranchAccessRes?.length > 0) {
      // generate new array grouping by city_id from above array
      const objUserBranchAccess = {};
      for (let userBranchAccess of userBranchAccessRes) {
        const userBranchAccessObj = {
          ...userBranchAccess,
          label: userBranchAccess.branch_name,
          value: userBranchAccess.branch_id,
          uuid: userBranchAccess.user_branch_access_id
        };
        if (objUserBranchAccess[userBranchAccess.city_id]) {
          objUserBranchAccess[userBranchAccess.city_id].push(userBranchAccessObj);
        } else {
          objUserBranchAccess[userBranchAccess.city_id] = [userBranchAccessObj];
        }
        // console.log('objUserBranchAccess', objUserBranchAccess);
      }
      const arrUserBranchAccess = [];
      for (let key in objUserBranchAccess) {
        const branches = [];
        for (let branch of objUserBranchAccess[key]) {
          if (branch.branch_id) {
            branches.push(branch);
          }
        }
        const cityAccessId = userBranchAccessRes.find((u) => u.city_id === key && !u.branch_id);
        if (cityAccessId) {
          const cityObj = {
            uuid: cityAccessId.user_branch_access_id,
            city: {
              uuid: cityAccessId.user_branch_access_id,
              ...control,
              value: key
            },
            branches: { ...control, value: branches }
          };
          arrUserBranchAccess.push(cityObj);
        }
      }
      // console.log('arrUserBranchAccess -->', arrUserBranchAccess);
      if (arrUserBranchAccess.length === 0) {
        arrUserBranchAccess.push(controlGroupObj);
      }
      setControlGroups([...arrUserBranchAccess]);
      setSelectedCity(arrUserBranchAccess);
    }
  };

  const setControlGroupsValues = (controlGroup, index) => {
    controlGroups[index] = { ...controlGroup };
    setControlGroups([...controlGroups]);
    setSelectedCity(controlGroups);
    // const cities = Object.values(cities);
    // mark all city selected as false
  };

  const setSelectedCity = (controlGroupsParameter) => {
    for (let c in cities) {
      cities[c].selected = false;
    }
    for (let cg of controlGroupsParameter) {
      // write logic to mark selected city which are part of control group
      if (cities) {
        const cityObj = cities[cg.city.value];
        // if (cityObj) {
        cityObj.selected = true;
        // }
      }
    }
    setCities({ ...cities });
  };

  const handleRemovedValues = (actionMeta) => {
    if (actionMeta?.removedValue?.uuid) {
      setDeletedBranchAccess([...deletedBranchAccess, actionMeta.removedValue.uuid]);
    }
    console.log('deletedBranchAccess', deletedBranchAccess, actionMeta, setDeletedBranchAccess);
  };

  const deleteUserBranchAccess = async (index) => {
    // maintain d
    // remove index from controlGroups obj
    const controlGroup = controlGroups[index];
    if (controlGroup.uuid) {
      deletedBranchAccess.push(controlGroup.uuid);
      setDeletedBranchAccess([...deletedBranchAccess]);
    }
    controlGroups.splice(index, 1);
    setControlGroups([...controlGroups]);
    setSelectedCity(controlGroups);
  };

  const addControlGroup = () => {
    if (controlGroups.length === Object.keys(cities).length) {
      return;
    }
    controlGroups.push({ ...controlGroupObj });
    setControlGroups([...controlGroups]);
  };
  return (
    <div>
      {isLoading && <BbaSpinner />}
      <FormGroup tag="fieldset">
        <FormGroup check>
          <Input
            name="all_branch_access"
            type="radio"
            checked={isAllBranchAccess}
            onClick={() => setIsAllBranchAccess(true)}
          />
          {' '}
          <Label check className="fw-normal">
            All Branch Access
          </Label>
        </FormGroup>
        <FormGroup check>
          <Input
            name="all_branch_access"
            type="radio"
            checked={!isAllBranchAccess}
            onClick={() => setIsAllBranchAccess(false)}
          />
          {' '}
          <Label check className="fw-normal">
            Set Branch Access
          </Label>
        </FormGroup>
      </FormGroup>
      {!isAllBranchAccess && (
        <>
          {controlGroups && controlGroups.map((controlGroup, index) => (
            (!controlGroup?.city?.value || cities[controlGroup?.city?.value]) && (
              <Row className="mt-10-px">
                <Col sm={6} md={4}>
                  <Label className="form-label">
                    City
                    <span className="text-danger">&nbsp;*</span>
                  </Label>
                  <Input
                    type="select"
                    value={controlGroup?.city?.value}
                    disabled={controlGroup?.city?.uuid}
                    onChange={(e) => {
                      console.log('e', e);
                      setControlGroupsValues({ ...controlGroup, city: { ...controlGroup.city, value: e.target.value } }, index);
                    }}
                  >
                    <option value="">Select city...</option>
                    {Object.values(cities).map((c) => <option value={c.city_id} disabled={c.selected}>{c.city_name}</option>)}
                  </Input>
                </Col>
                <Col sm={6} md={7}>
                  {controlGroup.city.value && (
                    <>
                      <Label className="form-label">
                        Branch
                      </Label>
                      <Select
                        defaultValue={controlGroup?.branches?.value}
                        isMulti
                        name="colors"
                        options={cities?.[controlGroup?.city?.value]?.branches}
                        value={controlGroup?.branches?.value}
                        onChange={(e, actionMeta) => { handleRemovedValues(actionMeta); setControlGroupsValues({ ...controlGroup, branches: { ...controlGroup.branches, value: e } }, index); }}
                        className="basic-multi-select"
                        classNamePrefix="select"
                      />
                    </>
                  )}

                </Col>
                <Col sm={6} md={1} style={{ marginTop: 34 }}>
                  <span role="link" className="cursor-pointer" onClick={() => deleteUserBranchAccess(index)} tabIndex={-1}>
                    <i className="uil uil-trash font-size-20" />
                  </span>
                </Col>
              </Row>
            )
          ))}
          {controlGroups.length !== Object.keys(cities).length && (
            <div className="text-align-right">
              <button type="submit" className="btn btn-primary mt-10-px" onClick={addControlGroup}>
                +
              </button>
            </div>
          )}
        </>
      )}

      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-light"
          onClick={() => {
            handleCloseModal();
          }}
        >
          Close
        </button>
        <button
          type="button"
          className="btn btn-light"
          onClick={() => {
            prevStep();
          }}
        >
          Back to User Info
        </button>
        <button type="submit" className="btn btn-primary" onClick={addUserBranchAccess}>
          Next
        </button>
      </div>

    </div>
  );
}

UserBranchAccess.propTypes = {
  user: PropTypes.object,
  // isModalOpen: PropTypes.bool,
  nextStep: PropTypes.func,
  prevStep: PropTypes.func,
  handleCloseModal: PropTypes.func
};

export default UserBranchAccess;
