import { request } from './request';
import { routes } from '../constants/constant.routes';

const { API_URL } = routes;

export default class AuthService {
  static signin(userObj) {
    const headers = {
      'grant-type': 'client_credentials'
    };
    return request('POST', `${API_URL}${routes.SIGNIN}`, null, userObj, headers);
  }
}
