/* eslint prefer-const: "off" */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Form, Label, Input, FormFeedback, Row, Col
} from 'reactstrap';

import UserService from '../services/UserService';
// import BranchService from '../services/BranchService';
import RoleService from '../services/RoleService';
import ValidationService from '../services/Validation';

import ErrorModal from '../modal/ErrorModal';

import {
  ADDRESS_MAX_LENGTH,
  EMAIL_MAX_LENGTH,
  FIRST_NAME_MAX_LENGTH,
  LAST_NAME_MAX_LENGTH,
  PHONE_NUMBER_MAX_LENGTH
} from '../constants/validation.constant';
import { extractErrorAndShowToast } from '../utils';
import AlertService from '../services/AlertService';

const control = {
  value: '',
  valid: true
};

function AddUser({
  user, nextStep, reloadList, handleCloseModal
}) {
  let [firstName, setFirstName] = useState({ ...control });
  let [lastName, setLastName] = useState({ ...control });
  let [address, setAddress] = useState({ ...control });
  let [phoneNumber, setPhoneNumber] = useState({ ...control });
  let [role, setRole] = useState({ ...control });
  // let [branch, setBranch] = useState({ ...control });
  // let [city, setCity] = useState({ ...control });
  let [email, setEmail] = useState({ ...control });
  let [userDetail, setUserDetail] = useState({ ...user });
  let [active, setActive] = useState(false);
  const [userId, setUserId] = useState(null);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorModalDetail, setErrorModalDetail] = useState(null);
  const [roles, setRoles] = useState([]);
  // const [branches, setBranches] = useState([]);
  // const [filteredBranches, setFilteredBranches] = useState([]);

  useEffect(() => {
    if (user) {
      console.log('user', user);
      setFirstName({ ...control, value: user.first_name });
      setLastName({ ...control, value: user.last_name });
      setAddress({ ...control, value: user.address });
      setPhoneNumber({ ...control, value: user.phone_number });
      setRole({ ...control, value: user.role_id });
      setEmail({ ...control, value: user.email });
      // setCity({ ...control, value: user.city_id });
      // setBranch({ ...control, value: user.branch_id });
      setActive(user.active);
      setUserId(user.uuid);
    }
    getRoles();
    // getBranches();
  }, []);

  // useEffect(() => {
  //   if (user && user.city_id) {
  //     getFilteredBranches(user.city_id);
  //   }
  // }, [branches]);

  const handleValidation = () => {
    firstName = ValidationService.isEmpty(firstName);
    firstName = ValidationService.lengthValidation(firstName, FIRST_NAME_MAX_LENGTH);
    if (firstName.isEmpty || firstName.isInvalidLength) {
      firstName.valid = false;
    } else {
      firstName.valid = true;
    }

    lastName = ValidationService.isEmpty(lastName);
    lastName = ValidationService.lengthValidation(lastName, LAST_NAME_MAX_LENGTH);
    if (lastName.isEmpty || lastName.isInvalidLength) {
      lastName.valid = false;
    } else {
      lastName.valid = true;
    }

    // address = ValidationService.isEmpty(address);
    // address = ValidationService.lengthValidation(address, ADDRESS_MAX_LENGTH);
    // if (address.isEmpty) {
    //   address.valid = false;
    // } else {
    //   address.valid = true;
    // }

    role = ValidationService.isEmpty(role);
    if (role.isEmpty) {
      role.valid = false;
    } else {
      role.valid = true;
    }

    // city = ValidationService.isEmpty(city);
    // if (city.isEmpty) {
    //   city.valid = false;
    // } else {
    //   city.valid = true;
    // }

    // branch = ValidationService.isEmpty(branch);
    // if (branch.isEmpty) {
    //   branch.valid = false;
    // } else {
    //   branch.valid = true;
    // }

    email = ValidationService.isEmpty(email);
    email = ValidationService.lengthValidation(email, EMAIL_MAX_LENGTH);
    email = ValidationService.isInvalidEmail(email);
    if (email.isEmpty || email.isInvalidLength || email.isInvalidEmail) {
      email.valid = false;
    } else {
      email.valid = true;
    }

    phoneNumber = ValidationService.isEmpty(phoneNumber);
    phoneNumber = ValidationService.lengthValidation(phoneNumber, PHONE_NUMBER_MAX_LENGTH);
    phoneNumber = ValidationService.validatePhoneNumber(phoneNumber);

    if (phoneNumber.isEmpty || phoneNumber.isInvalidLength || phoneNumber.invalidPhone === true) {
      phoneNumber.valid = false;
    } else {
      phoneNumber.valid = true;
    }

    setFirstName({ ...firstName });
    setLastName({ ...lastName });
    // setAddress({ ...address });
    setEmail({ ...email });
    setPhoneNumber({ ...phoneNumber });
    // setCity({ ...city });
    // setBranch({ ...branch });

    if (
      firstName.valid
      && lastName.valid
      // && address.valid
      && email.valid
      && phoneNumber.valid
      && role.valid
      // && city.valid
      // && branch.valid
    ) {
      return true;
    }
    return false;
  };

  const addUser = async () => {
    try {
      const isFormValid = handleValidation();
      if (!isFormValid) {
        return;
      }
      const userObj = {
        firstName: firstName.value,
        lastName: lastName.value,
        email: email.value.toLowerCase(),
        address: address.value,
        phoneNumber: phoneNumber.value,
        role: role.value,
        // cityId: city.value,
        // branchId: branch.value
      };
      console.log('userObj', userObj);
      let userDetailObj = userDetail;
      if (userId) {
        userObj.id = userId;
        userObj.isActive = active;
        await UserService.updateUser(userObj);
        AlertService.showToast('User updated successfully');
      } else {
        const addUserRes = await UserService.addUser(userObj);
        userDetailObj = addUserRes.data;
        setUserDetail(userDetailObj);
        AlertService.showToast('User added successfully');
      }
      nextStep(userDetailObj);
      reloadList();
      // handleCloseModal(true);
    } catch (e) {
      console.log('update failed');
      extractErrorAndShowToast(e);
      // const errorObj = {
      //   title: 'Users',
      //   message: 'Something went wrong!!',
      // };
      // setErrorModalDetail(errorObj);
      // setIsErrorModalOpen(true);
    }
  };

  const getRoles = async () => {
    try {
      const rolesResponse = await RoleService.getRoles();
      setRoles(rolesResponse.data.data);
      console.log('roles', roles);
    } catch (e) {
      extractErrorAndShowToast(e);
    }
  };

  // const getBranches = async () => {
  //   try {
  //     const branchesResponse = await BranchService.getBranches();
  //     setBranches(branchesResponse.data.data);
  //   } catch (e) {
  //     extractErrorAndShowToast(e);
  //   }
  // };

  const handleCloseErrorModal = () => {
    setIsErrorModalOpen(false);
    setErrorModalDetail(null);
  };

  // const handleCityChange = (e) => {
  //   const cityId = e.target.value;
  //   setCity({ ...city, value: cityId });
  //   const branchesForCity = getFilteredBranches(cityId);
  //   if (branchesForCity && branchesForCity.length > 0) {
  //     setBranch({ ...branch, value: branchesForCity[0].id });
  //   } else {
  //     setBranch({ ...branch, value: '' });
  //   }
  // };

  // const getFilteredBranches = (cityId) => {
  //   const branchesForCity = branches.filter((b) => +b.city_id === +cityId);
  //   setFilteredBranches(branchesForCity);
  //   return branchesForCity;
  // };

  return (
    <>
      <Form
        className="form-horizontal"
        onSubmit={(e) => {
          e.preventDefault();
          // validation.handleSubmit();

          return false;
        }}
      >
        {/* {error ? <Alert color="danger">{error}</Alert> : null} */}
        <Row>
          <Col>
            <div className="mb-3">
              <Label className="form-label">
                First Name
                <span className="text-danger">&nbsp;*</span>
              </Label>
              <Input
                name="firstName"
                className="form-control"
                placeholder="Enter First Name"
                type="text"
                onChange={(e) => setFirstName({ ...firstName, value: e.target.value })}
                value={firstName.value || ''}
                invalid={
                  !(firstName.valid)
                }
              />
              {firstName.isEmpty ? (
                <FormFeedback type="invalid">First name should not be empty</FormFeedback>
              ) : null}
              {firstName.isInvalidLength ? (
                <FormFeedback type="invalid">
                  First name should be more then
                  {FIRST_NAME_MAX_LENGTH}
                  {' '}
                  characters
                </FormFeedback>
              ) : null}
            </div>
          </Col>
          <Col>
            <div className="mb-3">
              <Label className="form-label">
                Last Name
                <span className="text-danger">&nbsp;*</span>
              </Label>
              <Input
                name="lastName"
                className="form-control"
                placeholder="Enter Last Name"
                type="text"
                value={lastName.value || ''}
                onChange={(e) => setLastName({ ...lastName, value: e.target.value })}
                invalid={
                  !(lastName.valid)
                }
              />
              {lastName.isEmpty ? (
                <FormFeedback type="invalid">Last name should not be empty</FormFeedback>
              ) : null}
              {lastName.isInvalidLength ? (
                <FormFeedback type="invalid">
                  Last name should be more then
                  {LAST_NAME_MAX_LENGTH}
                  {' '}
                  characters
                </FormFeedback>
              ) : null}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="mb-3">
              <Label className="form-label">
                Email
                <span className="text-danger">&nbsp;*</span>
              </Label>
              <Input
                name="email"
                className="form-control"
                placeholder="Enter email"
                type="email"
                disabled={!!user.uuid}
                onChange={(e) => setEmail({ ...email, value: e.target.value })}
                value={email.value || ''}
                invalid={
                  !email.valid
                }
              />
              {email.isEmpty ? (
                <FormFeedback type="invalid">Email should not be empty</FormFeedback>
              ) : null}
              {email.isInvalidEmail ? (
                <FormFeedback type="invalid">Please enter valid email</FormFeedback>
              ) : null}
              {email.isInvalidLength ? (
                <FormFeedback type="invalid">
                  Email should be more then
                  {EMAIL_MAX_LENGTH}
                  {' '}
                  characters
                </FormFeedback>
              ) : null}
              {/* {validation.touched.email && validation.errors.email ? (
                <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
              ) : null} */}
            </div>
          </Col>
          <Col>
            <div className="mb-3">
              <Label className="form-label">
                Phone Number
                <span className="text-danger">&nbsp;*</span>
              </Label>
              <Input
                name="phoneNumber"
                className="form-control"
                placeholder="Enter Phone Number"
                type="tel"
                maxLength={PHONE_NUMBER_MAX_LENGTH.toString()}
                onChange={(e) => setPhoneNumber({ ...address, value: e.target.value })}
                value={phoneNumber.value || ''}
                invalid={
                  !(phoneNumber.valid)
                }
              />
              {phoneNumber.isEmpty ? (
                <FormFeedback type="invalid">Phone number should not be empty</FormFeedback>
              ) : null}
              {phoneNumber.invalidPhone ? (
                <FormFeedback type="invalid">Phone number is invalid</FormFeedback>
              ) : null}
              {phoneNumber.isInvalidLength ? (
                <FormFeedback type="invalid">
                  Phone number should not be more then
                  {PHONE_NUMBER_MAX_LENGTH}
                  {' '}
                  characters
                </FormFeedback>
              ) : null}
              {/* {validation.touched.phone_number && validation.errors.phone_number ? (
                <FormFeedback type="invalid">{validation.errors.phone_number}</FormFeedback>
              ) : null} */}
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6} sm={12}>
            <div className="mb-3">
              <Label className="form-label">
                Role
                <span className="text-danger">&nbsp;*</span>
              </Label>
              <Input
                type="select"
                name="role"
                className="form-select"
                id="inlineFormSelectPref"
                onChange={(e) => setRole({ ...role, value: e.target.value })}
                value={role.value || ''}
                invalid={
                  !(role.valid)
                }
              >
                <option selected value="">Select role...</option>
                {roles && roles.map((r) => <option value={r.id}>{r.name}</option>)}
              </Input>

              {role.isEmpty ? (
                <FormFeedback type="invalid">Role should not be empty</FormFeedback>
              ) : null}

              {/* {validation.touched.role && validation.errors.role ? (
                <FormFeedback type="invalid">{validation.errors.role}</FormFeedback>
              ) : null} */}
            </div>
          </Col>
        </Row>

        {/* <Row>
          <Col>
            <div className="mb-3">
              <Label className="form-label">
                City
                <span className="text-danger">&nbsp;*</span>
              </Label>
              <Input
                type="select"
                name="city"
                className="form-select"
                id="inlineFormSelectPref"
                onChange={handleCityChange}
                value={city.value || ''}
              >
                <option selected value="">Select city...</option>
                {branches && branches.map((b) => <option value={b.city_id}>{b.city_name}</option>)}
              </Input>

              {city.isEmpty ? (
                <FormFeedback type="invalid">Please select city</FormFeedback>
              ) : null}
            </div>
          </Col>
          <Col>
            <div className="mb-3">
              <Label className="form-label">
                Branch
                <span className="text-danger">&nbsp;*</span>
              </Label>
              <Input
                type="select"
                name="branch"
                className="form-select"
                id="inlineFormSelectPref"
                onChange={(e) => setBranch({ ...branch, value: e.target.value })}
                value={branch.value || ''}
                invalid={
                  !(branch.valid)
                }
              >
                <option selected value="">Select branch...</option>
                {filteredBranches && filteredBranches.map((b) => (
                  <option value={b.id}>
                    {b.address}
                  </option>
                ))}
              </Input>

              {branch.isEmpty ? (
                <FormFeedback type="invalid">Please select branch</FormFeedback>
              ) : null}
            </div>
          </Col>
        </Row> */}
        <Row>
          <Col>
            <div className="mb-3">
              <Label className="form-label">Address</Label>
              <Input
                name="address"
                className="form-control"
                placeholder="Enter Address"
                type="textarea"
                onChange={(e) => setAddress({ ...address, value: e.target.value })}
                value={address.value || ''}
                invalid={
                  !(address.valid)
                }
              />
              {address.isEmpty ? (
                <FormFeedback type="invalid">Address should not be empty</FormFeedback>
              ) : null}
              {address.isInvalidLength ? (
                <FormFeedback type="invalid">
                  Last name should be more then
                  {ADDRESS_MAX_LENGTH}
                  {' '}
                  characters
                </FormFeedback>
              ) : null}
            </div>
          </Col>
        </Row>
        {userId && (
          <Row>
            <Col md={2} sm={3}>
              <Label className="form-label mt-2-px">Active</Label>
              <span className="form-check form-switch form-switch-md switch-inline">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="customSwitchsizemd"
                  defaultChecked
                  checked={!!active}
                  onClick={() => {
                    setActive(!active);
                  }}
                />
              </span>
            </Col>
          </Row>
        )}
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-light"
            onClick={() => {
              handleCloseModal();
            }}
          >
            Close
          </button>
          <button type="submit" className="btn btn-primary" onClick={addUser}>
            Next
          </button>
        </div>

      </Form>
      {isErrorModalOpen && (
        <ErrorModal
          isModalOpen={isErrorModalOpen}
          {...errorModalDetail}
          closeModal={handleCloseErrorModal}
        />
      )}
    </>
  );
}

AddUser.propTypes = {
  user: PropTypes.object,
  // isModalOpen: PropTypes.bool,
  reloadList: PropTypes.func,
  nextStep: PropTypes.func,
  handleCloseModal: PropTypes.func
};

export default AddUser;
