import React, { useEffect } from 'react';
import {
  Container, Row, Col
} from 'reactstrap';

// Import Breadcrumb
import ChangePasswordComponent from '../../components/ChangePassword';

// import images
import logo from '../../assets/images/logo-dark.png';
import logolight from '../../assets/images/logo-light.png';

function ResetPassword() {
  useEffect(() => {
    document.body.className = 'authentication-bg';
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = '';
    };
  });

  return (
    <div className="account-pages my-5  pt-sm-5">
      <Container>
        <Row className="justify-content-center">
          <Col md={8} lg={6} xl={5}>
            <div>
              <a href="/" className="mb-5 d-block auth-logo">
                <img src={logo} alt="" height="40" className="logo logo-dark" />
                <img src={logolight} alt="" height="40" className="logo logo-light" />
              </a>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md={8} lg={6} xl={5}>
            {/* <div>
              <div className="text-center">
                <a href="/" className="mb-5 d-block auth-logo">
                  <img src={logo} alt="" height="22" className="logo logo-dark" />
                  <img src={logolight} alt="" height="22" className="logo logo-light" />
                </a>
              </div> */}
            <ChangePasswordComponent forceReset />
            <div className="mt-5 text-center">
              <p>
                ©
                {' '}
                {new Date().getFullYear()}
                {' '}
                B&B Associates
              </p>
            </div>
            {/* </div> */}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ResetPassword;
