import React from 'react';
import {
  Container,
} from 'reactstrap';

// Import Breadcrumb
import Breadcrumb from '../../components/Common/Breadcrumb';
import ChangePasswordComponent from '../../components/ChangePassword';

function ChangePassword() {
  return (
    <div className="page-content">
      <Container fluid>
        {/* Render Breadcrumb */}
        <Breadcrumb title="Dashboard" breadcrumbItem="Change Password" />
        <ChangePasswordComponent />
      </Container>
    </div>
  );
}

export default ChangePassword;
