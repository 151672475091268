/* eslint prefer-const: "off" */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Modal, ModalHeader, ModalBody, Nav, NavItem, TabContent, TabPane
} from 'reactstrap';
import FileDetailComponent from '../components/FileDetailComponent';
import QueryComponent from '../components/QueryComponent';
import DocumentsComponent from '../components/DocumentsComponent';
import HistoryComponent from '../components/HistoryComponent';
// import { NavLink } from 'react-router-dom';
// import classnames from 'classnames';

// import { NavLink } from 'react-router-dom';

function FileDetailModal({ file, isModalOpen, closeModal }) {
  console.log('file', file);
  const [activeTab1, setactiveTab1] = useState('file-detail');
  // const [activeTab2, setactiveTab2] = useState("9");
  // const [activeTab3, setactiveTab3] = useState("13");

  function toggle1(tab) {
    if (activeTab1 !== tab) {
      setactiveTab1(tab);
    }
  }

  return (
    <Modal isOpen={isModalOpen}>
      <ModalHeader tag="h4">
        File Details
        {/* {!!isEdit ? "Edit Order" : "Add Order"} */}
      </ModalHeader>
      <ModalBody>
        <>
          {/* <Card>
            <CardBody> */}
          <Nav pills>
            <NavItem>
              <button
                type="button"
                style={{ cursor: 'pointer' }}
                className={activeTab1 === 'file-detail' ? 'btn btn-primary' : 'btn btn-link'}
                onClick={() => {
                  toggle1('file-detail');
                }}
                to="#"
              >
                <span className="d-block d-sm-none">
                  <i className="fas fa-home" />
                </span>
                <span className="d-none d-sm-block">Details</span>
              </button>
            </NavItem>
            <NavItem>
              <button
                type="button"
                style={{ cursor: 'pointer' }}
                className={activeTab1 === 'query' ? 'btn btn-primary' : 'btn btn-link'}
                onClick={() => {
                  toggle1('query');
                }}
              >
                <span className="d-block d-sm-none">
                  <i className="far fa-user" />
                </span>
                <span className="d-none d-sm-block">Queries</span>
              </button>
            </NavItem>
            <NavItem>
              <button
                type="button"
                style={{ cursor: 'pointer' }}
                className={activeTab1 === 'documents' ? 'btn btn-primary' : 'btn btn-link'}
                onClick={() => {
                  toggle1('documents');
                }}
              >
                <span className="d-block d-sm-none">
                  <i className="far fa-envelope" />
                </span>
                <span className="d-none d-sm-block">Documents</span>
              </button>
            </NavItem>
            <NavItem>
              <button
                type="button"
                style={{ cursor: 'pointer' }}
                className={activeTab1 === 'history' ? 'btn btn-primary' : 'btn btn-link'}
                onClick={() => {
                  toggle1('history');
                }}
              >
                <span className="d-block d-sm-none">
                  <i className="far fa-envelope" />
                </span>
                <span className="d-none d-sm-block">History</span>
              </button>
            </NavItem>
          </Nav>

          <TabContent activeTab={activeTab1} className="p-3 text-muted">
            <TabPane tabId="file-detail">
              <FileDetailComponent file={file} />
            </TabPane>
            <TabPane tabId="query">
              <QueryComponent file={file} />
            </TabPane>
            <TabPane tabId="documents">
              <DocumentsComponent file={file} />
            </TabPane>
            <TabPane tabId="history">
              <HistoryComponent file={file} />
            </TabPane>
          </TabContent>

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                closeModal();
              }}
            >
              Close
            </button>
            {/* <button type="submit" className="btn btn-primary" onClick={addUser}>
                Save
              </button> */}
          </div>

        </>
      </ModalBody>
    </Modal>
  );
}

FileDetailModal.propTypes = {
  file: PropTypes.object,
  isModalOpen: PropTypes.bool,
  closeModal: PropTypes.func
};

export default FileDetailModal;
