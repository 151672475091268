/* eslint prefer-const: "off" */
/* eslint guard-for-in: "off" */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Col, Row } from 'reactstrap';
import {
  extractErrorAndShowToast
} from '../utils';
import BbaSpinner from './Common/BbaSpinner';
import UserPermissionsService from '../services/UserPermissionsService';
import AlertService from '../services/AlertService';
import useUserAssignedPermissions from '../custom-hooks/CustomHookUserAssignedPermissions';

function UserPermissions({ user, handleCloseModal, prevStep }) {
  const [isLoading, setIsLoading] = useState(false);
  const {
    getUserAssignedPermissionsInModulePermissions,
    modulePermissions,
    setModulePermissions,
    isLoading: isUserAssignedLoading
  } = useUserAssignedPermissions();

  useEffect(() => {
    getUserAssignedPermissionsInModulePermissions(user);
    // loadInitialData();
  }, []);

  const checkUncheckModule = (module) => {
    const moduleDetail = module;
    if (!module.checked) {
      module.modulePermission.map((mp) => {
        const mPermission = mp;
        mPermission.checked = true;
        return mp;
      });
    } else {
      module.modulePermission.map((mp) => {
        const mPermission = mp;
        mPermission.checked = false;
        return mp;
      });
    }
    moduleDetail.checked = !module.checked;
    console.log('moduleDetail', moduleDetail);
    setModulePermissions([...modulePermissions]);
  };

  const checkUncheckPermission = (module, permission) => {
    const p = permission;
    const m = module;
    p.checked = !permission.checked;
    if (p.checked) {
      m.checked = true;
    }
    setModulePermissions([...modulePermissions]);
  };

  const addUserPermissions = async () => {
    try {
      const permissions = [];
      for (let module of modulePermissions) {
        for (let permission of module.modulePermission) {
          if (permission.checked) {
            permissions.push(permission.permissionValue);
          }
        }
      }
      const reqObj = {
        userId: user.uuid,
        permissions
      };
      await UserPermissionsService.addUserPermissions(reqObj);
      setIsLoading(false);
      AlertService.showToast('Permission saved successfully');
      handleCloseModal(true);
    } catch (e) {
      console.log('e', e);
      extractErrorAndShowToast(e);
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    }
  };

  return (
    <div>
      {(isLoading || isUserAssignedLoading) && <BbaSpinner />}
      <div className="permission-modal-section">
        {
          modulePermissions.map((m) => (
            <div style={{ paddingTop: 7, paddingBottom: 7 }}>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck2"
                  checked={m.checked}
                  onChange={() => checkUncheckModule(m)}
                />
                <label
                  // className="form-check-label"
                  htmlFor="defaultCheck2"
                >
                  {m.name}
                </label>
              </div>
              <div style={{ marginTop: '3px', paddingLeft: 15 }}>
                <Row>
                  {m.modulePermission.map((mp) => (
                    <Col sm={6}>
                      <span className="form-check">
                        <input
                          className="form-check-input sub-checkbox"
                          type="checkbox"
                          value=""
                          checked={mp.checked}
                          id={mp.permissionName}
                          onChange={() => checkUncheckPermission(m, mp)}
                        />
                        <label
                          // className="form-check-label"
                          className="fw-normal"
                          htmlFor={mp.permissionName}
                        >
                          {mp.permissionName}
                        </label>
                      </span>
                    </Col>
                  ))}
                </Row>
              </div>
            </div>
          ))
        }
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-light"
          onClick={() => {
            handleCloseModal();
          }}
        >
          Close
        </button>
        <button
          type="button"
          className="btn btn-light"
          onClick={() => {
            prevStep();
          }}
        >
          Back to Setup Branch
        </button>
        <button type="submit" className="btn btn-primary" onClick={addUserPermissions}>
          Save
        </button>
      </div>

    </div>
  );
}

UserPermissions.propTypes = {
  user: PropTypes.object,
  prevStep: PropTypes.func,
  handleCloseModal: PropTypes.func
};

export default UserPermissions;
