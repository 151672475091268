/* eslint prefer-const: "off" */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Table, Tbody, Th, Thead, Tr, Td
} from 'react-super-responsive-table';
import FileService from '../services/FileService';
import { extractErrorAndShowToast, formatDate } from '../utils';
import BbaSpinner from './Common/BbaSpinner';

function HistoryComponent({ file }) {
  const [fileHistory, setFileHistory] = useState([]);
  const [isLoading, setIsLoading] = useState([]);
  // const [pageCount, setPageCount] = useState(1);

  useEffect(() => {
    getFileHistory();
  }, []);

  const getFileHistory = async () => {
    try {
      const fileHistoryResponse = await FileService.getFileHistory(1, 1000, file.uuid);
      const fileHistoryResponseData = fileHistoryResponse.data;
      const { data } = fileHistoryResponseData;
      console.log('fileHistoryResponseData', fileHistoryResponseData);
      // setUsers(userResponseData.users);
      setFileHistory(data);
      // setPageCount(totalPages);
      setIsLoading(false);
    } catch (e) {
      console.log('e', e);
      extractErrorAndShowToast(e);
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    }
  };

  return (
    <div>
      {isLoading && <BbaSpinner />}
      <Table
        className="table table-striped"
      >
        <Thead>
          <Tr>
            <Th>Created Date</Th>
            <Th>Action</Th>
            <Th>History Details</Th>
            {/* <Th style={{ width: '120px' }}>Actions</Th> */}
          </Tr>
        </Thead>
        <Tbody>
          {fileHistory.length > 0 && fileHistory.map((h) => (
            <Tr>
              <Td>
                {formatDate(h.created_at)}
              </Td>
              <Td>
                {h.action}
              </Td>
              <Td>
                {h.history_details}
              </Td>

              {/* <Td>
                <span onClick={() => downlodFile(`${REACT_APP_API_URL}/${fileHistory.file_name}`)} role="button" tabIndex="-1" className="text-primary">
                  <i className="uil uil-cloud-download font-size-20" />
                </span>
              </Td> */}
            </Tr>
          ))}
          {
            fileHistory.length === 0 && (
              <Tr>
                <Td colSpan="7" className="text-center">No Documents found</Td>
              </Tr>
            )
          }
        </Tbody>
      </Table>
      {/* {fileHistory.map((f) => <div onClick={() => downlodFile(`${REACT_APP_API_URL}/${f.file_name}`)} role="button" tabIndex={-1}>{f.file_name}</div>)} */}
    </div>
  );
}

HistoryComponent.propTypes = {
  file: PropTypes.object
};

export default HistoryComponent;
