/* eslint prefer-const: "off" */
import React, { useEffect, useState } from 'react';

import {
  Row, Col, CardBody, Card, Container, Form, Input, FormFeedback, Label,
} from 'reactstrap';

// import * as _ from 'lodash';
// Redux
import { Link, useNavigate } from 'react-router-dom';

// Formik validation
// import * as Yup from 'yup';
// import { useFormik } from 'formik';

// import images
import { useDispatch } from 'react-redux';
import logo from '../../assets/images/logo-dark.png';
import logolight from '../../assets/images/logo-light.png';

import AuthService from '../../services/AuthService';
// import AlertService from '../../services/AlertService';
import StorageService from '../../services/StorageService';
import ValidationService from '../../services/Validation';

import useUserAssignedPermissions from '../../custom-hooks/CustomHookUserAssignedPermissions';

import { extractErrorAndShowToast } from '../../utils';
import { setUserPermissions } from '../../redux-slices/permission';

const control = {
  value: '',
  valid: true
};

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    getUserAssignedPermissionsInModulePermissions,
  } = useUserAssignedPermissions();

  let [email, setEmail] = useState({ ...control });
  let [password, setPassword] = useState({ ...control });
  const handleValidation = () => {
    email = ValidationService.isEmpty(email);
    // email = ValidationService.lengthValidation(email, FIRST_NAME_MAX_LENGTH);
    if (email.isEmpty) {
      email.valid = false;
    } else {
      email.valid = true;
    }

    password = ValidationService.isEmpty(password);
    // password = ValidationService.lengthValidation(password, LAST_NAME_MAX_LENGTH);
    if (password.isEmpty) {
      password.valid = false;
    } else {
      password.valid = true;
    }

    setEmail({ ...email });
    setPassword({ ...password });

    if (
      email.valid
      && password.valid
    ) {
      return true;
    }
    return false;
  };

  const signIn = async () => {
    try {
      const isFormValid = handleValidation();
      if (!isFormValid) {
        return;
      }
      const obj = {
        email: email.value,
        password: password.value
      };
      const { data } = await AuthService.signin(obj);
      data.uuid = data.id;
      StorageService.setToken(data.token.access);
      const { modulePermissionsResponse, userPermissionsMap } = await getUserAssignedPermissionsInModulePermissions(data);
      dispatch(setUserPermissions({
        permissions: userPermissionsMap,
        modulePermissions: modulePermissionsResponse
      }));
      // console.log('userPermissions', userPermissionsMap);
      // data.permissions = userPermissionsMap;
      // data.modulePermissions = modulePermissionsResponse;
      StorageService.setUserDetail(data);

      if (data.forceReset) {
        navigate('/reset-password');
      } else {
        navigate('/file');
      }
    } catch (e) {
      extractErrorAndShowToast(e);
    }
  };

  useEffect(() => {
    document.body.className = 'authentication-bg';
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = '';
    };
  });

  return (
    <div className="account-pages my-5 pt-sm-5">
      <Container>
        <Row>
          <Col lg={12}>
            <div className="text-center">
              <Link to="/" className="mb-5 d-block auth-logo">
                <img src={logo} alt="" height="30" className="logo logo-dark" />
                <img src={logolight} alt="" height="30" className="logo logo-light" />
              </Link>
            </div>
          </Col>
        </Row>
        <Row className="align-items-center justify-content-center">
          <Col md={8} lg={6} xl={5}>
            <Card>
              <CardBody className="p-4">
                <div className="text-center mt-2">
                  <h5 className="text-primary">Sign in!</h5>
                  {/* <p className="text-muted">Sign in to continue to BBA Cloud.</p> */}
                </div>
                <div className="p-2 mt-4">
                  <Form
                    className="form-horizontal"
                    onSubmit={(e) => {
                      e.preventDefault();
                      signIn();
                      // validation.handleSubmit();

                      return false;
                    }}
                  >
                    {/* {error ? <Alert color="danger">{error}</Alert> : null} */}

                    <div className="mb-3">
                      <Label className="form-label">Email</Label>
                      <Input
                        name="email"
                        className="form-control"
                        placeholder="Enter email"
                        type="email"
                        onChange={(e) => setEmail({ ...email, value: e.target.value })}
                        value={email.value || ''}
                        invalid={
                          !(email.valid)
                        }
                      />
                      {email && email.isEmpty ? (
                        <FormFeedback type="invalid">Please enter valid email</FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <div className="float-end">
                        <Link to="/forgot-password" className="text-muted">Forgot password?</Link>
                      </div>
                      <Label className="form-label">Password</Label>
                      <Input
                        name="password"
                        value={password.value || ''}
                        type="password"
                        placeholder="Enter Password"
                        onChange={(e) => setPassword({ ...password, value: e.target.value })}
                        invalid={
                          !(password.valid)
                        }
                      />
                      {password && password.isEmpty ? (
                        <FormFeedback type="invalid">Please enter password</FormFeedback>
                      ) : null}
                    </div>

                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="customControlInline"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="customControlInline"
                      >
                        Remember me
                      </label>
                    </div>

                    <div className="mt-3">
                      <button
                        className="btn btn-primary w-100 waves-effect waves-light"
                        type="submit"
                        formNoValidate
                      >
                        Log In
                      </button>
                    </div>

                    {/* <div className="mt-4 text-center">
                      <p className="mb-0">
                        Don&apos;t have an account ?
                        <a href="/register" className="fw-medium text-primary"> Signup now </a>
                      </p>
                    </div> */}

                  </Form>

                </div>
              </CardBody>
            </Card>
            <div className="mt-5 text-center">
              <p>
                ©
                {new Date().getFullYear()}
                {' '}
                BBAssociates
              </p>
            </div>
          </Col>
        </Row>

      </Container>
    </div>
  );
}

Login.propTypes = {
  // error: PropTypes.any,
  // history: PropTypes.object,
  // loginUser: PropTypes.func,
  // socialLogin: PropTypes.func
};
export default Login;
