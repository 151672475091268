import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  Row, Col, Card, CardBody, CardTitle, Input, Button,
  // Label
} from 'reactstrap';
import {
  Table, Thead, Tbody, Tr, Th, Td,
} from 'react-super-responsive-table';
// import Switch from 'react-switch';
import _debounce from 'lodash/debounce';

import BbaSpinner from '../../components/Common/BbaSpinner';
import Pagination from '../../components/Common/Pagination';

import BranchService from '../../services/BranchService';

import ConfirmationModal from '../../modal/ConfirmationModal';
import AddBranchModal from '../../modal/AddBranch';
import ErrorModal from '../../modal/ErrorModal';

import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { extractErrorAndShowToast } from '../../utils';
// import StorageService from '../../services/StorageService';
import permissionConstant from '../../constants/permission.constant';

const {
  ADD_BRANCH,
  UPDATE_BRANCH,
  DELETE_BRANCH,
  // LIST_BRANCH
} = permissionConstant;

function Branch() {
  const userPermissions = useSelector((state) => state.permission.userPermissions);
  const [branches, setBranches] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [active, setActive] = useState('');
  const [search, setSearch] = useState('');
  const [pageCount, setPageCount] = useState(10);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isBranchModalOpen, setIsBranchModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorModalDetail, setErrorModalDetail] = useState(null);
  const [branchToUpdate, setBranchToUpdate] = useState(null);
  // const userDetail = StorageService.getUserDetail();
  // const { permissions = {} } = userDetail;
  const permissions = userPermissions ? userPermissions?.permissions : {};

  useEffect(() => {
    getBranches();
    console.log(process.env.REACT_APP_API_URL);
    // @NOTE: added to remove validation error
    setLimit(10);
  }, []);

  useEffect(() => {
    getBranches();
  }, [page, limit, active]);

  useEffect(() => {
    console.log('search', search);
    getCityDebounceFunction(search);
  }, [search]);

  const getBranches = async (searchKeyword) => {
    try {
      const branchResponse = await BranchService.getBranches(page, limit, searchKeyword || search, active);
      const branchResponseData = branchResponse.data;
      const { data, totalPages } = branchResponseData;
      console.log('branchResponseData', branchResponseData);
      // setUsers(userResponseData.users);
      setBranches(data);
      setPageCount(totalPages);
      setIsLoading(false);
    } catch (e) {
      console.log('e', e);
      // const errorObj = {
      //   title: 'Citys',
      //   message: 'Something went wrong!!',
      // };
      // setErrorModalDetail(errorObj);
      // setIsErrorModalOpen(true);
      extractErrorAndShowToast(e);
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    }
  };

  const getCityDebounceFunction = useCallback(_debounce(getBranches, 500), []);

  const updateBranch = async (updateObj) => {
    try {
      await BranchService.updateBranch(updateObj);
    } catch (e) {
      // console.log('update failed');
      // const errorObj = {
      //   title: 'Citys',
      //   message: 'Something went wrong!!',
      // };
      // setErrorModalDetail(errorObj);
      // setIsErrorModalOpen(true);
      extractErrorAndShowToast(e);
    }
  };

  const activeOrInactiveBranch = async (cityId, value) => {
    const updateObj = {
      id: cityId,
      isActive: value,
    };
    await updateBranch(updateObj);

    // @NOTE: comment once api is active
    const branch = branches.find((b) => b.uuid === cityId);
    branch.is_active = value;

    setBranches([...branches]);
    // call update user api
  };

  const openDeleteBranchModal = (branch) => {
    setBranchToUpdate(branch);
    setIsModalOpen(true);
  };

  const editBranch = (branch) => {
    setIsBranchModalOpen(true);
    setBranchToUpdate(branch);
  };

  const deleteBranch = async (branch) => {
    const updateObj = {
      id: branch.uuid,
      isDeleted: true,
    };
    await updateBranch(updateObj);
    setBranchToUpdate(null);
    getBranches();
    setIsModalOpen(false);
  };

  const handlePageChange = (selectedPage) => {
    setPage(selectedPage);
  };

  const handleCloseConfirmationModal = () => {
    setIsModalOpen(false);
  };

  const handleCloseErrorModal = () => {
    setIsErrorModalOpen(false);
    setErrorModalDetail(null);
  };

  const handleCloseBranchModal = (reload) => {
    setIsBranchModalOpen(false);
    setBranchToUpdate(null);
    if (reload) {
      getBranches();
    }
  };
  const openAddCityModal = () => {
    setIsBranchModalOpen(true);
  };

  return (
    <div className="page-content">
      <div className="container-fluid">
        {/* <Breadcrumbs title="Tables" breadcrumbItem="Responsive Table" /> */}

        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                {isLoading && <BbaSpinner />}
                <CardTitle className="h4">Branch </CardTitle>
                <Row>
                  <Col sm={10} md={5} lg={5}>
                    <div className="pb-3">
                      <div className="search-box chat-search-box">
                        <div className="position-relative">
                          <Input
                            type="text"
                            className="form-control bg-light border-light rounded"
                            placeholder="Search..."
                            onChange={(e) => setSearch(e.target.value)}
                          />
                          <i className="uil uil-search search-icon" />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col sm={6} md={2} lg={2}>
                    <div className="pb-3">
                      {/* <Label className="form-label">Role</Label> */}

                      <div className="position-relative">
                        <Input
                          type="select"
                          className="pagination-button-size"
                          // className="form-control bg-light border-light rounded"
                          // placeholder="Search..."
                          onChange={(e) => setActive(e.target.value)}
                        >
                          <option value="">All Branches</option>
                          <option value="true">Active</option>
                          <option value="false">InActive</option>
                        </Input>
                      </div>
                    </div>
                  </Col>

                  <Col sm={6} md={5} lg={5}>
                    {permissions[ADD_BRANCH] && (
                      <div className="pb-3 text-sm-end">
                        <Button
                          type="button"
                          color="success"
                          className="waves-effect waves-light mb-3 btn btn-success"
                          onClick={openAddCityModal}
                        >
                          <i className="mdi mdi-plus me-1" />
                          Add Branch
                        </Button>
                      </div>
                    )}
                  </Col>
                </Row>

                <div className="table-rep-plugin">
                  <div
                    className="table-responsive mb-0"
                    data-pattern="priority-columns"
                  >
                    <Table
                      id="tech-companies-1"
                      className="table table-striped"
                    >
                      <Thead>
                        <Tr>
                          <Th style={{ minWidth: '400px' }}>Name</Th>
                          <Th style={{ minWidth: '400px' }}>City</Th>
                          <Th>Active</Th>
                          <Th style={{ width: '120px' }}>Actions</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {branches && branches.map((branch) => (
                          <Tr>
                            <Td>
                              {branch.name}
                            </Td>
                            <Td>
                              {branch.city_name}
                            </Td>
                            <Td>
                              {/* {user.is_active.toString()} */}

                              <div className="form-check form-switch form-switch-md">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="customSwitchsizemd"
                                  checked={!!branch.is_active}
                                  // defaultChecked
                                  onClick={() => {
                                    activeOrInactiveBranch(branch.uuid, !branch.is_active);
                                  }}
                                />
                              </div>
                            </Td>
                            <Td>
                              {permissions[UPDATE_BRANCH] && (
                                <>
                                  <span onClick={() => editBranch(branch)} role="link" tabIndex="-1" className="text-primary">
                                    <i className="uil uil-pen font-size-20" />
                                  </span>
                                  &nbsp;&nbsp;
                                </>
                              )}

                              {permissions[DELETE_BRANCH] && (
                                <span onClick={() => openDeleteBranchModal(branch)} role="button" tabIndex="-1" className="text-danger">
                                  <i className="uil-trash-alt bba-icon font-size-20" />
                                </span>
                              )}
                            </Td>
                          </Tr>
                        ))}
                        {
                          branches.length === 0 && (
                            <Tr>
                              <Td colSpan="3" className="text-center">No Branch found</Td>
                            </Tr>
                          )
                        }
                      </Tbody>
                    </Table>
                  </div>
                </div>
                {isModalOpen && (
                  <ConfirmationModal
                    isModalOpen={isModalOpen}
                    title="Delete Branch"
                    message="Are you sure you want to delete Branch?"
                    closeModal={handleCloseConfirmationModal}
                    handleConfirmation={() => deleteBranch(branchToUpdate)}
                  />
                )}

                {isBranchModalOpen && (
                  <AddBranchModal
                    isModalOpen={isBranchModalOpen}
                    branch={branchToUpdate}
                    handleCloseModal={handleCloseBranchModal}
                  />
                )}

                {isErrorModalOpen && (
                  <ErrorModal
                    isModalOpen={isErrorModalOpen}
                    {...errorModalDetail}
                    closeModal={handleCloseErrorModal}
                  />
                )}

                <Pagination
                  handlePageChange={handlePageChange}
                  page={page}
                  pageCount={pageCount}
                />

              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
  // return <div className="page-content">Users</div>
}

export default Branch;
