import React, { useEffect, useState, useCallback } from 'react';
import {
  Row, Col, Card, CardBody, CardTitle, Input, Button,
  // Label
} from 'reactstrap';
import {
  Table, Thead, Tbody, Tr, Th, Td,
} from 'react-super-responsive-table';
// import Switch from 'react-switch';
import _debounce from 'lodash/debounce';

import { useSelector } from 'react-redux';
import BbaSpinner from '../../components/Common/BbaSpinner';
import Pagination from '../../components/Common/Pagination';

import CompanyService from '../../services/CompanyService';
// import RoleService from '../../services/RoleService';

import ConfirmationModal from '../../modal/ConfirmationModal';
import AddCompanyModal from '../../modal/AddCompany';
import ErrorModal from '../../modal/ErrorModal';

import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { extractErrorAndShowToast } from '../../utils';
// import StorageService from '../../services/StorageService';
import permissionConstant from '../../constants/permission.constant';

const {
  ADD_BANK_CONFIGURATION,
  UPDATE_BANK_CONFIGURATION,
  DELETE_BANK_CONFIGURATION,
  // LIST_BANK_CONFIGURATION
} = permissionConstant;
// const userDetail = StorageService.getUserDetail();
function Company() {
  const userPermissions = useSelector((state) => state.permission.userPermissions);
  // const [roles, setRoles] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [active, setActive] = useState('');
  // const [role, setRole] = useState('');
  const [search, setSearch] = useState('');
  const [pageCount, setPageCount] = useState(10);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCompanyModalOpen, setIsCompanyModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorModalDetail, setErrorModalDetail] = useState(null);
  const [companyToUpdate, setCompanyToUpdate] = useState(null);
  // const userDetail = StorageService.getUserDetail();
  // const { permissions = {} } = userDetail;
  const permissions = userPermissions ? userPermissions?.permissions : {};

  useEffect(() => {
    getCompanies();
    console.log(process.env.REACT_APP_API_URL);
    // @NOTE: added to remove validation error
    setLimit(10);
  }, []);

  useEffect(() => {
    getCompanies();
  }, [page, limit, active]);

  useEffect(() => {
    console.log('search', search);
    getCompanyDebounceFunction(search);
  }, [search]);

  const getCompanies = async (searchKeyword) => {
    try {
      const userResponse = await CompanyService.getCompanies(page, limit, searchKeyword || search, active);
      const userResponseData = userResponse.data;
      console.log('userResponseData', userResponseData);
      setCompanies(userResponseData.data);
      setPageCount(userResponseData.totalPages);
      // setCompanies(userResponseData);
      // setPageCount(10);
      setIsLoading(false);
    } catch (e) {
      console.log('e', e);
      // const errorObj = {
      //   title: 'Users',
      //   message: 'Something went wrong!!',
      // };
      // setErrorModalDetail(errorObj);
      // setIsErrorModalOpen(true);
      extractErrorAndShowToast(e);
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    }
  };

  const getCompanyDebounceFunction = useCallback(_debounce(getCompanies, 1000), []);

  const updateCompany = async (updateObj) => {
    try {
      await CompanyService.updateCompany(updateObj);
    } catch (e) {
      console.log('update failed');
      // const errorObj = {
      //   title: 'Company',
      //   message: 'Something went wrong!!',
      // };
      // setErrorModalDetail(errorObj);
      // setIsErrorModalOpen(true);
      extractErrorAndShowToast(e);
    }
  };

  const activeOrInactiveCompany = async (companyId, value) => {
    const updateObj = {
      id: companyId,
      isActive: value,
    };
    await updateCompany(updateObj);

    // @NOTE: comment once api is active
    const company = companies.find((c) => c.uuid === companyId);
    company.is_active = value;

    setCompanies([...companies]);
    // getCompanies();
    // call update user api
  };

  const openDeleteCompanyModal = (company) => {
    setCompanyToUpdate(company);
    setIsModalOpen(true);
  };

  const editCompany = (company) => {
    setIsCompanyModalOpen(true);
    setCompanyToUpdate(company);
  };

  const deleteCompany = async () => {
    console.log('companyToUpdate', companyToUpdate);
    const updateObj = {
      id: companyToUpdate.uuid,
      isDeleted: true,
    };
    await updateCompany(updateObj);
    getCompanies();
    handleCloseConfirmationModal();
  };

  const handlePageChange = (selectedPage) => {
    setPage(selectedPage);
  };

  const handleCloseConfirmationModal = () => {
    setIsModalOpen(false);
  };

  const handleCloseErrorModal = () => {
    setIsErrorModalOpen(false);
    setErrorModalDetail(null);
  };

  const handleCloseCompanyModal = (reload) => {
    setIsCompanyModalOpen(false);
    setCompanyToUpdate(null);
    if (reload) {
      getCompanies();
    }
  };

  const openAddCompanyModal = () => {
    setIsCompanyModalOpen(true);
  };

  return (
    <div className="page-content">
      <div className="container-fluid">
        {/* <Breadcrumbs title="Tables" breadcrumbItem="Responsive Table" /> */}

        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                {isLoading && <BbaSpinner />}
                <CardTitle className="h4">Bank Configuration</CardTitle>
                <Row>
                  <Col sm={10} md={5} lg={5}>
                    <div className="pb-3">
                      <div className="search-box chat-search-box">
                        <div className="position-relative">
                          <Input
                            type="text"
                            className="form-control bg-light border-light rounded"
                            placeholder="Search by Bank Name"
                            onChange={(e) => setSearch(e.target.value)}
                          />
                          <i className="uil uil-search search-icon" />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col sm={6} md={2} lg={2}>
                    <div className="pb-3">
                      {/* <Label className="form-label">Role</Label> */}

                      <div className="position-relative">
                        <Input
                          type="select"
                          // className="form-control bg-light border-light rounded"
                          // placeholder="Search..."
                          onChange={(e) => setActive(e.target.value)}
                        >
                          <option value="">All Bank Configuration</option>
                          <option value="true">Active</option>
                          <option value="false">InActive</option>
                        </Input>
                      </div>
                    </div>
                  </Col>

                  <Col sm={2} md={5} lg={5}>
                    {permissions[ADD_BANK_CONFIGURATION] && (
                      <div className="pb-3 text-sm-end">
                        <Button
                          type="button"
                          color="success"
                          className="waves-effect waves-light mb-3 btn btn-success"
                          onClick={openAddCompanyModal}
                        >
                          <i className="mdi mdi-plus me-1" />
                          Add Bank Configuration
                        </Button>
                      </div>
                    )}
                  </Col>
                </Row>

                <div className="table-rep-plugin">
                  <div
                    className="table-responsive mb-0"
                    data-pattern="priority-columns"
                  >
                    <Table
                      id="tech-companies-1"
                      className="table table-striped"
                    >
                      <Thead>
                        <Tr>
                          <Th>Bank Name</Th>
                          <Th>TSR Fees</Th>
                          <Th>VR Fees</Th>
                          <Th>Emails</Th>
                          <Th>Active</Th>
                          <Th style={{ width: '120px' }}>Actions</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {companies.map((company) => (
                          <Tr>
                            <Td>
                              {`${company.bank_name}`}
                            </Td>
                            <Td>{company.tsr_fees}</Td>
                            <Td>{company.vr_fees}</Td>
                            <Td>{company.emails && company.emails.map((e) => <div>{e}</div>)}</Td>
                            <Td>
                              {/* {company.is_active.toString()} */}
                              <div className="form-check form-switch form-switch-md">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="customSwitchsizemd"
                                  checked={!!company.is_active}
                                  // defaultChecked
                                  onClick={() => {
                                    activeOrInactiveCompany(company.uuid, !company.is_active);
                                  }}
                                />
                              </div>
                            </Td>
                            <Td>
                              {permissions[UPDATE_BANK_CONFIGURATION] && (
                                <>
                                  <span onClick={() => editCompany(company)} role="link" tabIndex="-1" className="text-primary">
                                    <i className="uil uil-pen font-size-20" />
                                  </span>
                                  &nbsp;&nbsp;
                                </>
                              )}
                              {permissions[DELETE_BANK_CONFIGURATION] && (
                                <span onClick={() => openDeleteCompanyModal(company)} role="button" tabIndex="-1" className="text-danger">
                                  <i className="uil-trash-alt bba-icon font-size-20" />
                                </span>
                              )}
                            </Td>
                          </Tr>
                        ))}
                        {
                          companies.length === 0 && (
                            <Tr>
                              <Td colSpan="7" className="text-center">No Bank Configuration Found</Td>
                            </Tr>
                          )
                        }
                      </Tbody>
                    </Table>
                  </div>
                </div>
                {isModalOpen && (
                  <ConfirmationModal
                    isModalOpen={isModalOpen}
                    title="Delete Bank Configuration"
                    message="Are you sure you want to delete Bank Configuration?"
                    closeModal={handleCloseConfirmationModal}
                    handleConfirmation={() => deleteCompany()}
                  />
                )}

                {isCompanyModalOpen && (
                  <AddCompanyModal
                    isModalOpen={isCompanyModalOpen}
                    company={companyToUpdate}
                    handleCloseModal={handleCloseCompanyModal}
                  />
                )}

                {isErrorModalOpen && (
                  <ErrorModal
                    isModalOpen={isErrorModalOpen}
                    {...errorModalDetail}
                    closeModal={handleCloseErrorModal}
                  />
                )}

                {pageCount > 1 && (
                  <Pagination
                    handlePageChange={handlePageChange}
                    page={page}
                    pageCount={pageCount}
                  />
                )}

              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
  // return <div className="page-content">Users</div>
}

export default Company;
