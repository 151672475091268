/* eslint prefer-const: "off" */

// import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import {
  Row, Col, Card, CardBody, Container
} from 'reactstrap';
import { Link } from 'react-router-dom';

// import images
import logo from '../../assets/images/logo-dark.png';
import logolight from '../../assets/images/logo-light.png';
// import AuthService from '../../services/AuthService';

function Success() {
  // const dispatch = useDispatch();

  useEffect(() => {
    document.body.className = 'authentication-bg';
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = '';
    };
  });

  return (
    <div className="account-pages my-5  pt-sm-5">
      <Container>
        <Row>
          <Col lg={12}>
            <div className="text-center">
              <Link to="/" className="mb-5 d-block auth-logo">
                <img src={logo} alt="" height="40" className="logo logo-dark" />
                <img src={logolight} alt="" height="40" className="logo logo-light" />
              </Link>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md={8} lg={6} xl={5}>
            <div>
              <Card>
                <CardBody className="p-4">
                  <div className="text-center mt-2">
                    <span role="button" tabIndex="-1" className="text-success">
                      <i className="uil-check-circle font-size-60" />
                    </span>
                  </div>
                  <div className="text-center mt-2">
                    <h5 className="text-primary">Congratulations! your email has been verified.</h5>
                  </div>
                  <br />
                  <div className="text-center mt-2">
                    <h5 className="text-secondary">Please check your email to reset the password.</h5>
                  </div>

                  {/* <div className="p-2 mt-4">
                    <div className="alert alert-success text-center mb-4" role="alert">
                      Enter your Password and instructions will be sent to you!
                    </div>
                  </div> */}
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  ©
                  {' '}
                  {new Date().getFullYear()}
                  {' '}
                  B&B Associates
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

Success.propTypes = {
  // forgetError: PropTypes.any,
  // forgetSuccessMsg: PropTypes.any,
  // history: PropTypes.object,
  // userForgetPassword: PropTypes.func,
};

export default Success;
