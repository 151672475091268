/* eslint prefer-const: "off" */

import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Modal, ModalHeader, ModalBody, Form, Label, Input, FormFeedback,
  Row, Col
} from 'reactstrap';
import Select from 'react-select';

import BankService from '../services/BankService';
import CompanyService from '../services/CompanyService';
import ValidationService from '../services/Validation';

import ErrorModal from './ErrorModal';

import AlertService from '../services/AlertService';
import { extractErrorAndShowToast } from '../utils';

const control = {
  value: '',
  valid: true
};

function AddCompany({ company, isModalOpen, handleCloseModal }) {
  const emailRef = useRef(null);

  let [name, setName] = useState({ ...control });
  // const [selectedGroup, setselectedGroup] = useState(null);
  let [active, setActive] = useState(true);
  let [emailControl, setEmail] = useState('');
  let [emails, setEmails] = useState([]);
  let [tsrFees, setTSRFees] = useState({ ...control });
  let [vrFees, setVRFees] = useState({ ...control });
  let [banks, setBanks] = useState([]);
  const [companyId, setCompanyId] = useState(null);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorModalDetail, setErrorModalDetail] = useState(null);

  useEffect(() => {
    if (company) {
      // setName({ ...control, value: company.name });
      setEmails(company.emails || []);
      setTSRFees({ ...control, value: company.tsr_fees });
      setVRFees({ ...control, value: company.vr_fees });
      setActive(company.is_active);
      setCompanyId(company.uuid);
      if (company.emails && company.emails.length > 0) {
        const emailsFromDb = [];
        for (let email of company.emails) {
          emailsFromDb.push({
            ...control,
            value: email
          });
        }
        setEmails(emailsFromDb);
      } else {
        setEmails([]);
      }
    }
    getBanks();
  }, []);

  const handleValidation = () => {
    name = ValidationService.isEmpty(name);
    if (name.isEmpty) {
      name.valid = false;
    } else {
      name.valid = true;
    }

    tsrFees = ValidationService.isEmpty(tsrFees);
    if (tsrFees.isEmpty) {
      tsrFees.valid = false;
    } else {
      tsrFees.valid = true;
    }

    vrFees = ValidationService.isEmpty(vrFees);
    if (vrFees.isEmpty) {
      vrFees.valid = false;
    } else {
      vrFees.valid = true;
    }

    let isEmailValid = true;
    if (emails.length > 0) {
      for (let i = 0; i < emails.length; i++) {
        let email = emails[i];
        if (email.value) {
          email = ValidationService.isInvalidEmail(email);
          if (email.isInvalidEmail) {
            isEmailValid = false;
          }
        }
        emails[i] = { ...email };
      }
      setEmails([...emails]);
    }

    setName({ ...name });
    setTSRFees({ ...tsrFees });

    if (
      name.valid
      && tsrFees.valid
      && vrFees.valid
      && isEmailValid
    ) {
      return true;
    }
    return false;
  };

  const addCompany = async () => {
    try {
      const isFormValid = handleValidation();
      if (!isFormValid) {
        return;
      }
      if (emails.length === 0) {
        AlertService.showToast('Please Add Emails', 'error');
        return;
      }
      const emailIds = [];
      for (let email of emails) {
        if (email.value) {
          emailIds.push(email.value);
        }
      }
      const userObj = {
        bank: name.value,
        tsrFees: tsrFees.value,
        vrFees: vrFees.value,
        emails: emailIds,
      };
      console.log('userObj', userObj);
      if (companyId) {
        userObj.id = companyId;
        userObj.isActive = active;
        await CompanyService.updateCompany(userObj);
      } else {
        await CompanyService.addCompany(userObj);
      }
      AlertService.showToast(`${company && company.uuid ? 'Bank Configuration updated successfully' : 'Bank Configuration added successfully'}`);
      handleCloseModal(true);
    } catch (e) {
      extractErrorAndShowToast(e);
    }
  };

  const getBanks = async () => {
    try {
      const rolesResponse = await BankService.getAdminBanks(1, 10000);
      const responseBanks = rolesResponse.data.data;
      let bankDropdown = [];
      if (responseBanks && responseBanks.length > 0) {
        for (let responseBank of responseBanks) {
          const dropdownObj = {
            label: responseBank.name,
            value: responseBank.uuid
          };
          bankDropdown.push(dropdownObj);
        }
      }
      setBanks(bankDropdown);

      if (!(company && company.uuid)) {
        setName({ ...control, value: responseBanks[0].uuid, optionValue: bankDropdown[0] });
      } else {
        const selectedBankDetail = bankDropdown.find((b) => b.value === company.bank_uuid);
        setName({ ...control, value: selectedBankDetail.value, optionValue: selectedBankDetail });
      }
      console.log('roles', banks);
    } catch (e) {
      extractErrorAndShowToast(e);
    }
  };

  const handleCloseErrorModal = () => {
    setIsErrorModalOpen(false);
    setErrorModalDetail(null);
  };

  const handleEmailsChange = (index, value) => {
    const email = emails[index];
    email.value = value;
    // if (index === emails.length - 1) {
    //   emails.push({ ...control });
    // }
    setEmails([...emails]);
  };

  useEffect(() => {
    if (emailRef.current) {
      emailRef.current.focus();
    }
  }, []);

  const deleteEmail = (index) => {
    emails.splice(index, 1);
    setEmails([...emails]);
  };

  const addEmail = () => {
    if (!emailControl) {
      return;
    }
    const emailControlObj = {
      value: emailControl,
      valid: true
    };
    emails.push(emailControlObj);
    setEmail('');
    if (emailRef.current) {
      emailRef.current.focus();
    }
  };

  // function handleSelectGroup(selectedGroupData) {
  //   setselectedGroup(selectedGroupData);
  // }

  // const optionGroup = [
  //   {
  //     label: 'Picnic',
  //     options: [
  //       { label: 'Mustard', value: 'Mustard' },
  //       { label: 'Ketchup', value: 'Ketchup' },
  //       { label: 'Relish', value: 'Relish' },
  //     ],
  //   },
  //   {
  //     label: 'Camping',
  //     options: [
  //       { label: 'Tent', value: 'Tent' },
  //       { label: 'Flashlight', value: 'Flashlight' },
  //       { label: 'Toilet Paper', value: 'Toilet Paper' },
  //     ],
  //   },
  // ];

  return (
    <>
      <Modal isOpen={isModalOpen}>
        <ModalHeader tag="h4">
          {companyId ? 'Edit Bank Configuration' : 'Add Bank Configuration'}
          {/* {!!isEdit ? "Edit Order" : "Add Order"} */}
        </ModalHeader>
        <ModalBody>
          <Form
            className="form-horizontal"
            onSubmit={(e) => {
              e.preventDefault();
              // validation.handleSubmit();

              return false;
            }}
          >
            {/* {error ? <Alert color="danger">{error}</Alert> : null} */}

            <div className="mb-3">
              <Label className="form-label">
                Bank Name
                <span className="text-danger">&nbsp;*</span>
              </Label>
              <Select
                value={name.optionValue}
                onChange={(e) => {
                  console.log('e', e);
                  setName({ ...name, optionValue: e, value: e.value });
                }}
                isDisabled={!!company}
                options={banks}
                classNamePrefix="select2-selection"
              />
              {name.isEmpty ? (
                <FormFeedback type="invalid">Name should not be empty</FormFeedback>
              ) : null}
            </div>

            {/* <div className="mb-3">
              <Label className="form-label">
                Bank Name
                <span className="text-danger">&nbsp;*</span>
              </Label>
              <Input
                type="select"
                onChange={(e) => { setName({ ...name, value: e.target.value }); }}
              >
                {banks.map((b) => <option value={b.uuid}>{b.name}</option>)}
              </Input>
              {name.isEmpty ? (
                <FormFeedback type="invalid">Name should not be empty</FormFeedback>
              ) : null}
            </div> */}

            <div className="mb-3">
              <Label className="form-label">
                TSR Fees
                <span className="text-danger">&nbsp;*</span>
              </Label>
              <Input
                name="tsrFees"
                className="form-control"
                placeholder="Enter Fees"
                type="number"
                min="0"
                value={tsrFees.value}
                onWheel={(e) => e.target.blur()}
                onChange={(e) => setTSRFees({ ...tsrFees, value: e.target.value })}
                invalid={
                  !(tsrFees.valid)
                }
              />
              {tsrFees.isEmpty ? (
                <FormFeedback type="invalid">TSR fees should not be empty</FormFeedback>
              ) : null}
            </div>

            <div className="mb-3">
              <Label className="form-label">
                VR Fees
                <span className="text-danger">&nbsp;*</span>
              </Label>
              <Input
                name="tsrFees"
                className="form-control"
                placeholder="Enter Fees"
                min="0"
                type="number"
                value={vrFees.value}
                onWheel={(e) => e.target.blur()}
                onChange={(e) => setVRFees({ ...vrFees, value: e.target.value })}
                invalid={
                  !(vrFees.valid)
                }
              />
              {vrFees.isEmpty ? (
                <FormFeedback type="invalid">VR Fees should not be empty</FormFeedback>
              ) : null}
            </div>

            <div className="mb-3">
              <Label className="form-label">
                Banking Communication Emails
                <span className="text-danger">&nbsp;*</span>
              </Label>

              <Row>
                <Col sm={11}>
                  <Input
                    name="email"
                    className="form-control form-control-email"
                    placeholder="Enter Email"
                    type="text"
                    onChange={(e) => setEmail(e.target.value)}
                    value={emailControl || ''}
                    ref={emailRef}
                  />
                </Col>

                <Col>
                  <div style={{ display: 'table', height: '100%' }}>
                    <div role="link" onClick={addEmail} style={{ display: 'table-cell', verticalAlign: 'middle' }} tabIndex="-1" className="text-primary">
                      <i className="uil-plus-circle bba-icon font-size-16" />
                    </div>
                  </div>
                </Col>
              </Row>

              {emails && Array.isArray(emails) && emails.map(
                (email, index) => (
                  <>
                    <Row>
                      <Col sm={email.value ? 11 : 11}>
                        <Input
                          name="email"
                          className="form-control form-control-email"
                          placeholder="Enter Email"
                          type="text"
                          onChange={(e) => handleEmailsChange(index, e.target.value)}
                          value={email.value || ''}
                          invalid={
                            !(email.valid)
                          }
                        />
                      </Col>
                      {email.value ? (
                        <Col>
                          <div style={{ display: 'table', height: '100%' }}>
                            <div onClick={() => deleteEmail(index)} role="link" style={{ display: 'table-cell', verticalAlign: 'middle' }} tabIndex="-1" className="text-danger">
                              <i className="uil-trash-alt bba-icon font-size-16" />
                            </div>
                          </div>
                        </Col>
                      )
                        : (
                          <Col>
                            <div style={{ display: 'table', height: '100%' }}>
                              <div role="link" style={{ display: 'table-cell', verticalAlign: 'middle' }} tabIndex="-1" className="text-primary">
                                <i className="uil-pen bba-icon font-size-16" />
                              </div>
                            </div>
                          </Col>
                        )}
                    </Row>
                    {email.isInvalidEmail ? (
                      <FormFeedback type="invalid">Please enter valid email</FormFeedback>
                    ) : null}
                  </>
                )
              )}

              {company && (
                <Row>
                  <Col md={3} sm={6}>
                    <Label className="form-label mt-2-px">Active</Label>
                    <span className="form-check form-switch form-switch-md switch-inline">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="customSwitchsizemd"
                        defaultChecked
                        checked={!!active}
                        onClick={() => {
                          setActive(!active);
                        }}
                      />
                    </span>
                  </Col>
                </Row>
              )}

            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => {
                  handleCloseModal();
                }}
              >
                Close
              </button>
              <button type="submit" className="btn btn-primary" onClick={addCompany}>
                Save
              </button>
            </div>

          </Form>
        </ModalBody>
      </Modal>
      {isErrorModalOpen && (
        <ErrorModal
          isModalOpen={isErrorModalOpen}
          {...errorModalDetail}
          closeModal={handleCloseErrorModal}
        />
      )}
    </>
  );
}

AddCompany.propTypes = {
  company: PropTypes.object,
  isModalOpen: PropTypes.bool,
  handleCloseModal: PropTypes.func
};

export default AddCompany;
