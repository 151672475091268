import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  setUserPermissions
} from './redux-slices/permission';
import CustomRoutes from './router/routes';
import './App.scss';
import StorageService from './services/StorageService';
import useUserAssignedPermissions from './custom-hooks/CustomHookUserAssignedPermissions';
import BbaSpinner from './components/Common/BbaSpinner';

function App() {
  const dispatch = useDispatch();
  const [isPermissionLoaded, setIsPermissionLoaded] = useState(false);
  const {
    getUserAssignedPermissionsInModulePermissions,
  } = useUserAssignedPermissions();

  useEffect(() => {
    getUserPermissions();
  }, []);

  const getUserPermissions = async () => {
    const userDetail = StorageService.getUserDetail();
    const { modulePermissionsResponse, userPermissionsMap } = await getUserAssignedPermissionsInModulePermissions(userDetail);
    dispatch(setUserPermissions({
      permissions: userPermissionsMap,
      modulePermissions: modulePermissionsResponse
    }));
    setIsPermissionLoaded(true);
    // console.log('userPermissions', userPermissionsMap);
    // userDetail.permissions = userPermissionsMap;
    // userDetail.modulePermissions = modulePermissionsResponse;
  };

  return (
    <div>
      {!isPermissionLoaded && (
        <div className="App">
          <BbaSpinner />
        </div>
      )}
      {isPermissionLoaded && <CustomRoutes />}
    </div>
  );
}

export default App;
