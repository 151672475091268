import { Col, Label, Row } from 'reactstrap';
import React from 'react';
import PropTypes from 'prop-types';
import { formatDate } from '../utils';

function FileDetailComponent({ file }) {
  return (
    <>
      {file && (
        <>
          <Row>
            <Col>
              <div className="mb-3">
                <Label className="form-label">File Number</Label>
                <div>
                  {file.file_no}
                </div>
              </div>
            </Col>
          </Row>

          {['TSR', 'Mortgage'].includes(file.login_file_type) && (
            <Row>
              <Col>
                <div className="mb-3">
                  <Label className="form-label">Bank</Label>
                  <div>
                    {file.bank_name}
                  </div>
                </div>
              </Col>
              <Col>
                <div className="mb-3">
                  <Label className="form-label">Bank Ref No.</Label>
                  <div>{file.bank_reference_no}</div>
                </div>
              </Col>
            </Row>
          )}

          {file.login_file_type === 'TSR' && (
            <>
              <Row>
                <Col>
                  <div className="mb-3">
                    <Label className="form-label">Property Description</Label>
                    <div>{file.property_description}</div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="mb-3">
                    <Label className="form-label">City</Label>
                    <div>{file.city_name}</div>
                  </div>
                </Col>
                <Col>
                  <div className="mb-3">
                    <Label className="form-label">Customer Name</Label>
                    <div>{file.customer_name}</div>
                  </div>
                </Col>
              </Row>
              <Row>
                {file.outsource_to && (
                  <Col>
                    <div className="mb-3">
                      <Label className="form-label">Outsource To</Label>
                      <div>{file.outsource_to}</div>
                    </div>
                  </Col>
                )}
              </Row>
            </>
          )}
          {file.login_file_type !== 'TSR' && (
            <>
              <Row>
                <Col>
                  <div className="mb-3">
                    <Label className="form-label">Actual Fees</Label>
                    <div>{file.actual_fees}</div>
                  </div>
                </Col>
                <Col>
                  <div className="mb-3">
                    <Label className="form-label">Received Fees</Label>
                    <div>{file.received_fees}</div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="mb-3">
                    <Label className="form-label">Loan Amount</Label>
                    <div>{file.loan_amount}</div>
                  </div>
                </Col>
                <Col>
                  <div className="mb-3">
                    <Label className="form-label">Appointment Date</Label>
                    <div>{file.appointment_date && formatDate(file.appointment_date)}</div>
                  </div>
                </Col>

              </Row>
              <Row>
                <Col>
                  <div className="mb-3">
                    <Label className="form-label">Sub Registrar Detail</Label>
                    <div>{file.sub_registrar_details}</div>
                  </div>
                </Col>
                <Col>
                  <div className="mb-3">
                    <Label className="form-label">Customer Name</Label>
                    <div>{file.general_file_customer_name}</div>
                  </div>
                </Col>
              </Row>
            </>
          )}
        </>
      )}
      {!file && <div>No file details</div>}
    </>
  );
}

FileDetailComponent.propTypes = {
  file: PropTypes.object
};

export default FileDetailComponent;
