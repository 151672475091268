/* eslint prefer-const: "off" */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Table, Tbody, Th, Thead, Tr, Td
} from 'react-super-responsive-table';
import FileService from '../services/FileService';
import ResendEmail from '../modal/ResendEmail';

import {
  downlodFile, extractErrorAndShowToast, formatDate
} from '../utils';
import BbaSpinner from './Common/BbaSpinner';

// const { REACT_APP_API_URL } = process.env;

function DocumentsComponent({ file }) {
  const [fileDocuments, setFileDocuments] = useState([]);
  const [isLoading, setIsLoading] = useState([]);
  const [emailDetails, setEmailDetails] = useState(null);
  const [isResendMailModalOpen, setIsResendMailModalOpen] = useState(false);
  // const [s3Url, setS3Url] = useState(false);
  // const [pageCount, setPageCount] = useState(1);

  useEffect(() => {
    getDocuments();
  }, []);

  const getDocuments = async () => {
    try {
      const fileDocumentsResponse = await FileService.getTSRVRDocument(1, 1000, file.uuid);
      const fileDocumentsResponseData = fileDocumentsResponse.data;
      const {
        data,
        // s3BucketUrl
      } = fileDocumentsResponseData;
      console.log('fileDocumentsResponseData', fileDocumentsResponseData);
      // setUsers(userResponseData.users);
      // setS3Url(s3BucketUrl);
      setFileDocuments(data);
      // setPageCount(totalPages);
      setIsLoading(false);
    } catch (e) {
      console.log('e', e);
      extractErrorAndShowToast(e);
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    }
  };

  // const resendDocumentEmail = async (fileDocument) => {
  //   try {
  //     const obj = {
  //       loginFileId: file.uuid,
  //       action: 'document',
  //       type: fileDocument.type,
  //       fileName: fileDocument.file_name
  //     };
  //     await FileService.resendEmail(obj);
  //     showSuccessToast('Mail sent');
  //   } catch (e) {
  //     console.log('resend mail failed');
  //     extractErrorAndShowToast(e);
  //   }
  // };

  const openResendMailModalOpen = (fileDocument) => {
    setIsResendMailModalOpen(true);
    setEmailDetails({
      action: 'document',
      type: fileDocument.type,
      fileName: fileDocument.file_name
    });
  };

  const closeResendMailModalOpen = () => {
    setIsResendMailModalOpen(false);
    setEmailDetails(null);
  };

  const getSignedUrlAndDownloadFile = async (fileDocument) => {
    try {
      setIsLoading(true);

      const response = await FileService.getSignedUrl({
        fileName: fileDocument.file_name
      });
      setIsLoading(false);
      const responseData = response.data;
      const { url } = responseData;
      downlodFile(url);
    } catch (e) {
      console.log('e', e);
      extractErrorAndShowToast(e);
      setIsLoading(false);
    }
  };

  return (
    <div>
      {isLoading && <BbaSpinner />}
      <Table
        className="table table-striped"
      >
        <Thead>
          <Tr>
            <Th>Type</Th>
            <Th>Created Date</Th>
            <Th style={{ width: '120px' }}>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {fileDocuments.length > 0 && fileDocuments.map((fileDocument) => (
            <Tr>
              <Td>
                {fileDocument.type}
              </Td>
              <Td>
                {formatDate(fileDocument.created_at)}
              </Td>
              <Td>
                <span onClick={() => getSignedUrlAndDownloadFile(fileDocument)} role="button" tabIndex="-1" className="text-primary">
                  <i className="uil uil-cloud-download font-size-20" />
                </span>
                &nbsp;&nbsp;
                {file.login_file_type === 'TSR' && (
                  <span onClick={() => openResendMailModalOpen(fileDocument)} role="button" title="Resend Document" tabIndex="-1" className="text-primary">
                    <i className="uil uil-envelope font-size-20" />
                  </span>
                )}
              </Td>
            </Tr>
          ))}
          {
            fileDocuments.length === 0 && (
              <Tr>
                <Td colSpan="7" className="text-center">No Documents found</Td>
              </Tr>
            )
          }
        </Tbody>
      </Table>
      {isResendMailModalOpen && (
        <ResendEmail
          file={file}
          emailDetails={emailDetails}
          isModalOpen={isResendMailModalOpen}
          handleCloseModal={closeResendMailModalOpen}
        />
      )}
      {/* {fileDocuments.map((f) => <div onClick={() => downlodFile(`${REACT_APP_API_URL}/${f.file_name}`)} role="button" tabIndex={-1}>{f.file_name}</div>)} */}
    </div>
  );
}

DocumentsComponent.propTypes = {
  file: PropTypes.object
};

export default DocumentsComponent;
