module.exports = {
  // json web token errors
  errInvalidToken: 'Invalid Token',
  errTokenExpired: 'Token is expired',
  errInactiveJWT: 'Token is expired',
  // user/authentication errors
  errInvalidEmailOrPassword: 'Invalid Email or Password',
  errEmailNotExist: 'Email does not exist',
  errEmptyEmailOrPassword: 'Email or password is empty',
  errEmptyEmail: 'Email is empty',
  errEmptyPassword: 'Password is required',

  errRequiredPassword: 'Password is required',
  errInvalidEmail: 'Email is invalid',
  errMin7Password: 'Password length should be minumum 7 Character',
  errIncorrectPassword: 'Password is incorrect',
  // role errors
  errDuplicateRoleName: 'Role already exist',
  errDuplicateRoleCode: 'Role Code already exist',
  errEmptyRoleCode: 'Role is empty',
  errEmptyRoleName: 'Role is empty',
  // bank errors
  errDuplicateBank: 'Bank already exist',
  errDuplicateUser: 'User already exist',
  errEmptyBankName: 'Bank name is empty',
  // reset credentials
  errEmptyPasswordOrConfirmPassword: 'Password or comfirm password is empty',
  errNotMatchPasswordOrConfirmPassword: 'Password is not matching',
  // Misc
  errEmailTemplateNotFound: 'Email template doest not exist',
  errBankConfigurationInActive: 'Bank configuration is inactive',
  errDuplicateBankConfiguration: 'Bank configuration already exist',
  errBadRequest: 'Bad Request',
  errUnauthorizedAccess: 'Unauthorized access',
  errPaymentRequired: 'Payment Required',
  errForbidden: 'You are not authorize to perform this action',
  errNotFound: 'Resource not found',
  errConflict: 'Conflict',
  errPayloadTooLarge: 'Payload too large',
  errUnsupportedMediaTypes: 'Unsupported media type',
  errUnableToProcess: 'Unable to process',
  errTooManyRequests: 'Too many requests',
  errInternalServer: 'Internal server error',
  errServiceUnavailable: 'Service Unavailable',
  errInvalidCredentials: 'Invalid Email or Password ',
  errIncorrectOldPassword: 'Old password is incorrect',
  errProhibitedToken: 'Token is expired',
  errDuplicateBranch: 'Branch is already exist',
  errDuplicateCity: 'City is already exist',
  errDuplicateEmail: 'User with same email is already exist',
  errRequiredEmail: 'Email is Required'
};
