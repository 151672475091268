import get from 'lodash/get';
import AlertService from './services/AlertService';
import MsgConstant from './constants/msg.constant';

const monthObj = {
  1: 'Jan',
  2: 'Feb',
  3: 'Mar',
  4: 'Apr',
  5: 'May',
  6: 'Jun',
  7: 'Jul',
  8: 'Aug',
  9: 'Sep',
  10: 'Oct',
  11: 'Nov',
  12: 'Dec',
};

export function formatDate(dateParam) {
  if (dateParam) {
    const date = new Date(dateParam);
    console.log('date', date);
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear().toString();
    year = getYearString(year);
    if (day < 10) {
      day = `0${day}`;
    }
    month = monthObj[month];
    return `${day} ${month}${year}`;
  }
  return '';
}
function getYearString(yearParam) {
  let year = yearParam;
  if (!year) {
    year = new Date().getFullYear().toString();
  }
  return `, ${year}`;
}

export function extractErrorAndShowToast(e) {
  console.log('e', e);
  // const errorCode = get(e, 'response.data.code');
  const errorMessage = get(e, 'response.data.message');
  console.log('errorMessage', errorMessage);
  const message = MsgConstant[errorMessage] || errorMessage;
  AlertService.showToast(message, 'error');
}

export function showSuccessToast(msg) {
  AlertService.showToast(msg, 'success');
}

export function downlodFile(url) {
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'sample.xlsx');
  link.setAttribute('target', '_blank');
  // 3. Append to html page
  document.body.appendChild(link);
  // 4. Force download
  link.click();
  // 5. Clean up and remove the link
  link.parentNode.removeChild(link);
}

export function titleCase(str) {
  let text = '';
  if (str) {
    text = str.toLowerCase().split(' ');
    for (let i = 0; i < text.length; i++) {
      text[i] = text[i].charAt(0).toUpperCase() + text[i].slice(1);
    }
  }
  return text.join(' ');
}

export function getCurrentFinancialYear() {
  const today = new Date();
  const currentMonth = today.getMonth();
  const currentYear = today.getFullYear();
  const financialYearStartMonth = 3; // April
  const startYear = currentMonth >= financialYearStartMonth ? currentYear : currentYear - 1;
  const endYear = startYear + 1;
  const label = `${startYear}-${endYear.toString().slice(2)}`;
  return label;
}

export const getFinancialYears = () => {
  let currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;
  const baseYear = 2024; // 2023-24
  if (currentMonth > 3) {
    currentYear += 1;
  }
  const lengthOfYear = currentYear - baseYear + 1;
  const years = Array.from({ length: lengthOfYear }, (_, index) => currentYear - index);

  const fyYears = years.map((yearOption) => {
    const startYear = yearOption - 1;
    const endYear = yearOption;
    const label = `${startYear}-${endYear.toString().slice(2)}`;
    return { label, value: label };
  });
  return fyYears;
};
