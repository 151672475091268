/* eslint prefer-const: "off" */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Modal, ModalHeader, ModalBody, Form, Label, Input, FormFeedback
} from 'reactstrap';

import CityService from '../services/CityService';
import ValidationService from '../services/Validation';
import { extractErrorAndShowToast } from '../utils';

import ErrorModal from './ErrorModal';

import {
  BANK_NAME_MAX_LENGTH
} from '../constants/validation.constant';
import AlertService from '../services/AlertService';

const control = {
  value: '',
  valid: true
};

function AddCityModal({ city, isModalOpen, handleCloseModal }) {
  let [name, setName] = useState({ ...control });
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorModalDetail, setErrorModalDetail] = useState(null);

  useEffect(() => {
    if (city) {
      setName({ ...control, value: city.name });
    }
  }, []);

  const handleValidation = () => {
    name = ValidationService.isEmpty(name);
    name = ValidationService.lengthValidation(name, BANK_NAME_MAX_LENGTH);
    if (name.isEmpty || name.isInvalidLength) {
      name.valid = false;
    } else {
      name.valid = true;
    }

    setName({ ...name });

    if (
      name.valid
    ) {
      return true;
    }
    return false;
  };

  const addCity = async () => {
    try {
      const isFormValid = handleValidation();
      if (!isFormValid) {
        return;
      }
      const cityObj = {
        name: name.value,
      };
      console.log('cityObj', cityObj);
      if (city && city.uuid) {
        cityObj.id = city.uuid;
        await CityService.updateCity(cityObj);
      } else {
        await CityService.addCity(cityObj);
      }
      // const infoObj = {
      //   title: 'Bank',
      //   message: `${bank.uuid ? 'Bank info updated successfully' : 'Bank info added successfully'}`,
      // };
      AlertService.showToast(`${city && city.uuid ? 'City updated successfully' : 'City added successfully'}`, 'sucess');
      // openSweetAlert('Bank', `${city.uuid ? 'Bank info updated successfully' : 'Bank info added successfully'}`, 'sucess');
      // setIsInfoModalOpen(true);
      // setInfoModalDetail(infoObj);
      handleCloseModal(true);
    } catch (e) {
      console.log('update failed');
      // const errorObj = {
      //   title: 'Bank',
      //   message: 'Something went wrong!!',
      // };
      extractErrorAndShowToast(e);
      // openSweetAlert('Bank', 'Something went wrong', 'error');
      // setErrorModalDetail(errorObj);
      // setIsErrorModalOpen(true);
    }
  };

  const handleCloseErrorModal = () => {
    setIsErrorModalOpen(false);
    setErrorModalDetail(null);
  };
  // const openSweetAlert = (title, message, state = 'success') => {
  //   let icon = 'info';
  //   if (state === 'error') {
  //     icon = 'error';
  //   }
  //   Swal.fire({
  //     title,
  //     icon,
  //     text: message,
  //     showCloseButton: true,
  //     confirmButtonText:
  //       'Ok',
  //     confirmButtonAriaLabel: 'Ok',
  //   });
  // };

  return (
    <>
      <Modal isOpen={isModalOpen}>
        <ModalHeader tag="h4">
          {city && city.uuid ? 'Edit City Detail' : 'Add City'}
          {/* {!!isEdit ? "Edit Order" : "Add Order"} */}
        </ModalHeader>
        <ModalBody>
          <Form
            className="form-horizontal"
            onSubmit={(e) => {
              e.preventDefault();
              // validation.handleSubmit();

              return false;
            }}
          >
            {/* {error ? <Alert color="danger">{error}</Alert> : null} */}

            <div className="mb-3">
              <Label className="form-label">City Name</Label>
              <Input
                name="name"
                className="form-control"
                placeholder="Enter City Name"
                type="text"
                onChange={(e) => setName({ ...name, value: e.target.value })}
                value={name.value || ''}
                invalid={
                  !(name.valid)
                }
              />
              {name.isEmpty ? (
                <FormFeedback type="invalid">city name should not be empty</FormFeedback>
              ) : null}
              {name.isInvalidLength ? (
                <FormFeedback type="invalid">
                  city name should be more then
                  {BANK_NAME_MAX_LENGTH}
                  {' '}
                  characters
                </FormFeedback>
              ) : null}
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => {
                  handleCloseModal();
                }}
              >
                Close
              </button>
              <button type="submit" className="btn btn-primary" onClick={addCity}>
                Save
              </button>
            </div>

          </Form>
        </ModalBody>
      </Modal>
      {isErrorModalOpen && (
        <ErrorModal
          isModalOpen={isErrorModalOpen}
          {...errorModalDetail}
          closeModal={handleCloseErrorModal}
        />
      )}
    </>
  );
}

AddCityModal.propTypes = {
  city: PropTypes.object,
  isModalOpen: PropTypes.bool,
  handleCloseModal: PropTypes.func
};

export default AddCityModal;
