/* eslint no-useless-escape: "off" */
/* eslint no-param-reassign: "off" */

export default class Validation {
  static emailValidator1(email) {
    const pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (email) {
      if (email.match(pattern)) {
        return null;
      }
      return { error: true, errorMsg: 'Email is not valid' };
    }
    return { error: true, errorMsg: 'Email is not valid' };
    // return resolve(null);
  }

  static emailValidator(controlParam) {
    const control = controlParam;
    const pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (control.value === null || control.value === '') {
      control.invalidEmail = false;
      return control;
    }
    if (control.value) {
      if (control.value.match(pattern)) {
        control.invalidEmail = false;
        return control;
      }
      control.invalidEmail = true;
      return control;
    }
    control.invalidEmail = true;
    return control;
    // return resolve(null);
  }

  /**
     * @description password validator
     * @param control
     */
  static numberValidatior(number) {
    const pattern = /(^[0-9]+\.([0-9])+|^[0-9]+)$/;
    if (number) {
      const number1 = number ? number.trim() : '';
      if (number1.length === 0) {
        return { error: true, errorMsg: 'Value should not be empty' };
      } if (number === 0) {
        return { error: true, errorMsg: 'Value should not 0' };
      } if (!pattern.test(number)) {
        return { error: true, errorMsg: 'Value you entered is incorrect' };
      }
      return null;
    }
    return { error: true, errorMsg: 'Value should not be empty' };
  }

  static notNullValidator(controlParam) {
    const control = controlParam;

    if (control.value === null || control.value === '' || control.value === undefined) {
      control.nullValue = true;
      return control;
    }
    control.nullValue = false;
    return control;
  }

  static validatePhoneNumber(controlParam) {
    const control = controlParam;

    const regex = /^[0-9]*$/;
    const { value } = control;
    if (!(value === null || value === '')) {
      if (value.length !== 10) {
        control.invalidPhone = true;
        return control;
      }
      if (!value.match(regex)) {
        control.invalidPhone = true;
      } else {
        control.invalidPhone = null;
      }
    } else {
      control.invalidPhone = null;
    }
    return control;
  }

  static isEmpty = (control) => {
    control.isEmpty = true;
    if (control && control.value) {
      const controlValue = control.value.toString().trim();
      if (controlValue) {
        control.isEmpty = false;
      }
    }
    return control;
  };

  static lengthValidation = (control, maxLength) => {
    control.isInvalidLength = false;
    if (control && control.value) {
      const controlValue = control.value.toString().trim();
      if (controlValue.length > maxLength) {
        control.isInvalidLength = true;
      }
    }
    return control;
  };

  static isInvalidEmail = (control) => {
    const validEmail = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,5}$/); // eslint-disable-line
    control.isInvalidEmail = false;
    if (control.value && !validEmail.test(control.value)) {
      control.isInvalidEmail = true;
    } else {
      control.isInvalidEmail = false;
    }
    return control;
  };

  static isNegativeNumber = (control) => {
    control.isNegativeNumber = false;
    if (control && control.value) {
      const controlValue = control.value.toString().trim();
      if (Number.isNaN(+controlValue) || +controlValue < 0) {
        control.isNegativeNumber = true;
      }
    }
    return control;
  };

  static isInValidColor = (control) => {
    control.isInValidColor = false;
    if (control && control.value) {
      const controlValue = control.value.toString().trim();
      if (!(controlValue.length === 7 && controlValue[0] === '#')) {
        control.isInValidColor = true;
      }
    }
    return control;
  };
}
