import React from 'react';
import { Spinner } from 'reactstrap';

function BbaSpinner() {
  return (
    <div className="spinner-container">
      <Spinner className="m-1 spinner" color="primary" />
    </div>
  );
}

export default BbaSpinner;
