import { request } from './request';
import { routes } from '../constants/constant.routes';

const { API_URL } = routes;

export default class RoleService {
  static getRoles() {
    return request('GET', `${API_URL}${routes.GET_ROLES}?page=1&limit=100`, null, null, null);
  }
}
