/* eslint prefer-const: "off" */

// import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  Row, Col, Card, CardBody, Container, FormFeedback, Input, Label, Form,
} from 'reactstrap';

// Redux
import { Link } from 'react-router-dom';

import ValidationService from '../../services/Validation';

// import images
import logo from '../../assets/images/logo-dark.png';
import logolight from '../../assets/images/logo-light.png';
import UserService from '../../services/UserService';
import AlertService from '../../services/AlertService';
import { extractErrorAndShowToast } from '../../utils';
// import AuthService from '../../services/AuthService';

const control = {
  value: '',
  valid: true
};

function ForgetPasswordPage() {
  // const dispatch = useDispatch();
  let [email, setEmail] = useState({ ...control });

  const handleValidation = () => {
    email = ValidationService.isEmpty(email);
    email = ValidationService.isInvalidEmail(email);
    // email = ValidationService.lengthValidation(email, FIRST_NAME_MAX_LENGTH);
    if (email.isEmpty || email.isInvalidEmail) {
      email.valid = false;
    } else {
      email.valid = true;
    }

    setEmail({ ...email });

    if (
      email.valid
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    document.body.className = 'authentication-bg';
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = '';
    };
  });

  const resetPassword = async (e) => {
    try {
      e.preventDefault();
      const isFormValid = handleValidation();
      if (!isFormValid) {
        return;
      }
      console.log('isFormValid', isFormValid);
      await UserService.forgotPassword(email.value);
      AlertService.showToast('Password reset link has been sent on mail. Please click on link and reset password');
    } catch (err) {
      extractErrorAndShowToast(err);
    }
  };

  return (
    <div className="account-pages my-5  pt-sm-5">
      <Container>
        <Row>
          <Col lg={12}>
            <div className="text-center">
              <Link to="/" className="mb-5 d-block auth-logo">
                <img src={logo} alt="" height="40" className="logo logo-dark" />
                <img src={logolight} alt="" height="40" className="logo logo-light" />
              </Link>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center">

          <Col md={8} lg={6} xl={5}>
            <div>
              <Card>
                <CardBody className="p-4">
                  <div className="text-center mt-2">
                    <h5 className="text-primary">Reset Password</h5>
                    {/* <p className="text-muted">Reset Password with Minible.</p> */}
                  </div>
                  <div className="p-2 mt-4">
                    <div className="alert alert-success text-center mb-4" role="alert">
                      Enter your Email and instructions will be sent to you!
                    </div>

                    <Form
                      className="form-horizontal"
                      onSubmit={resetPassword}
                    >
                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={(e) => setEmail({ ...email, value: e.target.value })}
                          value={email.value || ''}
                          invalid={
                            !(email.valid)
                          }
                        />
                        {email && email.isEmpty ? (
                          <FormFeedback type="invalid">Please enter email</FormFeedback>
                        ) : null}
                        {email && email.isInvalidEmail ? (
                          <FormFeedback type="invalid">Please enter valid email</FormFeedback>
                        ) : null}
                      </div>
                      <Row className="mb-0">
                        <Col xs={12} className="text-end">
                          <button
                            className="btn btn-primary w-md waves-effect waves-light"
                            type="submit"
                            formNoValidate
                          >
                            Reset
                          </button>
                        </Col>
                      </Row>
                      <div className="mt-4 text-center">
                        <p className="mb-0">
                          Remember It ?
                          <Link to="/" className="fw-medium text-primary"> Signin </Link>
                        </p>
                      </div>
                    </Form>
                  </div>

                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  ©
                  {' '}
                  {new Date().getFullYear()}
                  {' '}
                  B&B Associates
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

ForgetPasswordPage.propTypes = {
  // forgetError: PropTypes.any,
  // forgetSuccessMsg: PropTypes.any,
  // history: PropTypes.object,
  // userForgetPassword: PropTypes.func,
};

export default ForgetPasswordPage;
