/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import {
  layoutTypes,
  layoutModeTypes,
  layoutWidthTypes,
  topBarThemeTypes,
  sidebarSizeTypes,
  leftSideBarThemeTypes,
} from '../constants/layout';

const INIT_STATE = {
  layoutType: layoutTypes.HORIZONTAL,
  layoutModeType: layoutModeTypes.LIGHT,
  layoutWidth: layoutWidthTypes.FLUID,
  leftSideBarTheme: leftSideBarThemeTypes.LIGHT,
  topbarTheme: topBarThemeTypes.LIGHT,
  sidebarSizeType: sidebarSizeTypes.DEFAULT,
  isPreloader: false,
  showRightSidebar: false,
  isMobile: false,
  showSidebar: true,
  leftMenu: false,
};

export const layoutSlice = createSlice({
  name: 'header',
  initialState: {
    ...INIT_STATE,
  },
  reducers: {
    changeLayout: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.layoutType = action.payload;
    },
    changeLayoutMode: (state, action) => {
      state.layoutModeType = action.payload;
    },
    changePreloader: (state, action) => {
      state.isPreloader = action.payload;
    },
    changeLayoutWidth: (state, action) => {
      state.layoutWidth = action.payload;
    },
    changeSidebarTheme: (state, action) => {
      state.leftSideBarTheme = action.payload;
    },
    changeTopbarTheme: (state, action) => {
      state.topbarTheme = action.payload;
    },
    changeSidebarSize: (state, action) => {
      state.sidebarSizeType = action.payload;
    },
    showRightSidebarAction: (state, action) => {
      state.showRightSidebar = action.payload;
    },
    showSidebar: (state, action) => {
      state.showSidebar = action.payload;
    },
    toggleLeftmenu: (state, action) => {
      state.leftMenu = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  changeLayout,
  changeLayoutMode,
  changePreloader,
  changeLayoutWidth,
  changeSidebarTheme,
  changeTopbarTheme,
  changeSidebarSize,
  toggleLeftmenu,
  showSidebar,
  showRightSidebarAction,
} = layoutSlice.actions;

export default layoutSlice.reducer;
