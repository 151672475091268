/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import auth from '../constants/auth';

const INIT_STATE = {
  isAuthenticated: auth.IS_AUTHENICATED,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    ...INIT_STATE,
  },
  reducers: {
    setIsAuthenticated: (state, action) => {
      console.log('action', action);
      state.isAuthenticated = action.payload;
    }
  },
});

// Action creators are generated for each case reducer function
export const {
  setIsAuthenticated
} = authSlice.actions;

export default authSlice.reducer;
