import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

// i18n
import SidebarContent from './SidebarContent';

import logoSm from '../../assets/images/bba-logo.png';
import logoBg from '../../assets/images/bba-long-header.png';
import logoLight from '../../assets/images/logo-light.png';

function Sidebar({ type, toggleMenuCallback }) {
  function tToggle() {
    const { body } = document;
    body.classList.toggle('vertical-collpsed');
    body.classList.toggle('sidebar-enable');
    console.log('type', type);
    toggleMenuCallback();
  }

  return (
    <div className="vertical-menu">
      <div className="navbar-brand-box">
        <Link to="/" className="logo logo-dark">
          <span className="logo-sm">
            <img src={logoSm} alt="" className="log-small" />
          </span>
          <span className="logo-lg">
            <img src={logoBg} alt="" className="log-big" />
          </span>
        </Link>

        <Link to="/" className="logo logo-light">
          <span className="logo-sm">
            <img src={logoSm} alt="" height="38" />
          </span>
          <span className="logo-lg">
            <img src={logoLight} alt="" height="36" />
          </span>
        </Link>
      </div>
      <button
        onClick={() => {
          tToggle();
        }}
        type="button"
        className="btn btn-sm px-3 font-size-16 header-item waves-effect vertical-menu-btn"
      >
        <i className="fa fa-fw fa-bars" />
      </button>
      <div className="sidebar-menu-scroll">
        {type !== 'condensed' ? <SidebarContent /> : <SidebarContent />}
      </div>
    </div>
  );
}

Sidebar.propTypes = {
  type: PropTypes.string,
  toggleMenuCallback: PropTypes.func
};

// const mapStatetoProps = (state) => ({
//   layout: state.Layout,
// });
export default Sidebar;
