import React from 'react';
import PropTypes from 'prop-types';

import { Modal } from 'reactstrap';

function ErrorModal({
  isModalOpen, title, message, closeModal,
}) {
  return (
    <Modal
      isOpen={isModalOpen}
      toggle={() => closeModal()}
      backdrop
      scrollable
      id="staticBackdrop"
    >
      <div className="modal-header">
        <h5
          className="modal-title"
          id="staticBackdropLabel"
        >
          {title}
        </h5>
        <button
          type="button"
          className="btn-close"
          onClick={() => {
            closeModal(false);
          }}
          aria-label="Close"
        />
      </div>
      <div className="modal-body">
        <p>
          {message}
        </p>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-light"
          onClick={() => {
            closeModal(false);
          }}
        >
          Close
        </button>
      </div>
    </Modal>
  );
}

ErrorModal.propTypes = {
  message: PropTypes.string,
  isModalOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  title: PropTypes.string
};

export default ErrorModal;
