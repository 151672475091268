import { request } from './request';
import { routes } from '../constants/constant.routes';

const { API_URL } = routes;

export default class UserService {
  static addUser(userObj) {
    return request('POST', `${API_URL}${routes.ADD_USER}`, null, userObj, null);
  }

  static updateUser(userObj) {
    return request('PUT', `${API_URL}${routes.UPDATE_USER}`, null, userObj, null);
  }

  static getUsers(page = 1, limit = 10, search = null, role = '', active = '') {
    let qString = '';
    if (page) {
      qString += `page=${(page).toString()}&`;
    }
    if (limit) {
      qString += `limit=${limit.toString()}&`;
    }
    if (search) {
      qString += `search=${search}&`;
    }
    if (role) {
      qString += `role=${role}&`;
    }
    if (active) {
      qString += `active=${active}&`;
    }
    return request('GET', `${API_URL}${routes.GET_USERS}?${qString}`, null, null, null);
  }

  static getUserDetail(userId) {
    return request('GET', `${API_URL}${routes.GET_USER_DETAIL}?userId=${userId}`, null, null, null);
  }

  static setPassword(userObj, token) {
    const headers = {
      'grant-type': 'reset_password'
    };
    return request('POST', `${API_URL}${routes.USER_RESET_CREDENTIALS}`, null, userObj, headers, token);
  }

  static forgotPassword(email) {
    return request('GET', `${API_URL}${routes.FORGOT_PASSWORD}?email=${email}`, null, null, null);
  }

  static changePassword(userObj, token) {
    return request('POST', `${API_URL}${routes.CHANGE_PASSWORD}`, null, userObj, null, token);
  }

  static resendVerificationEmail(userId) {
    return request('GET', `${API_URL}${routes.RESEND_VERIFICATION_EMAIL}?userId=${userId}`, null, null, null, null);
  }
}
