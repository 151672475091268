import swal from 'sweetalert2';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

export default class AlertService {
  static openAlert(title, text, isError = false) {
    swal.fire({
      icon: isError ? 'error' : 'success',
      title,
      text,
    });
  }

  /**
   * @description function open toast message
   * @param {*} msg
   * @param {*} type info/warning/error/success
   */
  static showToast(msg, type) {
    if (type === 'error') {
      toastr.error(msg);
    } else if (type === 'warning') {
      toastr.warning(msg);
    } else if (type === 'info') {
      toastr.info(msg);
    } else {
      toastr.success(msg);
    }
  }
}
