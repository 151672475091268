import React from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  setIsAuthenticated
} from '../redux-slices/auth';

import StorageService from '../services/StorageService';

function Protected({ route = {}, isProtected, children }) {
  const userPermissions = useSelector((state) => state.permission.userPermissions || {});
  const { permissions = [], path } = route;
  const token = StorageService.getToken();
  // const userDetail = StorageService.getUserDetail();
  const dispatch = useDispatch();

  if (!token) {
    console.log('calling auth reducer');
    dispatch(setIsAuthenticated(false));
  } else {
    dispatch(setIsAuthenticated(true));
  }

  if (userPermissions) {
    const { permissions: userPermissionsObj = {} } = userPermissions;
    if (permissions.length > 0) {
      let permissionGranted = false;
      for (const permission of permissions) {
        if (userPermissionsObj[permission]) {
          permissionGranted = true;
          break;
        }
      }
      // console.log('userDetail?.role', userDetail?.role
      if (!permissionGranted) {
        return <Navigate to="/unauthorized" replace />;
      }
    }
  }

  if (token && path === '/') {
    return <Navigate to="/file" replace />;
  }
  if (isProtected && !token) {
    return <Navigate to="/" replace />;
  }

  return children;
}

Protected.propTypes = {
  route: PropTypes.object,
  children: PropTypes.element,
  isProtected: PropTypes.bool
};

export default Protected;
