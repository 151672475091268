import axios from 'axios';
import qs from 'qs';
import Storage from './StorageService';
/**
 * request interceptors
 * @param {String} method GET,PUT,POST,DELETE
 * @param {String} url req url
 * @param {Object} params query parameters
 * @param {Object} body req body
 * @param {Object} headers req headers
 */
export const request = async (method, url, paramsObj, bodyParam = {}, headersParam = {}, authToken = null) => {
  const headers = headersParam || {};
  const params = paramsObj || {};
  const body = bodyParam || {};
  const companyDetail = Storage.getCompanyDetail();
  let companyId = null;
  if (companyDetail) {
    companyId = companyDetail.uuid;
    headers['company-id'] = companyId;
  }
  if (!headers['content-type']) {
    headers['content-type'] = 'application/json';
  }
  if (!(url === 'auth' || url === 'signin' || url === 'signup' || url === 'forgot-password')) {
    // in case of set password token will be different
    if (authToken) {
      headers.Authorization = `Bearer ${authToken}`;
    } else {
      const token = Storage.getToken();
      headers.Authorization = `Bearer ${token}`;
    }
    // headers.Authorization = 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1X3V1aWQiOiJkNjhiYjU3Zi0zOGY0LTQxOGUtYmIzNy0yMzJmOGQzMDg3MDciLCJmaXJzdF9uYW1lIjoibmlrdW5qIiwibGFzdF9uYW1lIjoiUHJhIiwiZW1haWwiOiJuaWt1bmpAbWFpbGluYXRvci5jb20iLCJmYklkIjpudWxsLCJpZCI6NCwidXNlclR5cGUiOjIsImlhdCI6MTUzMTg5NjMzNH0.g2cvqz_CeBWfaBkAwQwe5B-wy-iM2QlTruF27APva6Q';
  }
  const options = {
    method,
    headers,
    params,
    url,
  };

  if ((method === 'POST' || method === 'PUT') && headers['content-type'] === 'application/x-www-form-urlencoded') {
    options.data = qs.stringify(body);
  } else if ((method === 'POST' || method === 'PUT')) {
    options.data = body;
  }

  try {
    const response = await axios(options);
    return response.data;
  } catch (e) {
    const logoutCodes = ['errProhibitedToken', 'errUnauthorizedAccess', 'errInvalidToken', 'errTokenExpired'];
    if (e && e.response && e.response.data && logoutCodes.includes(e.response.data.code)) {
      logout();
      window.location.reload();
      throw e;
    }
    throw e;
  }
};

export const csvUploadRequest = (url, file) => {
  const formData = new FormData();
  formData.append('csv', file, file.name);
  return axios.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${Storage.getToken()}`,
    },
  });
};

const logout = () => {
  Storage.removeToken();
  Storage.removeUserDetail();
};
