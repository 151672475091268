/* eslint prefer-const: "off" */

import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal, ModalHeader, ModalBody
} from 'reactstrap';

const verticalTimelineData = [
  {
    id: 1,
    date: '03 May',
    title: 'Timeline event One',
    description:
      'If several languages coalesce, the grammar of the resulting the individual',
  },
  {
    id: 2,
    date: '08 May',
    title: 'Timeline event Two',
    description:
      'Sed ut perspiciatis unde omnis iste natus error sit illo inventore veritatis',
  },
  {
    id: 3,
    date: '11 May',
    title: 'Timeline event Three',
    description:
      'Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit',
  },
  {
    id: 4,
    date: '16 May',
    title: 'Timeline event Four',
    description:
      'Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam',
  },
  {
    id: 5,
    date: '23 May',
    title: 'Timeline event Five',
    description:
      'Itaque earum rerum hic tenetur a sapiente delectus maiores alias consequatur aut',
  },
];

function FileHistory({ isModalOpen, closeModal }) {
  return (
    <Modal isOpen={isModalOpen}>
      <ModalHeader tag="h4">
        File History
        {/* {!!isEdit ? "Edit Order" : "Add Order"} */}
      </ModalHeader>
      <ModalBody>
        <div className="">
          <ul className="verti-timeline list-unstyled">
            {/* Render Horizontal Timeline Events */}
            {verticalTimelineData.map((status) => (
              <li key={status} className="event-list">
                <div className="event-date text-primar">{status.date}</div>
                <h5>{status.title}</h5>
                <p className="text-muted">{status.description}</p>
              </li>
            ))}
          </ul>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-light"
            onClick={() => {
              closeModal();
            }}
          >
            Close
          </button>
          {/* <button type="submit" className="btn btn-primary" onClick={addUser}>
                Save
              </button> */}
        </div>
      </ModalBody>
    </Modal>
  );
}

FileHistory.propTypes = {
  isModalOpen: PropTypes.bool,
  closeModal: PropTypes.func
};

export default FileHistory;
