/* eslint prefer-const: "off" */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Modal, ModalHeader, ModalBody, Form, Label, Input, FormFeedback
} from 'reactstrap';

import FileService from '../services/FileService';
import ValidationService from '../services/Validation';
import AlertService from '../services/AlertService';

import ErrorModal from './ErrorModal';

import BbaSpinner from '../components/Common/BbaSpinner';

import { extractErrorAndShowToast } from '../utils';

const control = {
  value: '',
  valid: true
};

function AddQuery({ file, isModalOpen, closeModal }) {
  let [query, setQuery] = useState({ ...control });
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorModalDetail, setErrorModalDetail] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (file) {
      setQuery({ ...control, value: file.handover_name });
    }
  }, []);

  const handleValidation = () => {
    query = ValidationService.isEmpty(query);
    if (query.isEmpty || query.isInvalidLength) {
      query.valid = false;
    } else {
      query.valid = true;
    }

    setQuery({ ...query });

    if (
      query.valid
    ) {
      return true;
    }
    return false;
  };

  const addQuery = async () => {
    try {
      const isFormValid = handleValidation();
      if (!isFormValid) {
        return;
      }
      const fileQueryObj = {
        query: query.value,
        loginFileId: file.uuid
      };
      console.log('fileQueryObj', fileQueryObj);
      setIsLoading(true);

      await FileService.addFileQuery(fileQueryObj);
      AlertService.showToast('Query added successfully', 'success');
      setIsLoading(false);
      closeModal(true);
    } catch (e) {
      console.log('update failed');
      // const errorObj = {
      //   title: 'Bank',
      //   message: 'Something went wrong!!',
      // };
      setIsLoading(false);
      extractErrorAndShowToast(e);
    }
  };

  const handleCloseErrorModal = () => {
    setIsErrorModalOpen(false);
    setErrorModalDetail(null);
  };

  return (
    <>
      <Modal isOpen={isModalOpen}>
        <ModalHeader tag="h4">
          Raise Query
          {/* {!!isEdit ? "Edit Order" : "Add Order"} */}
        </ModalHeader>
        <ModalBody>
          {isLoading && <BbaSpinner />}
          <Form
            className="form-horizontal"
            onSubmit={(e) => {
              e.preventDefault();
              // validation.handleSubmit();

              return false;
            }}
          >
            {/* {error ? <Alert color="danger">{error}</Alert> : null} */}
            <div className="mb-3">
              <Label className="form-label">
                Query
                <span className="text-danger">&nbsp;*</span>
              </Label>
              <Input
                name="name"
                className="form-control"
                placeholder="Enter Query"
                type="textarea"
                onChange={(e) => setQuery({ ...query, value: e.target.value })}
                value={query.value || ''}
                invalid={
                  !(query.valid)
                }
              />
              {query.isEmpty ? (
                <FormFeedback type="invalid">Query should not be empty</FormFeedback>
              ) : null}
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => {
                  closeModal();
                }}
              >
                Close
              </button>
              <button type="submit" className="btn btn-primary" onClick={addQuery}>
                Save
              </button>
            </div>

          </Form>
        </ModalBody>
      </Modal>
      {
        isErrorModalOpen && (
          <ErrorModal
            isModalOpen={isErrorModalOpen}
            {...errorModalDetail}
            closeModal={handleCloseErrorModal}
          />
        )
      }
    </>
  );
}

AddQuery.propTypes = {
  file: PropTypes.object,
  isModalOpen: PropTypes.bool,
  closeModal: PropTypes.func
};

export default AddQuery;
