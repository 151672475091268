/* eslint prefer-const: "off" */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Modal, ModalHeader, ModalBody, Form, Row, Col, Label,
  // Label, Input, FormFeedback
} from 'reactstrap';

// import ValidationService from '../services/Validation';
import { extractErrorAndShowToast, showSuccessToast, titleCase } from '../utils';

import ErrorModal from './ErrorModal';

import AlertService from '../services/AlertService';
import CompanyService from '../services/CompanyService';
import FileService from '../services/FileService';

// const control = {
//   value: '',
//   valid: true
// };

function ResendEmailModal({
  file, isModalOpen, handleCloseModal, emailDetails
}) {
  let [emailCheckbox, setEmailCheckbox] = useState([]);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorModalDetail, setErrorModalDetail] = useState(null);

  useEffect(() => {
    if (file && file.bank_configuration_id) {
      getBankConfigurationDetail();
    }
  }, []);

  const getBankConfigurationDetail = async () => {
    try {
      // setIsLoading(true);
      const bankConfigurationResponse = await CompanyService.getCompanies(1, 1, null, null, file.bank_configuration_id);
      const bankConfigurationResponseData = bankConfigurationResponse.data;
      console.log('bankConfigurationResponseData', bankConfigurationResponseData);
      const [bankConfigurationDetail] = bankConfigurationResponseData.data;
      if (bankConfigurationDetail) {
        const { emails } = bankConfigurationDetail;
        if (emails && Array.isArray(emails) && emails.length > 0) {
          prepareEmailCheckbox(emails);
        }
      }
      // setCompanies(userResponseData);
      // setPageCount(10);
      // setIsLoading(false);
    } catch (e) {
      extractErrorAndShowToast(e);
      // setIsLoading(false);
    }
  };

  const prepareEmailCheckbox = (emails) => {
    const emailCheckboxArr = [];
    for (let email of emails) {
      const emailCheckboxObj = {
        email,
        checked: true
      };
      emailCheckboxArr.push(emailCheckboxObj);
    }
    setEmailCheckbox([...emailCheckboxArr]);
  };
  // const handleValidation = () => {
  //   name = ValidationService.isEmpty(name);
  //   name = ValidationService.lengthValidation(name, BANK_NAME_MAX_LENGTH);
  //   if (name.isEmpty || name.isInvalidLength) {
  //     name.valid = false;
  //   } else {
  //     name.valid = true;
  //   }

  //   setName({ ...name });

  //   if (
  //     name.valid
  //   ) {
  //     return true;
  //   }
  //   return false;
  // };

  const handleCloseErrorModal = () => {
    setIsErrorModalOpen(false);
    setErrorModalDetail(null);
  };

  const resendQueryEmail = async () => {
    try {
      let emails = [];
      for (let emailObj of emailCheckbox) {
        if (emailObj.checked) {
          emails.push(emailObj.email);
        }
      }
      if (emails.length === 0) {
        AlertService.showToast('Please select email ids to send email', 'error');
        return;
      }
      const obj = {
        loginFileId: file.uuid,
        emails,
        ...emailDetails
      };
      await FileService.resendEmail(obj);
      showSuccessToast('Mail sent');
      handleCloseModal();
    } catch (e) {
      console.log('resend mail failed');
      extractErrorAndShowToast(e);
    }
  };

  const updateEmailcheckbox = (index) => {
    emailCheckbox[index].checked = !emailCheckbox[index].checked;
    setEmailCheckbox([...emailCheckbox]);
  };

  return (
    <>
      <Modal isOpen={isModalOpen}>
        <ModalHeader tag="h4">
          Resend
          {' '}
          {titleCase(emailDetails.action)}
          {' '}
          Email
          {/* {!!isEdit ? "Edit Order" : "Add Order"} */}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <div className="mb-3">
                <Label className="form-label">File No</Label>
                <div>
                  {file.file_no}
                </div>
              </div>
            </Col>
          </Row>
          <Form
            className="form-horizontal"
            onSubmit={(e) => {
              e.preventDefault();
              // validation.handleSubmit();
              return false;
            }}
          >
            <Row>
              <Col>
                <Label className="form-label">Send Email To</Label>
              </Col>
            </Row>
            <Row className="g-4">
              <Col md={6}>
                <div>
                  {emailCheckbox && emailCheckbox.map((e, index) => (
                    <div className="vstack gap-2">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value={e.email}
                          checked={e.checked}
                          onChange={() => updateEmailcheckbox(index)}
                          id={e.email}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={e.email}
                        >
                          {e.email}
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
              </Col>
            </Row>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => {
                  handleCloseModal();
                }}
              >
                Cancel
              </button>
              <button type="submit" className="btn btn-primary" onClick={resendQueryEmail}>
                Resend
              </button>
            </div>

          </Form>
        </ModalBody>
      </Modal>
      {isErrorModalOpen && (
        <ErrorModal
          isModalOpen={isErrorModalOpen}
          {...errorModalDetail}
          closeModal={handleCloseErrorModal}
        />
      )}
    </>
  );
}

ResendEmailModal.propTypes = {
  file: PropTypes.object,
  isModalOpen: PropTypes.bool,
  handleCloseModal: PropTypes.func,
  emailDetails: PropTypes.object
};

export default ResendEmailModal;
