import { configureStore } from '@reduxjs/toolkit';
import reducer from './redux-slices/reducers';

const customMiddleWare = () => (next) => (action) => {
  console.log('Middleware triggered:', action);
  next(action);
};

const middleware = [customMiddleWare];

// add redux dev tool
const store = configureStore({
  reducer,
  middleware,
});

export default store;
