/* eslint prefer-const: "off" */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Modal, ModalHeader, ModalBody, Form, Label, Input, FormFeedback
} from 'reactstrap';

import BranchService from '../services/BranchService';
import ValidationService from '../services/Validation';
import { extractErrorAndShowToast } from '../utils';

import ErrorModal from './ErrorModal';

import {
  BANK_NAME_MAX_LENGTH
} from '../constants/validation.constant';
import AlertService from '../services/AlertService';
import CityService from '../services/CityService';

const control = {
  value: '',
  valid: true
};

function AddBranchModal({ branch, isModalOpen, handleCloseModal }) {
  let [name, setName] = useState({ ...control });
  let [city, setCity] = useState({ ...control });
  let [address, setAddresss] = useState({ ...control });
  let [isHeadQuarter, setIsHeadQuarter] = useState(false);
  let [isCityHeadOffice, setIsCityHeadOffice] = useState(false);
  // let [isLoading, setIsLoading] = useState(false);
  let [cities, setCities] = useState([]);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorModalDetail, setErrorModalDetail] = useState(null);

  useEffect(() => {
    if (branch) {
      setName({ ...control, value: branch.name });
      setCity({ ...control, value: branch.city_id });
      setAddresss({ ...control, value: branch.address });
      setIsHeadQuarter(branch.is_head_quarter);
      setIsCityHeadOffice(branch.is_city_head_office);
    }
    getCities();
  }, []);

  const handleValidation = () => {
    name = ValidationService.isEmpty(name);
    name = ValidationService.lengthValidation(name, BANK_NAME_MAX_LENGTH);
    if (name.isEmpty || name.isInvalidLength) {
      name.valid = false;
    } else {
      name.valid = true;
    }

    setName({ ...name });

    if (
      name.valid
    ) {
      return true;
    }
    return false;
  };

  const addBranch = async () => {
    try {
      const isFormValid = handleValidation();
      if (!isFormValid) {
        return;
      }
      const branchObj = {
        name: name.value,
        cityId: city.value,
        address: address.value,
        isHeadQuarter,
        isCityHeadOffice,
      };
      console.log('branchObj', branchObj);
      if (branch && branch.uuid) {
        branchObj.id = branch.uuid;
        await BranchService.updateBranch(branchObj);
      } else {
        await BranchService.addBranch(branchObj);
      }
      // const infoObj = {
      //   title: 'Bank',
      //   message: `${bank.uuid ? 'Bank info updated successfully' : 'Bank info added successfully'}`,
      // };
      AlertService.showToast(`${branch && branch.uuid ? 'Branch updated successfully' : 'Branch added successfully'}`, 'sucess');
      // openSweetAlert('Bank', `${branch.uuid ? 'Bank info updated successfully' : 'Bank info added successfully'}`, 'sucess');
      // setIsInfoModalOpen(true);
      // setInfoModalDetail(infoObj);
      handleCloseModal(true);
    } catch (e) {
      console.log('update failed');
      // const errorObj = {
      //   title: 'Bank',
      //   message: 'Something went wrong!!',
      // };
      extractErrorAndShowToast(e);
      // openSweetAlert('Bank', 'Something went wrong', 'error');
      // setErrorModalDetail(errorObj);
      // setIsErrorModalOpen(true);
    }
  };

  const handleCloseErrorModal = () => {
    setIsErrorModalOpen(false);
    setErrorModalDetail(null);
  };

  const getCities = async (searchKeyword) => {
    try {
      const cityResponse = await CityService.getCities(1, 1000, searchKeyword, '', 'true');
      const cityResponseData = cityResponse.data;
      const { data } = cityResponseData;
      console.log('cityResponseData', cityResponseData);
      // setUsers(userResponseData.users);
      if (data.length > 0) {
        setCity({ ...city, value: data[0].id });
      }
      setCities(data);
      // setIsLoading(false);
    } catch (e) {
      extractErrorAndShowToast(e);
      // console.log('e', e);
      // const errorObj = {
      //   title: 'Citys',
      //   message: 'Something went wrong!!',
      // };
      // setErrorModalDetail(errorObj);
      // setIsErrorModalOpen(true);
      setTimeout(() => {
        // setIsLoading(false);
      }, 2000);
    }
  };
  // const openSweetAlert = (title, message, state = 'success') => {
  //   let icon = 'info';
  //   if (state === 'error') {
  //     icon = 'error';
  //   }
  //   Swal.fire({
  //     title,
  //     icon,
  //     text: message,
  //     showCloseButton: true,
  //     confirmButtonText:
  //       'Ok',
  //     confirmButtonAriaLabel: 'Ok',
  //   });
  // };

  return (
    <>
      <Modal isOpen={isModalOpen}>
        <ModalHeader tag="h4">
          {branch && branch.uuid ? 'Edit Branch Detail' : 'Add Branch'}
          {/* {!!isEdit ? "Edit Order" : "Add Order"} */}
        </ModalHeader>
        <ModalBody>
          <Form
            className="form-horizontal"
            onSubmit={(e) => {
              e.preventDefault();
              // validation.handleSubmit();

              return false;
            }}
          >
            {/* {error ? <Alert color="danger">{error}</Alert> : null} */}

            <div className="mb-3">
              <Label className="form-label">Branch Name</Label>
              <Input
                name="name"
                className="form-control"
                placeholder="Enter Branch Name"
                type="text"
                onChange={(e) => setName({ ...name, value: e.target.value })}
                value={name.value || ''}
                invalid={
                  !(name.valid)
                }
              />
              {name.isEmpty ? (
                <FormFeedback type="invalid">branch name should not be empty</FormFeedback>
              ) : null}
              {name.isInvalidLength ? (
                <FormFeedback type="invalid">
                  branch name should be more then
                  {BANK_NAME_MAX_LENGTH}
                  {' '}
                  characters
                </FormFeedback>
              ) : null}
            </div>

            <div className="mb-3">
              <Label className="form-label">
                City
                <span className="text-danger">&nbsp;*</span>
              </Label>

              <Input
                type="select"
                value={city.value}
                // className="form-control bg-light border-light rounded"
                // placeholder="Search..."
                onChange={(e) => { setCity({ ...city, value: e.target.value }); }}
              >
                {cities.map((c) => <option value={c.id}>{c.name}</option>)}
              </Input>
            </div>

            <div>
              {/* <Label className="form-label">
                City Head Office
                <span className="text-danger">&nbsp;*</span>
              </Label> */}
              <div className="form-check form-switch form-switch-md">
                City Head Office
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="customSwitchsizemd"
                  checked={!!isCityHeadOffice}
                  // defaultChecked
                  onClick={() => {
                    setIsCityHeadOffice(!isCityHeadOffice);
                  }}
                />
              </div>
            </div>

            <div>
              <div className="form-check form-switch form-switch-md">
                Head Quarter
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="customSwitchsizemd"
                  checked={!!isHeadQuarter}
                  // defaultChecked
                  onClick={() => {
                    setIsHeadQuarter(!isHeadQuarter);
                  }}
                />
              </div>
            </div>

            <div className="mb-3">
              <Label className="form-label">
                Address
                <span className="text-danger">&nbsp;*</span>
              </Label>
              <Input
                type="textarea"
                value={address.value}
                // className="form-control bg-light border-light rounded"
                // placeholder="Search..."
                onChange={(e) => { setAddresss({ ...address, value: e.target.value }); }}
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => {
                  handleCloseModal();
                }}
              >
                Close
              </button>
              <button type="submit" className="btn btn-primary" onClick={addBranch}>
                Save
              </button>
            </div>

          </Form>
        </ModalBody>
      </Modal>
      {isErrorModalOpen && (
        <ErrorModal
          isModalOpen={isErrorModalOpen}
          {...errorModalDetail}
          closeModal={handleCloseErrorModal}
        />
      )}
    </>
  );
}

AddBranchModal.propTypes = {
  branch: PropTypes.object,
  isModalOpen: PropTypes.bool,
  handleCloseModal: PropTypes.func
};

export default AddBranchModal;
