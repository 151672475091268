module.exports = {
  ADD_ORGANIZATION: 'add_organization',
  UPDATE_ORGANIZATION: 'update_organization',
  LIST_ORGANIZATION: 'list_organization',
  ADD_USER: 'add_user',
  UPDATE_USER: 'update_user',
  LIST_USER: 'list_user',
  DELETE_USER: 'delete_user',
  ADD_BRANCH: 'add_branch',
  UPDATE_BRANCH: 'update_branch',
  LIST_BRANCH: 'list_branch',
  DELETE_BRANCH: 'delete_branch',
  ADD_BANK_CONFIGURATION: 'add_bank_configuration',
  UPDATE_BANK_CONFIGURATION: 'update_bank_configuration',
  LIST_BANK_CONFIGURATION: 'list_bank_configuration',
  DELETE_BANK_CONFIGURATION: 'delete_bank_configuration',
  ADD_FILE: 'add_file',
  UPDATE_FILE: 'update_file',
  LIST_FILE: 'list_file',
  VIEW_FILE_DETAIL: 'view_file_detail',
  ADD_QUERY: 'add_query',
  TSR_VR_UPLOAD: 'tsr_vr_upload',
  TSR_VR_HANDOVER: 'tsr_vr_handover',
  SEND_OUTSOURCE: 'send_outsource',
  DELETE_FILE: 'delete_file',
  ADD_CITY: 'add_city',
  UPDATE_CITY: 'update_city',
  LIST_CITY: 'list_city',
  DELETE_CITY: 'delete_city',
  ADD_BANK: 'add_bank',
  UPDATE_BANK: 'update_bank',
  LIST_BANK: 'list_bank',
  DELETE_BANK: 'delete_bank'
};
