const { REACT_APP_API_URL } = process.env;
module.exports = {
  routes: {
    // API_URL: 'http://localhost:3001',
    API_URL: REACT_APP_API_URL,

    SIGNIN: '/auth',
    SIGNUP: '/auth',

    ADD_USER: '/user',
    UPDATE_USER: '/user',
    GET_USERS: '/user',
    GET_USER_DETAIL: '/user/user-detail',
    USER_RESET_CREDENTIALS: '/user/reset/credentials',
    FORGOT_PASSWORD: '/user/forgot-password',
    CHANGE_PASSWORD: '/user/change-password',
    RESEND_VERIFICATION_EMAIL: '/user/resend-verification-email',

    GET_MODULE_PERMISSIONS: '/module-permissions',
    ADD_USER_PERMISSIONS: '/module-permissions/user-permissions',
    GET_USER_PERMISSIONS: '/module-permissions/user-permissions',

    GET_ROLES: '/role',

    GET_ADMIN_BANKS: '/bank',
    ADD_ADMIN_BANK: '/bank',
    UPDATE_ADMIN_BANK: '/bank',
    GET_BANKS: '/bank',

    GET_BANK_CONFIGURATIONS: '/bank/configuration',
    GET_BANK_CONFIGURATIONS_DROPDOWN: '/bank/configuration/dropdown',
    ADD_BANK_CONFIGURATION: '/bank/configuration',
    UPDATE_BANK_CONFIGURATION: '/bank/configuration',

    ADD_FILE: '/file',
    UPDATE_FILE: '/file',
    GET_FILES: '/file/files',
    GET_FILE_DETAIL: '/file/file-detail',
    GET_LOGIN_FILE_TYPES: '/file/login-file-type',
    TSR_VR_HANDOVER: '/file/tsr-vr-handover',
    ADD_FILE_QUERY: '/file/query',
    GET_FILE_QUERY: '/file/query',
    UPLOAD_TSR_VR_DOCUMENT: '/file/tsr-vr-upload',
    GET_TSR_VR_DOCUMENT: '/file/tsr-vr-upload',
    GET_FILE_HISTORY: '/file/history',
    OUTSOURCE: '/file/outsource',
    RESEND_EMAIL: '/file/resend-email',

    ADD_BRANCH: '/branch',
    UPDATE_BRANCH: '/branch',
    GET_BRANCHES: '/branch',
    GET_BRANCHES_DROPDOWN: '/branch/dropdown',

    ADD_USER_BRANCH: '/branch/branch-user',
    GET_USER_BRANCH: '/branch/branch-user',
    UPDATE_USER_BRANCH: '/branch/branch-user',

    ADD_CITY: '/city',
    UPDATE_CITY: '/city',
    GET_CITIES: '/city',

    GET_SIGNED_URL: '/file/get-signed-url'
  },
};
